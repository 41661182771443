import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
const TermsScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Login" className="home-page main-content">
            <div className="content">
                <div className="card-container" style={{ maxWidth: 700 }}>

                    <div className="card-component">
                        <h1 className="card-title">Program Terms and Conditions</h1>
                        <p>In submitting my Driven to Give (DTG) event request, I recognize that if approved to host a DTG event, I am responsible for understanding and agreeing to the following:</p>
                        <ol>
                            <li>
                                I understand that in order to be eligible to host a 2023 Lincoln DTG event the Lincoln retailer must be a Lincoln Brand Exclusive Advertising Program eligible retailer and the benefitting organization must pass the charity vetting requirements.
                            </li>
                            <li>
                                I accept that as a Lincoln retailer I cannot host more than one (1) fully funded Lincoln DTG event in 2023. Additional event requests will be reviewed and granted on a one-off basis.
                            </li>
                            <li>
                                My event must be held on a single day and at one location only.
                            </li>
                            <li>
                                I will be sent a confirmation email after I submit my DTG event enrollment request on the program site. I will receive an email within ten (10) business days after submission with either organization approval or organization denial. If my selected organization is not approved, I can email <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a> with a new selected charity for review.
                            </li>
                            <li>
                                I understand that any changes after enrollment (including changes to date, location, benefitting organization) must be approved by Program Headquarters (PHQ). If conflict arises with my originally scheduled event date, I must submit my request in writing to PHQ at <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>. Requests will be grated at the sole discretion of PHQ.
                            </li>
                            <li>
                                I understand that for each test-drive, each waiver must be submitted digitally though your event's unique registration link. Paper waivers will not be available for the 2023 DTG events.
                            </li>
                            <li>
                                My store and I are responsible for adhering to all local and state laws regarding test-drives as applicable to my event.
                            </li>
                            <li>
                                My event location will be within my Lincoln retailer PMA/DMA and I will contact my Lincoln Specialist with any questions regarding my Lincoln retailer PMA/DMA.
                            </li>
                            <li>
                                My event cannot be hosted on the same day or in conjunction with another Lincoln or Ford Motor Company sponsored event.
                            </li>
                            <li>
                                I understand that Lincoln will only distribute donation funds to the chosen and approved charity after an event has been completed and test-drives have been validated*. I further understand that Lincoln will not distribute donation funds if an event does not take place and is cancelled for any reason.
                            </li>
                            <li>
                                If I want to cancel my event for any reason, I am responsible for contacting PHQ at <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a> to communicate my event cancellation.
                            </li>
                            <li>
                                Maximum donation for an event is $7,000. NO PURCHASE OR ACTION IS NECESSARY. Donation of $30 per valid, up to 200 test-drives, for a total donation $6,000 per event. Limit one (1) donation per household. An additional 100 bonus experiences per event are allowed, for an incremental donation of up to $1,000 per event, for a maximum total donation of $7,000. Test-drive and bonus experience must both occur as part of the same event.
                            </li>
                            <li>
                                I understand that if the benefitting organization does not cash their donation check within 180 days of issue, Lincoln reserves the right to donate the funds to another organization of their choice.
                            </li>
                            <li>
                                Test-drivers must be 18 years of age or older with a valid driver's license and valid automobile insurance.
                            </li>
                            <li>
                                I understand that Lincoln will provide various promotional materials free of charge. This could include customizable, digital promotional materials for use by the retailer and organization to promote registration.
                            </li>
                            <li>
                                I accept that select events, as determined by PHQ, may receive an influencer or content capturer to attend my event.
                            </li>
                        </ol>
                        <p>
                            I have read and understood these terms and conditions. By clicking “I agree”, I assume responsibility for the above.
                        </p>
                        <p>
                            Should you have questions, please contact DTG PHQ <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>.
                        </p>
                        <p>
                            NO PURCHASE NECESSARY. Must be 18 years of age or older with a valid driver's license and valid automobile insurance. Donation of $30 per valid, up to 200 test-drives, for a total donation $6,000 per event. Limit one (1) donation per household. An additional 100 bonus experiences per event are allowed, for an incremental donation of up to $1,000 per event, for a maximum total donation of $7,000. Test-drive and bonus experience must both occur as part of the same event. If the benefitting organization does not cash their donation check within 180 days of issue, Lincoln reserves the right to donate the funds to another organization of their choice.
                        </p>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },
                ]}
            />
        </div>
    );
};

export default TermsScreen;
