import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/Lincoln_DrivenToGive_Banner_Logo.png";
const FAQScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Profile" className="guidelines-page main-content">
            <img src={logo} alt={'Lincoln logo 100 years'} className={'logo'} />
            <div className="card-container">
                <div className="card-component">
                <h2>Frequently Asked Questions</h2>
                    <ul>
                        <li><span className="bold">How many Driven to Give events can my store hold?</span>
                            <br/><br/>
                            Retailers can hold one (1) event in 2023.
                        </li>
                        <li><span className="bold">Are there any restrictions on when I can hold my Driven to Give event?</span>
                            <br/><br/>
                            Events must occur between July 15 - November 19.
                        </li>
                        <li><span className="bold">When is my Driven to Give event confirmed?</span>
                            <br/><br/>
                            After you enroll your event on the site, you will receive an email from Program HQ confirming your request was received and that we are reviewing your organization for eligibility. If the benefitting organization is approved, you will receive an email confirming your event. If your organization is not approved, you can resubmit a new event with a new organization and the process will begin again. If there are any questions, please reach out to <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>.
                        </li>
                        <li><span className="bold">What promotional assets does Lincoln provide?</span>
                            <br/><br/>
                            Each retailer will have access to digital promotional materials including an event flyer, web banner and two social media graphics.
                        </li>
                        <li><span className="bold">What is the donation amount for the benefitting organization? </span>
                            <br/><br/>
                            For each DTG event, Lincoln will provide a donation* of $30 per valid test drive (up to 200 test drives) and $10 per valid bonus Blue Cruise experience (up to 100 experiences) to the benefitting charitable organization for a maximum total donation of $7,000. All test drives must occur on one day, one donation per household.
                        </li>
                        <li><span className="bold">What if I need to change my event date?</span>
                            <br/><br/>
                            Any event cancellations or changes must be submitted in writing to Program HQ at <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>. From there, Program HQ will work with you on next steps to update your event.
                        </li>
                    </ul>
                    <br/>
                    <p>* NO PURCHASE NECESSARY. Must be 18 years of age or older with a valid driver’s license and valid automobile insurance. Donation of $30 per valid, up to 200 test-drives, for a total donation $6,000 per event. Limit one (1) donation per household. An additional 100 bonus experiences per event are allowed, for an incremental donation of up to $1,000 per event, for a maximum total donation of $7,000. Test-drive and bonus experience must both occur as part of the same event. If the benefitting organization does not cash their donation check within 180 days of issue, Lincoln reserves the right to donate the funds to another organization of their choice. </p>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </div>
    );
};

export default FAQScreen;
