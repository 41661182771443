/* eslint-disable @typescript-eslint/no-unused-vars */
// import moment from 'moment-timezone';

import { observer } from "mobx-react-lite";
import { useLocalization } from "@progress/kendo-react-intl";
import { useStores } from "src/stores";
import { default as React, useEffect, useState } from "react";
import { useServices } from "src/services";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { useHistory } from "react-router";
import hashid from '../utils/hashid';
import moment from "moment";
import capitalize from "src/utils/capitalize";
// @ts-ignore
import PrivacyBanner from "react-ford-ccpa-banner";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { NumericTextBox } from "../components/form/NumericTextBox";
import { Input } from "../components/form/Input";
import { Upload } from "../components/form/Upload";
import {
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from "@progress/kendo-react-upload";

// import { houseAfternoon, houseEvening, houseMorning, houseNight, houseNoon, houseSunrise } from '../assets/images/houseImages';

const EventsScreen = () => {
  const localizationService = useLocalization();
  const { language } = localizationService;

  const { ui, login } = useStores();
  const { dtg } = useServices();
  let history = useHistory();

  const processEvents = (events: DTGEvent[]): DTGEvent[] => {
    return events.map((e) => {
      const { start_date, eligibility_custom_data, status } = e;
      const { requested_date, requested_month } = JSON.parse(
        eligibility_custom_data || "{}"
      );

      switch (status) {
        case "new":
          e.sort_status = 0;
          break;

        case "review":
          e.sort_status = 1;
          break;

        case "eligible":
          e.sort_status = 2;
          break;

        case "planning":
          e.sort_status = 3;
          break;

        case "approved":
          e.sort_status = 4;
          break;

        case "in progress":
          e.sort_status = 5;
          break;

        case "event ending":
          e.sort_status = 6;
          break;

        case "event ended":
          e.sort_status = 7;
          break;

        case "ineligible":
          e.sort_status = 99;
          break;

        case "revise":
          e.sort_status = 100;
          break;

        case "cancelled":
          e.sort_status = 101;
          break;

        default:
          e.sort_status = 999;
          break;
      }

      if (start_date) {
        e.sort_date = start_date;
        e.display_date = moment(start_date).format("ddd MMM Do yyyy");
      } else if (requested_date) {
        e.sort_date = requested_date;
        e.display_date = moment(requested_date).format("ddd MMM Do yyyy");
      } else {
        e.sort_date = moment(
          `${requested_month} 1 ${ui.currentSeason.start_date}`
        );
        e.display_date = requested_month;
      }

      return e;
    });
  };

  const [processing, setProcessing] = useState<boolean>(true);
  const [twoFAEmailSent, setTwoFAEmailSent] = useState<boolean>(false);
  const [dialogDisplayed, setDialogDisplayed] = useState<boolean>(false);
  const [twoFA_DialogDisplayed, setTwoFA_DialogDisplayed] = useState<boolean>(false);
  const [files, setFiles] = useState<Array<UploadFileInfo>>([]);
  const [formError, setFormError] = useState<string>();
  const [twoFAError, setTwoFAError] = useState<string>();
  const [selectedEvent, setSelectedEvent] = useState<number>(0);

  const ref: any = React.useRef(null);

  const handleOnWheel = (event: any) => {
    event.stopPropagation();
  };

  React.useEffect(() => {
    ref?.current?.element.addEventListener('wheel', handleOnWheel);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ref?.current?.element.removeEventListener('wheel', handleOnWheel);
    };
  });

  useEffect(() => {
    dtg.getAccount();
    ui.setIsGettingEvents(true);
    dtg.checkRequest().then((ret) => {
      if ((ret as DTGFetchResult).response.status === 200) {
        ui.setIsApprovedDealer(true);
      } else {
        ui.setIsApprovedDealer(false);
      }
    });
    dtg.getEvents().then((ret) => {
      const processedEvents = processEvents(
        (ret as DTGFetchResult).result || []
      );
      ui.setIsGettingEvents(false);

      ui.setEvents(processedEvents);
      setProcessing(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEventRequestButton = () => {
    if (ui.isApprovedDealer) {
      history.push("/eligibility");
    } else {
      alert(
        localizationService.toLanguageString(
          "custom.notApprovedDealer",
          "notApprovedDealer"
        )
      );
    }
  };

  const handleSend2FAEmail = async () => {
    setTwoFAEmailSent(false);
    const contactEmail = (login.dealer as Dealer).contact_email;
    return dtg.send2FAEmail(contactEmail).then(async (res: any) => {
      ui.setReceivedCode(res.result.code);
      setTwoFAEmailSent(true)
      return;
    })
      .catch((e) => {
        console.log('handleSend2FAEmail Error', e)
        ui.setReceivedCode('');
        login.setTwoFACode('');
        setTwoFAEmailSent(false)
        setTwoFAError(e)
        return;
      })
  }

  const onSubmit2FACode = React.useCallback(
    async (dataItem) => {
      const {
        entered_code
      } = dataItem;
      const [decodedTimestamp] = await hashid.decode('lcc_2fa_code', ui.receivedCode);
      if ((entered_code === ui.receivedCode) && moment(decodedTimestamp).isSame(moment(), 'day')) {
        login.setTwoFACode(ui.receivedCode);
        history.push(`/respondents/${ui.selectedEventID}`);
      } else {
        console.log("This code is invalid or has expired, please try again")
        setTwoFAError("This code is invalid or has expired, please try again or click here to request a new code.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );


  const doMagicLink = (event_id: string) => {
    dtg.getEventToken(event_id).then((tokenResult) => {
      const { token, url } = (tokenResult as DTGFetchResult).result;
      setTimeout(() => {
        window.open(`${url}/auto_login/${token}`, "_blank");
      });
    });
  };
  const onAdd = (event: UploadOnAddEvent) => {
    console.log("onAdd: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    console.log("onRemove: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    console.log("onProgress: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    console.log("onStatusChange: ", event.affectedFiles);

    setFiles(event.newState);
  };

  const onSubmitReimbursement = React.useCallback(
    async (dataItem) => {
      setProcessing(true);
      const [selectedFile] = files;
      const rawFile =
        selectedFile && selectedFile.getRawFile && selectedFile.getRawFile();
      if (rawFile) {
        dataItem.event_reimbursement_file = rawFile;
      }
      dataItem.event_id = selectedEvent;

      console.log("dataItem", dataItem);

      const call: any = await dtg.saveReimbursement(dataItem);
      if (call.result.errors) {
        console.log("[EventPlanningFormScreen]", "Reimbursement Request Error");
        setFormError(call.result.errors.join("\n"));
        setProcessing(false);
      } else {
        console.log(
          "[EventPlanningFormScreen]",
          "Reimbursement Request created"
        );
        history.go(0);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dtg, files, history]
  );

  const EventStatusCell = (props: GridCellProps) => {
    const { status } = props.dataItem as DTGEvent;

    return <td>{capitalize(status || "")}</td>;
  };

  const EventActionsCell = (props: GridCellProps) => {
    const { event_id, event_request_id, status, reimbursement_requested } =
      props.dataItem as DTGEvent;
    console.log('STATUS!!!', status);

    return (
      <td>
        {["eligible", "planning", "revise"].includes(status!) && (
          <Button
            className="k-button k-primary"
            // onClick={() => props.enterEdit(props.dataItem)}
            onClick={() => {
              if (event_id) {
                history.push(`/event/${event_id}`);
              } else {
                history.push(`/eventRequest/${event_request_id}`);
              }
            }}
          >
            Edit
          </Button>
        )}
        {["approved", "in progress", "event ending", "event ended"].includes(status!) && (
          <Button
            className="k-button k-primary"
            onClick={() => {
              doMagicLink(`${event_id}`);
            }}
          >
            Log In To Event Portal
          </Button>
        )}
        {/* {["approved", "in progress", "event ending"].includes(status!) && (
          <Button
            className="k-button k-primary"
            style={{ marginLeft: "10px", }}
            onClick={async () => {
              // if already authenticated today, proceed.
              if (login.twoFACode) {
                const [decodedTimestamp] = await hashid.decode('lcc_2fa_code', login.twoFACode);
                if (moment(decodedTimestamp).isSame(moment(), 'day')) {
                  console.log('Already authenticated today - proceeding to respondents page')
                  history.push(`/respondents/${event_id}`);
                }
                console.log('Not authenticated today - proceeding to 2FA.')
                return;
              }
              ui.setSelectedEventID(event_id!)
              setTwoFA_DialogDisplayed(true);
            }}
          >
            View Respondents
          </Button>
        )} */}
        {/* {["donation approved"].includes(status!) && !reimbursement_requested && (
          <Button
            className="k-button k-primary"
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setSelectedEvent(event_id!);
              setDialogDisplayed(true);
            }}
          >
            Start Reimbursement
          </Button>
        )} */}
      </td>
    );
  };

  return (
    <>
      {dialogDisplayed && (
        <Dialog>
          <h3>Submit Event Reimbursement</h3>

          <p>
            Upload receipts and estimated attendance to PHQ for final approval.
          </p>
          <Form
            onSubmit={onSubmitReimbursement}
            render={(formRenderProps) => (
              <FormElement horizontal={true}>
                {formError && <Error>{formError}</Error>}
                <Field
                  id={"attendance"}
                  name={"attendance"}
                  // type={'input'}
                  label={localizationService.toLanguageString(
                    "custom.attendance",
                    "attendance"
                  )}
                  childRef={ref}
                  // validator={requiredValidator}
                  component={NumericTextBox}
                />
                <Field
                  id={"event_reimbursement_file"}
                  name={"event_reimbursement_file"}
                  type={"file"}
                  multiple={false}
                  files={files}
                  onAdd={onAdd}
                  onRemove={onRemove}
                  onProgress={onProgress}
                  onStatusChange={onStatusChange}
                  onChange={() => (formRenderProps.allowSubmit = true)}
                  label={localizationService.toLanguageString(
                    "custom.event_reimbursement_file",
                    "event_reimbursement_file"
                  )}
                  hint={localizationService.toLanguageString(
                    "custom.event_reimbursement_file_hint",
                    "event_reimbursement_file_hint"
                  )}
                  // validator={requiredValidator}
                  component={Upload}
                />
                <DialogActionsBar>
                  <Button
                    primary={true}
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(
                      "custom.request_reimbursement",
                      "request_reimbursement"
                    )}
                  </Button>
                </DialogActionsBar>
              </FormElement>
            )}
          />
        </Dialog>
      )}
      {twoFA_DialogDisplayed && (
        <Dialog style={{ width: '50%', marginLeft: '25%' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <h3>Two-Factor Authentication Required</h3>
            <Button
              style={{ marginLeft: 30, color: '#ff6358', backgroundColor: 'transparent' }}
              onClick={() => {
                setTwoFA_DialogDisplayed(false)
                setTwoFAEmailSent(false)
              }}
            >Cancel</Button>
          </div>
          <p style={{ textAlign: 'center' }}>
            {twoFAEmailSent ? 'Please check your email for the 2FA code. Enter it below and then press "Authenticate."' : 'To view the list of event respondents, select "Send 2FA Email" below to send a code to you via email. Then, you will be prompted to enter that code to authenticate the respondents page.'}
          </p>
          <Button
            style={{ marginBottom: 5, width: '40%', marginLeft: '30%' }}
            onClick={handleSend2FAEmail}
            hidden={twoFAEmailSent}
          >{'Send 2FA Email'}</Button>
          {twoFAEmailSent ?
            (
              <Form
                onSubmit={onSubmit2FACode}
                render={() => (
                  <FormElement horizontal={true} style={{ width: '60%', marginLeft: '20%', marginBottom: 10, marginTop: 15 }}>
                    {formError && <Error>{formError}</Error>}
                    <Field
                      id={"entered_code"}
                      name={"entered_code"}
                      label={'2FA Code'}
                      component={Input}
                    />
                    <Button
                      primary={true}
                      type={"submit"}
                      style={{ marginTop: 10, width: '100%' }}
                    >
                      Authenticate
                    </Button>
                    {twoFAError ? <Button
                      style={{ backgroundColor: 'transparent', borderWidth: 0, width: '40%', marginLeft: '30%', cursor: 'pointer' }}
                      onClick={handleSend2FAEmail}
                      hidden={!twoFAEmailSent}
                    ><p style={{ color: 'red', fontSize: 15, cursor: 'pointer' }}>{twoFAError}</p>
                    </Button> : null}
                  </FormElement>
                )}
              />
            ) : null
          }
        </Dialog>
      )}
      <div id="Dashboard" className="dashboard-page main-content">
        <div className="card-container grid">
          <h3 className="card-title">
            {login.dealer?.dealer_name}:{" "}
            {localizationService.toLanguageString("custom.events", "events")}
          </h3>
          <div>
            <Button
              primary={!ui.events.length}
              onClick={handleEventRequestButton}
              className="events_button"
            >
              {ui.events.length
                ? localizationService.toLanguageString(
                  "custom.requestNewEvent",
                  "requestNewEvent"
                )
                : localizationService.toLanguageString(
                  "custom.beginHere",
                  "beginHere"
                )}
            </Button>
            <p className="events-disclaimer">Lincoln Brand Exclusive Advertising Program eligible retailers can host one (1) Driven to Give event in the 2023 session.</p>
          </div>
          <br />
          {processing ? (
            <div style={{ textAlign: "center" }}>
              <Loader type="infinite-spinner" size="large" />
            </div>
          ) : (
            <div className="card-component">
              <Grid data={ui.events} scrollable="none">
                <GridColumn field="charity_partner" title="Charity Partner" />
                <GridColumn
                  cell={EventStatusCell}
                  field="status"
                  title="Status"
                />
                <GridColumn field="display_date" title="Event Date" />
                <GridColumn cell={EventActionsCell} />
              </Grid>
            </div>
          )}
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0 0", width: "100%" }}
        privacyContainerStyle={{ padding: "10px 0", width: "100%" }}
        showPrivacy={true}
        language={language}
        team={"lincoln"}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact" },
          {
            text: "PROGRAM TERMS AND CONDITIONS",
            url: "/terms-eligibility",
          },
          { text: "PROGRAM RESOURCES ", url: "/resources" },
        ]}
      />
    </>
  );
};

export default observer(EventsScreen);
