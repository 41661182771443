import { useLocalization } from "@progress/kendo-react-intl";

import * as React from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

const ContactScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;
    return (
        <>
            <div id="Login" className="home-page main-content">
                <div className="content">
                    <div className="card-container" style={{ maxWidth: 700 }}>
                        <div className="card-component">
                            <h1 className="card-title">Contact Us</h1>
                            <div>
                                <div>
                                    <h3>Have a question or need help?</h3>

                                    <p>Contact Driven to Give Program Headquarters </p>
                                </div>
                                <div>
                                    <p>
                                        <b>Email: </b>
                                        <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </>
    );
};

export default ContactScreen;
