import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import {useHistory, useParams} from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { passwordValidator, passwordMatchValidator } from '../validators';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';

const ResetPasswordScreen = () => {
        const {...formValues} = React.useContext(AppContext);
        const localizationService = useLocalization();
        const history = useHistory();
        const [ passwordError, setPasswordError ] = useState<string>();
        const [ confirmMessage, setConfirmMessage ] = useState<string>('');
        const { dtg } = useServices();
        // @ts-ignore
        const { token } = useParams();

        const onSubmit = React.useCallback(
            async (dataItem) => {
                if (!token) {
                    setPasswordError('No token value found');
                }

                const call:any = await dtg.doResetPassword(dataItem.password, token);
                if (call.result.error) {
                    console.log('[ResetPasswordScreen]', 'Password Error');
                    setPasswordError(call.result.error);
                }
                if (call.result.success) {
                    console.log('[ResetPasswordScreen]', 'email sent');
                    setConfirmMessage(localizationService.toLanguageString('custom.passwordReset', 'Password reset'))
                }
            },
            [dtg, token, localizationService]
        );

        return (
            <div id="Login" className="login-page">
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <div className="card-component">
                    <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                password: '',
                                password2: '',
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    { passwordError && <Error>{passwordError}</Error> }

                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.newPassword', 'New Password')}
                                        validator={passwordValidator}
                                        component={Input}
                                    />

                                    <Field
                                        id={'password2'}
                                        name={'password2'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.secondPassword', 'Retype Password')}
                                        validator={passwordMatchValidator}
                                        component={Input}
                                    />

                                    <hr />
                                    <div className={'k-form-buttons'}>
                                        <p>{confirmMessage && confirmMessage}</p>
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                            hidden={!!confirmMessage}
                                        >
                                            {localizationService.toLanguageString('custom.resetPassword', 'Reset Password')}
                                        </Button>
                                    </div>
                                    <Button
                                        primary={true}
                                        onClick={() => {
                                            history.push("/");
                                        }}
                                        hidden={!confirmMessage}
                                    >
                                        {localizationService.toLanguageString('custom.goBack', 'Go Back')}
                                    </Button>
                                </FormElement>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
}

export default ResetPasswordScreen;
