const Hashids = require('hashids/cjs');

const hash = {
  lcc_2fa_code: new Hashids('lcc_respondents_2fa', 0, '2346789BCDFGHJKMPQRTVWXY'),
};

/**
 * Encode hash by type
 * @param type {string}
 * @param value {string|number}
 */
export function encode(type, value) {
  if (!hash[type]) throw (new Error(
    `Unable to encode. Hash type "${type}" does not exist. Try one of: [ "${Object.keys(hash).join('", "')}" ]`
  ));
  return hash[type].encode(value);
}

/**
 * Decode hash by type
 * @param type {string}
 * @param hashed_value {string}
 */
export async function decode(type, hashed_value) {
  if (!hash[type]) throw (new Error(
    `Unable to decode. Hash type "${type}" does not exist. Try one of: [ "${Object.keys(hash).join('", "')}" ]`
  ));
  return await hash[type].decode(hashed_value);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  encode,
  decode,
};

