import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator } from '../validators';

import { AppContext } from '../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import logo from "../assets/images/LINC_Hz_Black.png";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

const ForgotPasswordScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();
    const { language } = localizationService;
    const [passwordError, setPasswordError] = useState<string>();
    const [confirmMessage, setConfirmMessage] = useState<string>('');
    const { dtg } = useServices();

    const onSubmit = React.useCallback(
        async (dataItem) => {
            const call: any = await dtg.doForgotPassword(dataItem.contact_email);
            if (call.result.error) {
                console.log('[ForgotPasswordScreen]', 'Password Error');
                setPasswordError(call.result.error);
            }
            if (call.result.success) {
                console.log('[ForgotPasswordScreen]', 'email sent');
                setConfirmMessage(localizationService.toLanguageString('custom.confirmPasswordMessage', 'Please check your email'))
            }
        },
        [dtg, localizationService]
    );

    return (
        <div id="ForgotPassword" className="home-page main-content">
            <div className="content">
                <div className={'logo_container'}>
                    <img src={logo} alt={'Lincoln logo'} className={'logo small'} />
                </div>
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <div className="card-component">
                        <h1 className="card-title">{localizationService.toLanguageString('custom.resetPassword', 'Reset Password')}</h1>
                        <p className="card-text">{localizationService.toLanguageString('custom.resetText', 'Reset Text')}</p>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                contact_email: ''
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    {passwordError && <Error>{passwordError}</Error>}

                                    <Field
                                        id={'contact_email'}
                                        name={'contact_email'}
                                        type={'email'}
                                        autoComplete="username"
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />

                                    <hr />
                                    <p>{confirmMessage && confirmMessage}</p>
                                    <div className={'card-buttons'}>
                                        <Button
                                            className={'k-button'}
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            {localizationService.toLanguageString('custom.submit', 'submit')}
                                        </Button>
                                    </div>
                                </FormElement>
                            )}
                        />
                        <div className="card-links">
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/login");
                                }}
                            >
                                {localizationService.toLanguageString("custom.logIn", 'Log In')}
                                <span className={"k-icon k-i-arrow-chevron-right"} />
                            </Button>
                            {/* <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/signup");
                                }}
                            >
                                {localizationService.toLanguageString("custom.signUp", 'Sign Up')}
                                <span className={"k-icon k-i-arrow-chevron-right"} />
                            </Button> */}
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/");
                                }}
                            >
                                <span className={"k-icon k-i-arrow-chevron-left"} />
                                {localizationService.toLanguageString("custom.goBack", 'Go Back')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', }}
                privacyContainerStyle={{ padding: '10px 0' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </div>
    );
}

export default ForgotPasswordScreen;
