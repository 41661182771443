import { observable, action, makeObservable } from 'mobx';
import { persist } from 'mobx-persist';

import { HydratedStore } from 'src/utils/classes';

class LoginStore extends HydratedStore {
  constructor() {
    super('LoginStore'); // Storage ID

    makeObservable(this);
  }

  @persist @observable loginEmail: string = '';
  @persist twoFACode: string = '';
  @observable loginPassword: string = '';
  @persist('object') @observable dealer?: Dealer;
  @persist @observable dealerToken: string = '';

  @action setLoginEmail = (value: string) => this.loginEmail = value;
  @action setTwoFACode = (value: string) => this.twoFACode = value;
  @action setLoginPassword = (value: string) => this.loginPassword = value;
  @action setDealer = (value?: Dealer) => this.dealer = value;
  @action setDealerToken = (value: string) => this.dealerToken = value;
  @action doLogout = () => {
    this.dealerToken = '';
    this.dealer = undefined;
    this.loginPassword = '';
  };
}

export default new LoginStore();
