const capitalize = (str?:string) => {
  if (!str || !str.length) {
    return str;
  };

  const firstCP = str.codePointAt(0);
  const index = firstCP! > 0xFFFF ? 2 : 1;

  return String.fromCodePoint(firstCP!).toUpperCase() + str.slice(index);
}

export default capitalize;
