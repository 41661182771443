
import { observer } from 'mobx-react-lite';import * as React from "react";
import { Menu, MenuItem, MenuSelectEvent } from "@progress/kendo-react-layout";
import { Drawer, DrawerContent, DrawerItemProps, DrawerSelectEvent } from '@progress/kendo-react-layout';
import { withRouter } from 'react-router-dom';
import { LocalizationService, useLocalization } from '@progress/kendo-react-intl';

import { Header } from './Header';
import { useServices } from 'src/services';
import lincolnLogo from "../assets/images/LINC_Hz_Black.png";
import {useState} from "react";
import {MouseEventHandler} from "react";
import {useEffect} from "react";

const MenuNavContainer = (props: any) => {
    const onSelect = (event: MenuSelectEvent) => {
        props.history.push(event.item.data.route);
    };
    const { history, location, children } = props;
    const { dtg } = useServices();
    const items: DrawerItemProps[] = [
        { name: 'myEvents', icon: 'k-i-cloud', selected: true , route: '/events' },
        { name: 'myAccount', icon: 'k-i-user', route: '/profile' },

        // { name: 'dashboard', icon: 'k-i-grid', selected: true , route: '/dashboard' },
        // { name: 'planning', icon: 'k-i-calendar', route: '/planning' },
        { separator: true },
        // { name: 'help', icon: 'k-i-help', route: '/dashboard' },
        { name: 'contact', icon: 'k-i-email', route: '/events' },
        { separator: true },
        { name: 'logout', icon: 'k-i-logout', onClick:async () => {
                console.log('log out');
                await dtg.doLogout();
                history.push('/');
            } },
    ];
    const [ isSmallerScreen, setIsSmallerScreen ] = useState<boolean>(window.innerWidth < 768);
    const [ expanded, setExpanded ] = useState<boolean>(!isSmallerScreen);

    const resizeWindow = () => {
        setIsSmallerScreen(window.innerWidth < 768);
    }

    const handleClick:MouseEventHandler = () => {
        setExpanded(!expanded);
    }

    const handleSelect = (e:DrawerSelectEvent) => {
        setExpanded(!isSmallerScreen);
        history.push(e.itemTarget.props.route);
    }

    const getSelectedItem = (pathName:string) => {
        let currentPath = items.find(item => item.route === pathName);
        if (currentPath?.name) {
            return currentPath.name;
        }
    }
    useEffect(() => {
        window.addEventListener('resize', resizeWindow, false)
        resizeWindow();
        return () => {
            window.removeEventListener('resize', resizeWindow)
        }
    });

    let selected = getSelectedItem(location.pathname);
    const localizationService:LocalizationService = useLocalization();

    if (isSmallerScreen) {
        return (
            <React.Fragment>
                <Header
                    onButtonClick={handleClick}
                    page={localizationService.toLanguageString(`custom.${selected}`, 'CarePenguin')}
                />
                <Drawer
                    expanded={expanded}
                    animation={{duration: 100}}
                    items={items.map((item) => ({
                        ...item,
                        text: localizationService.toLanguageString(`custom.${item.name}`, item.name),
                        selected: item.name === selected
                    }))
                    }
                    position='start'
                    mode={isSmallerScreen ? 'overlay' : 'push'}
                    mini={isSmallerScreen ? false : true}

                    onOverlayClick={handleClick}
                    onSelect={handleSelect}
                >
                    <DrawerContent style={{height: 1066}}>
                        {children}
                    </DrawerContent>
                </Drawer>
            </React.Fragment>
        );
    }

    return (
        <>
            <div style={{display: 'flex'}}>
                <img src={lincolnLogo} alt="lincoln logo" style={{ width: 130, paddingLeft: '30px' }} />
                <Menu onSelect={onSelect} style={{padding: '35px 0 0 20px', color: 'black'}}>
                    <MenuItem cssStyle={{color: '#393939'}} text="My Events" data={{ route: "/events" }} />
                    <MenuItem cssStyle={{color: '#393939'}} text="My Account" data={{ route: "/profile" }} />
                    {/* <MenuItem cssStyle={{color: '#393939'}} text="Help" data={{ route: "/dashboard" }}/> */}
                    <MenuItem cssStyle={{color: '#393939'}} text="Contact" data={{ route: "/contact" }}/>

                </Menu>
                <p style={{padding: '30px 0 0 20px', fontSize: '13px', cursor: 'pointer'}} onClick={async () => {
                    console.log('log out');
                    await dtg.doLogout();
                    history.push('/');
                }}>
                    Log Out
                </p>
            </div>

            <div style={{ padding: 10 }}>{props.children}</div>
        </>
    );
};
export default withRouter(observer(MenuNavContainer));

