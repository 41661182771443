export const orders = [{
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 2490.5,
    "orderDate": 1531342800000,
    "requiredDate": 1533762000000,
    "shippedDate": 1531602000000,
    "requiredDateStart": 1533821400000,
    "requiredDateEnd": 1533826800000,
    "orderID": 10255,
    "customerID": "RICSU",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 148.33,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1873.8,
    "orderDate": 1532293200000,
    "requiredDate": 1534712400000,
    "shippedDate": 1532984400000,
    "requiredDateStart": 1534766400000,
    "requiredDateEnd": 1534771800000,
    "orderID": 10263,
    "customerID": "ERNSH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 146.06,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 5275.715,
    "orderDate": 1538946000000,
    "requiredDate": 1541368800000,
    "shippedDate": 1539118800000,
    "requiredDateStart": 1541415600000,
    "requiredDateEnd": 1541421000000,
    "orderID": 10324,
    "customerID": "SAVEA",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 214.27,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 88.5,
    "orderDate": 1539637200000,
    "requiredDate": 1543269600000,
    "shippedDate": 1540069200000,
    "requiredDateStart": 1543312800000,
    "requiredDateEnd": 1543316400000,
    "orderID": 10331,
    "customerID": "BONAP",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 10.19,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 166,
    "orderDate": 1545084000000,
    "requiredDate": 1546293600000,
    "shippedDate": 1545688800000,
    "requiredDateStart": 1546347600000,
    "requiredDateEnd": 1546353000000,
    "orderID": 10386,
    "customerID": "FAMIA",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 13.99,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 966.8,
    "orderDate": 1547071200000,
    "requiredDate": 1549490400000,
    "shippedDate": 1548021600000,
    "requiredDateStart": 1549530000000,
    "requiredDateEnd": 1549533600000,
    "orderID": 10411,
    "customerID": "BOTTM",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 23.65,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 1505.18,
    "orderDate": 1552514400000,
    "requiredDate": 1554930000000,
    "shippedDate": 1554325200000,
    "requiredDateStart": 1554989400000,
    "requiredDateEnd": 1554991200000,
    "orderID": 10475,
    "customerID": "SUPRD",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 68.52,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 149,
    "orderDate": 1554757200000,
    "requiredDate": 1557176400000,
    "shippedDate": 1555362000000,
    "requiredDateStart": 1557228600000,
    "requiredDateEnd": 1557232200000,
    "orderID": 10501,
    "customerID": "BLAUS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 8.85,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 415.8,
    "orderDate": 1555275600000,
    "requiredDate": 1557694800000,
    "shippedDate": 1556744400000,
    "requiredDateStart": 1557748800000,
    "requiredDateEnd": 1557754200000,
    "orderID": 10506,
    "customerID": "KOENE",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 21.19,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 139.8,
    "orderDate": 1557867600000,
    "requiredDate": 1560286800000,
    "shippedDate": 1557954000000,
    "requiredDateStart": 1560337200000,
    "requiredDateEnd": 1560342600000,
    "orderID": 10538,
    "customerID": "BSBEV",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 4.87,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1152.5,
    "orderDate": 1559509200000,
    "requiredDate": 1560718800000,
    "shippedDate": 1559768400000,
    "requiredDateStart": 1560771000000,
    "requiredDateEnd": 1560772800000,
    "orderID": 10557,
    "customerID": "LEHMS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 96.72,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 1761,
    "orderDate": 1560286800000,
    "requiredDate": 1562706000000,
    "shippedDate": 1560805200000,
    "requiredDateStart": 1562758200000,
    "requiredDateEnd": 1562761800000,
    "orderID": 10566,
    "customerID": "BLONP",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 88.4,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Trail's Head Gourmet Provisioners",
    "customerContactName": "Helvetius Nagy",
    "orderTotal": 569,
    "orderDate": 1561237200000,
    "requiredDate": 1564866000000,
    "shippedDate": 1561842000000,
    "requiredDateStart": 1564907400000,
    "requiredDateEnd": 1564911000000,
    "orderID": 10577,
    "customerID": "TRAIH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 25.41,
    "shipName": "Trail-s Head Gourmet Provisioners",
    "shipAddress": "722 DaVinci Blvd.",
    "shipCity": "Kirkland",
    "shipRegion": "WA",
    "shipPostalCode": "98034",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 23.8,
    "orderDate": 1562014800000,
    "requiredDate": 1564434000000,
    "shippedDate": 1562619600000,
    "requiredDateStart": 1564473600000,
    "requiredDateEnd": 1564477200000,
    "orderID": 10586,
    "customerID": "REGGC",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 0.48,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1446,
    "orderDate": 1566853200000,
    "requiredDate": 1570482000000,
    "shippedDate": 1567458000000,
    "requiredDateStart": 1570534200000,
    "requiredDateEnd": 1570537800000,
    "orderID": 10646,
    "customerID": "HUNGO",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 142.33,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 3815.25,
    "orderDate": 1568667600000,
    "requiredDate": 1569877200000,
    "shippedDate": 1569445200000,
    "requiredDateStart": 1569925800000,
    "requiredDateEnd": 1569927600000,
    "orderID": 10672,
    "customerID": "BERGS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 95.75,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 4960.9,
    "orderDate": 1569790800000,
    "requiredDate": 1572213600000,
    "shippedDate": 1572386400000,
    "requiredDateStart": 1572271200000,
    "requiredDateEnd": 1572273000000,
    "orderID": 10687,
    "customerID": "HUNGO",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 296.43,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 378,
    "orderDate": 1571086800000,
    "requiredDate": 1573509600000,
    "shippedDate": 1574028000000,
    "requiredDateStart": 1573569000000,
    "requiredDateEnd": 1573574400000,
    "orderID": 10705,
    "customerID": "HILAA",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 3.52,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 997,
    "orderDate": 1573423200000,
    "requiredDate": 1575842400000,
    "shippedDate": 1574287200000,
    "requiredDateStart": 1575892800000,
    "requiredDateEnd": 1575894600000,
    "orderID": 10736,
    "customerID": "HUNGO",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 44.1,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 4529.8,
    "orderDate": 1574028000000,
    "requiredDate": 1576447200000,
    "shippedDate": 1574805600000,
    "requiredDateStart": 1576488600000,
    "requiredDateEnd": 1576492200000,
    "orderID": 10745,
    "customerID": "QUICK",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 3.52,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 1590.5625,
    "orderDate": 1574287200000,
    "requiredDate": 1576706400000,
    "shippedDate": 1574546400000,
    "requiredDateStart": 1576765800000,
    "requiredDateEnd": 1576771200000,
    "orderID": 10750,
    "customerID": "WARTH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 79.3,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 344,
    "orderDate": 1575928800000,
    "requiredDate": 1578348000000,
    "shippedDate": 1577916000000,
    "requiredDateStart": 1578402000000,
    "requiredDateEnd": 1578407400000,
    "orderID": 10771,
    "customerID": "ERNSH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 11.19,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 12.5,
    "orderDate": 1576533600000,
    "requiredDate": 1578952800000,
    "shippedDate": 1576965600000,
    "requiredDateStart": 1579010400000,
    "requiredDateEnd": 1579015800000,
    "orderID": 10782,
    "customerID": "CACTU",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 1.1,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 1553.5,
    "orderDate": 1577311200000,
    "requiredDate": 1580940000000,
    "shippedDate": 1578175200000,
    "requiredDateStart": 1580983200000,
    "requiredDateEnd": 1580985000000,
    "orderID": 10799,
    "customerID": "KOENE",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 30.76,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rancho grande",
    "customerContactName": "Sergio Gutiérrez",
    "orderTotal": 932,
    "orderDate": 1578866400000,
    "requiredDate": 1580076000000,
    "shippedDate": 1580767200000,
    "requiredDateStart": 1580133600000,
    "requiredDateEnd": 1580139000000,
    "orderID": 10828,
    "customerID": "RANCH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 90.85,
    "shipName": "Rancho grande",
    "shipAddress": "Av. del Libertador 900",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 1764,
    "orderDate": 1578866400000,
    "requiredDate": 1581285600000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1581332400000,
    "requiredDateEnd": 1581334200000,
    "orderID": 10829,
    "customerID": "ISLAT",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 154.72,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1064.5,
    "orderDate": 1579125600000,
    "requiredDate": 1581544800000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1581595200000,
    "requiredDateEnd": 1581600600000,
    "orderID": 10837,
    "customerID": "BERGS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 13.32,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 967.819,
    "orderDate": 1579730400000,
    "requiredDate": 1582149600000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1582201800000,
    "requiredDateEnd": 1582207200000,
    "orderID": 10849,
    "customerID": "KOENE",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 0.56,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 625,
    "orderDate": 1580076000000,
    "requiredDate": 1582495200000,
    "shippedDate": 1580680800000,
    "requiredDateStart": 1582551000000,
    "requiredDateEnd": 1582554600000,
    "orderID": 10853,
    "customerID": "BLAUS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 53.83,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1979.23,
    "orderDate": 1580853600000,
    "requiredDate": 1583359200000,
    "shippedDate": 1581285600000,
    "requiredDateStart": 1583402400000,
    "requiredDateEnd": 1583404200000,
    "orderID": 10871,
    "customerID": "BONAP",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 112.27,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 11380,
    "orderDate": 1581804000000,
    "requiredDate": 1584309600000,
    "shippedDate": 1582408800000,
    "requiredDateStart": 1584361800000,
    "requiredDateEnd": 1584367200000,
    "orderID": 10889,
    "customerID": "RATTC",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 280.61,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 5502.11,
    "orderDate": 1581976800000,
    "requiredDate": 1584482400000,
    "shippedDate": 1582149600000,
    "requiredDateStart": 1584540000000,
    "requiredDateEnd": 1584541800000,
    "orderID": 10893,
    "customerID": "KOENE",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 77.78,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 342,
    "orderDate": 1582495200000,
    "requiredDate": 1585000800000,
    "shippedDate": 1583445600000,
    "requiredDateStart": 1585053000000,
    "requiredDateEnd": 1585058400000,
    "orderID": 10905,
    "customerID": "WELLI",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 13.72,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 560,
    "orderDate": 1583877600000,
    "requiredDate": 1586293200000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1586332800000,
    "requiredDateEnd": 1586334600000,
    "orderID": 10942,
    "customerID": "REGGC",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 17.95,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 458.755,
    "orderDate": 1584309600000,
    "requiredDate": 1587934800000,
    "shippedDate": 1586206800000,
    "requiredDateStart": 1587978000000,
    "requiredDateEnd": 1587979800000,
    "orderID": 10951,
    "customerID": "RICSU",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 30.85,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 4441.25,
    "orderDate": 1584309600000,
    "requiredDate": 1585515600000,
    "shippedDate": 1585087200000,
    "requiredDateStart": 1585560600000,
    "requiredDateEnd": 1585562400000,
    "orderID": 10953,
    "customerID": "AROUT",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 23.72,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 57.8,
    "orderDate": 1584568800000,
    "requiredDate": 1586984400000,
    "shippedDate": 1585173600000,
    "requiredDateStart": 1587043800000,
    "requiredDateEnd": 1587045600000,
    "orderID": 10963,
    "customerID": "FURIB",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 2.7,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Bólido Comidas preparadas",
    "customerContactName": "Martín Sommer",
    "orderTotal": 224,
    "orderDate": 1585000800000,
    "requiredDate": 1586206800000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1586246400000,
    "requiredDateEnd": 1586248200000,
    "orderID": 10970,
    "customerID": "BOLID",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 1,
    "freight": 16.16,
    "shipName": "Bólido Comidas preparadas",
    "shipAddress": "C/ Araquil, 67",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28023",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 1303.195,
    "orderDate": 1585173600000,
    "requiredDate": 1587589200000,
    "shippedDate": 1587589200000,
    "requiredDateStart": 1587639600000,
    "requiredDateEnd": 1587645000000,
    "orderID": 10978,
    "customerID": "MAISD",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 32.82,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 491.5,
    "orderDate": 1586466000000,
    "requiredDate": 1588885200000,
    "shippedDate": 1586725200000,
    "requiredDateStart": 1588924800000,
    "requiredDateEnd": 1588926600000,
    "orderID": 11016,
    "customerID": "AROUT",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 33.8,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 6750,
    "orderDate": 1586725200000,
    "requiredDate": 1589144400000,
    "shippedDate": 1587330000000,
    "requiredDateStart": 1589196600000,
    "requiredDateEnd": 1589198400000,
    "orderID": 11017,
    "customerID": "ERNSH",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 754.26,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1402,
    "orderDate": 1586811600000,
    "requiredDate": 1589230800000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1589283000000,
    "requiredDateEnd": 1589286600000,
    "orderID": 11022,
    "customerID": "HANAR",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 2,
    "freight": 6.27,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 858,
    "orderDate": 1588107600000,
    "requiredDate": 1590526800000,
    "shippedDate": 1532379600000,
    "requiredDateStart": 1590586200000,
    "requiredDateEnd": 1590588000000,
    "orderID": 11058,
    "customerID": "BLAUS",
    "employeeID": "a765924f-8ffb-4186-8991-6000a6ce2652",
    "teamID": 4,
    "shipVia": 3,
    "freight": 31.14,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 584,
    "orderDate": 1532206800000,
    "requiredDate": 1534626000000,
    "shippedDate": 1532466000000,
    "requiredDateStart": 1534672800000,
    "requiredDateEnd": 1534676400000,
    "orderID": 10262,
    "customerID": "RATTC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 48.29,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "GROSELLA-Restaurante",
    "customerContactName": "Manuel Pereira",
    "orderTotal": 1101.2,
    "orderDate": 1532898000000,
    "requiredDate": 1535317200000,
    "shippedDate": 1533157200000,
    "requiredDateStart": 1535369400000,
    "requiredDateEnd": 1535371200000,
    "orderID": 10268,
    "customerID": "GROSR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 66.29,
    "shipName": "GROSELLA-Restaurante",
    "shipAddress": "5ª Ave. Los Palos Grandes",
    "shipCity": "Caracas",
    "shipRegion": "DF",
    "shipPostalCode": "1081",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 420,
    "orderDate": 1533675600000,
    "requiredDate": 1534885200000,
    "shippedDate": 1534194000000,
    "requiredDateStart": 1534928400000,
    "requiredDateEnd": 1534930200000,
    "orderID": 10276,
    "customerID": "TORTU",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 13.84,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1488.8,
    "orderDate": 1534021200000,
    "requiredDate": 1536440400000,
    "shippedDate": 1534366800000,
    "requiredDateStart": 1536494400000,
    "requiredDateEnd": 1536498000000,
    "orderID": 10278,
    "customerID": "BERGS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 92.69,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 351,
    "orderDate": 1534107600000,
    "requiredDate": 1536526800000,
    "shippedDate": 1534366800000,
    "requiredDateStart": 1536568200000,
    "requiredDateEnd": 1536571800000,
    "orderID": 10279,
    "customerID": "LEHMS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 25.83,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3016,
    "orderDate": 1534798800000,
    "requiredDate": 1537218000000,
    "shippedDate": 1535576400000,
    "requiredDateStart": 1537261200000,
    "requiredDateEnd": 1537264800000,
    "orderID": 10286,
    "customerID": "QUICK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 229.24,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 819,
    "orderDate": 1534885200000,
    "requiredDate": 1537304400000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1537351200000,
    "requiredDateEnd": 1537356600000,
    "orderID": 10287,
    "customerID": "RICAR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 12.76,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Comércio Mineiro",
    "customerContactName": "Pedro Afonso",
    "orderTotal": 2169,
    "orderDate": 1535317200000,
    "requiredDate": 1537736400000,
    "shippedDate": 1535922000000,
    "requiredDateStart": 1537776000000,
    "requiredDateEnd": 1537781400000,
    "orderID": 10290,
    "customerID": "COMMI",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 79.7,
    "shipName": "Comércio Mineiro",
    "shipAddress": "Av. dos Lusíadas, 23",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05432-043",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 755,
    "orderDate": 1536440400000,
    "requiredDate": 1538859600000,
    "shippedDate": 1537131600000,
    "requiredDateStart": 1538919000000,
    "requiredDateEnd": 1538922600000,
    "orderID": 10301,
    "customerID": "WANDK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 45.08,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 3741.3,
    "orderDate": 1536786000000,
    "requiredDate": 1539205200000,
    "shippedDate": 1539032400000,
    "requiredDateStart": 1539248400000,
    "requiredDateEnd": 1539250200000,
    "orderID": 10305,
    "customerID": "OLDWO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 257.62,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "The Big Cheese",
    "customerContactName": "Liz Nixon",
    "orderTotal": 336,
    "orderDate": 1537390800000,
    "requiredDate": 1539810000000,
    "shippedDate": 1537995600000,
    "requiredDateStart": 1539865800000,
    "requiredDateEnd": 1539869400000,
    "orderID": 10310,
    "customerID": "THEBI",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 17.52,
    "shipName": "The Big Cheese",
    "shipAddress": "89 Jefferson Way Suite 2",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97201",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 240.4,
    "orderDate": 1538341200000,
    "requiredDate": 1540764000000,
    "shippedDate": 1538600400000,
    "requiredDateStart": 1540810800000,
    "requiredDateEnd": 1540816200000,
    "orderID": 10318,
    "customerID": "ISLAT",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 4.73,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 144.8,
    "orderDate": 1540069200000,
    "requiredDate": 1542492000000,
    "shippedDate": 1540674000000,
    "requiredDateStart": 1542549600000,
    "requiredDateEnd": 1542555000000,
    "orderID": 10334,
    "customerID": "VICTE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 8.56,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 568.8,
    "orderDate": 1542146400000,
    "requiredDate": 1544565600000,
    "shippedDate": 1542664800000,
    "requiredDateStart": 1544621400000,
    "requiredDateEnd": 1544625000000,
    "orderID": 10354,
    "customerID": "PERIC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 53.8,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Galería del gastrónomo",
    "customerContactName": "Eduardo Saavedra",
    "orderTotal": 136,
    "orderDate": 1543356000000,
    "requiredDate": 1546984800000,
    "shippedDate": 1546120800000,
    "requiredDateStart": 1547033400000,
    "requiredDateEnd": 1547037000000,
    "orderID": 10366,
    "customerID": "GALED",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 10.14,
    "shipName": "Galería del gastronómo",
    "shipAddress": "Rambla de Cataluña, 23",
    "shipCity": "Barcelona",
    "shipRegion": "",
    "shipPostalCode": "8022",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 2390.4,
    "orderDate": 1543701600000,
    "requiredDate": 1546120800000,
    "shippedDate": 1544306400000,
    "requiredDateStart": 1546180200000,
    "requiredDateEnd": 1546182000000,
    "orderID": 10369,
    "customerID": "SPLIR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 195.68,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1313.82,
    "orderDate": 1544565600000,
    "requiredDate": 1546984800000,
    "shippedDate": 1547589600000,
    "requiredDateStart": 1547044200000,
    "requiredDateEnd": 1547046000000,
    "orderID": 10380,
    "customerID": "HUNGO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 35.03,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 899,
    "orderDate": 1544911200000,
    "requiredDate": 1547330400000,
    "shippedDate": 1545084000000,
    "requiredDateStart": 1547389800000,
    "requiredDateEnd": 1547395200000,
    "orderID": 10383,
    "customerID": "AROUT",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 34.24,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 1765.6,
    "orderDate": 1546207200000,
    "requiredDate": 1547416800000,
    "shippedDate": 1546898400000,
    "requiredDateStart": 1547458200000,
    "requiredDateEnd": 1547463600000,
    "orderID": 10399,
    "customerID": "VAFFE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 27.36,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2713.5,
    "orderDate": 1546380000000,
    "requiredDate": 1550008800000,
    "shippedDate": 1547071200000,
    "requiredDateStart": 1550059200000,
    "requiredDateEnd": 1550064600000,
    "orderID": 10402,
    "customerID": "ERNSH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 67.88,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Folies gourmandes",
    "customerContactName": "Martine Rancé",
    "orderTotal": 1622.4,
    "orderDate": 1546898400000,
    "requiredDate": 1549317600000,
    "shippedDate": 1547416800000,
    "requiredDateStart": 1549369800000,
    "requiredDateEnd": 1549373400000,
    "orderID": 10408,
    "customerID": "FOLIG",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 11.26,
    "shipName": "Folies gourmandes",
    "shipAddress": "184, chaussée de Tournai",
    "shipCity": "Lille",
    "shipRegion": "",
    "shipPostalCode": "59000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 334.8,
    "orderDate": 1547330400000,
    "requiredDate": 1549749600000,
    "shippedDate": 1547503200000,
    "requiredDateStart": 1549789200000,
    "requiredDateEnd": 1549791000000,
    "orderID": 10412,
    "customerID": "WARTH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 3.77,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 720,
    "orderDate": 1547589600000,
    "requiredDate": 1550008800000,
    "shippedDate": 1548540000000,
    "requiredDateStart": 1550064600000,
    "requiredDateEnd": 1550068200000,
    "orderID": 10416,
    "customerID": "WARTH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 22.72,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 1194.27,
    "orderDate": 1548021600000,
    "requiredDate": 1551650400000,
    "shippedDate": 1548540000000,
    "requiredDateStart": 1551709800000,
    "requiredDateEnd": 1551713400000,
    "orderID": 10421,
    "customerID": "QUEDE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 99.23,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Consolidated Holdings",
    "customerContactName": "Elizabeth Brown",
    "orderTotal": 631.6,
    "orderDate": 1549231200000,
    "requiredDate": 1552860000000,
    "shippedDate": 1549490400000,
    "requiredDateStart": 1552899600000,
    "requiredDateEnd": 1552905000000,
    "orderID": 10435,
    "customerID": "CONSH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 9.21,
    "shipName": "Consolidated Holdings",
    "shipAddress": "Berkeley Gardens 12  Brewery",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX1 6LT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 393,
    "orderDate": 1549317600000,
    "requiredDate": 1551736800000,
    "shippedDate": 1549922400000,
    "requiredDateStart": 1551781800000,
    "requiredDateEnd": 1551787200000,
    "orderID": 10437,
    "customerID": "WARTH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 19.97,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 517.44,
    "orderDate": 1549922400000,
    "requiredDate": 1552341600000,
    "shippedDate": 1550095200000,
    "requiredDateStart": 1552397400000,
    "requiredDateEnd": 1552402800000,
    "orderID": 10443,
    "customerID": "REGGC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 13.95,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 425.12,
    "orderDate": 1550527200000,
    "requiredDate": 1552946400000,
    "shippedDate": 1552255200000,
    "requiredDateStart": 1552991400000,
    "requiredDateEnd": 1552996800000,
    "orderID": 10450,
    "customerID": "VICTE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 7.23,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2018.5,
    "orderDate": 1550613600000,
    "requiredDate": 1553032800000,
    "shippedDate": 1551132000000,
    "requiredDateStart": 1553092200000,
    "requiredDateEnd": 1553094000000,
    "orderID": 10452,
    "customerID": "SAVEA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 140.26,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 2684,
    "orderDate": 1550959200000,
    "requiredDate": 1554584400000,
    "shippedDate": 1551564000000,
    "requiredDateStart": 1554636600000,
    "requiredDateEnd": 1554642000000,
    "orderID": 10455,
    "customerID": "WARTH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 180.45,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 557.6,
    "orderDate": 1551045600000,
    "requiredDate": 1554670800000,
    "shippedDate": 1551304800000,
    "requiredDateStart": 1554730200000,
    "requiredDateEnd": 1554735600000,
    "orderID": 10456,
    "customerID": "KOENE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 8.12,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 176.1,
    "orderDate": 1551304800000,
    "requiredDate": 1553724000000,
    "shippedDate": 1551564000000,
    "requiredDateStart": 1553781600000,
    "requiredDateEnd": 1553785200000,
    "orderID": 10460,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 16.27,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 235.2,
    "orderDate": 1551823200000,
    "requiredDate": 1554238800000,
    "shippedDate": 1552255200000,
    "requiredDateStart": 1554291000000,
    "requiredDateEnd": 1554292800000,
    "orderID": 10467,
    "customerID": "MAGAA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 4.93,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 1036.8,
    "orderDate": 1552341600000,
    "requiredDate": 1554757200000,
    "shippedDate": 1552946400000,
    "requiredDateStart": 1554796800000,
    "requiredDateEnd": 1554798600000,
    "orderID": 10472,
    "customerID": "SEVES",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 4.2,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 180.48,
    "orderDate": 1552773600000,
    "requiredDate": 1555189200000,
    "shippedDate": 1553378400000,
    "requiredDateStart": 1555232400000,
    "requiredDateEnd": 1555234200000,
    "orderID": 10476,
    "customerID": "HILAA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 4.41,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 1472,
    "orderDate": 1553032800000,
    "requiredDate": 1555448400000,
    "shippedDate": 1553464800000,
    "requiredDateStart": 1555493400000,
    "requiredDateEnd": 1555498800000,
    "orderID": 10481,
    "customerID": "RICAR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 64.33,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1512,
    "orderDate": 1553637600000,
    "requiredDate": 1556053200000,
    "shippedDate": 1554152400000,
    "requiredDateStart": 1556109000000,
    "requiredDateEnd": 1556114400000,
    "orderID": 10488,
    "customerID": "FRANK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 4.93,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 259.505,
    "orderDate": 1553983200000,
    "requiredDate": 1556398800000,
    "shippedDate": 1554670800000,
    "requiredDateStart": 1556442000000,
    "requiredDateEnd": 1556445600000,
    "orderID": 10491,
    "customerID": "FURIB",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 16.96,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 575,
    "orderDate": 1554584400000,
    "requiredDate": 1557003600000,
    "shippedDate": 1554930000000,
    "requiredDateStart": 1557059400000,
    "requiredDateEnd": 1557063000000,
    "orderID": 10498,
    "customerID": "HILAA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 29.75,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 225.5,
    "orderDate": 1556485200000,
    "requiredDate": 1558904400000,
    "shippedDate": 1556744400000,
    "requiredDateStart": 1558963800000,
    "requiredDateEnd": 1558965600000,
    "orderID": 10521,
    "customerID": "CACTU",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 17.22,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 2222.2,
    "orderDate": 1557608400000,
    "requiredDate": 1560027600000,
    "shippedDate": 1558472400000,
    "requiredDateStart": 1560067200000,
    "requiredDateEnd": 1560072600000,
    "orderID": 10533,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 188.04,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 465.7,
    "orderDate": 1557608400000,
    "requiredDate": 1560027600000,
    "shippedDate": 1557781200000,
    "requiredDateStart": 1560076200000,
    "requiredDateEnd": 1560078000000,
    "orderID": 10534,
    "customerID": "LEHMS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 27.94,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1504.5,
    "orderDate": 1558386000000,
    "requiredDate": 1560805200000,
    "shippedDate": 1558558800000,
    "requiredDateStart": 1560850200000,
    "requiredDateEnd": 1560855600000,
    "orderID": 10543,
    "customerID": "LILAS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 48.17,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Lazy K Kountry Store",
    "customerContactName": "John Steel",
    "orderTotal": 210,
    "orderDate": 1558472400000,
    "requiredDate": 1560891600000,
    "shippedDate": 1561496400000,
    "requiredDateStart": 1560931200000,
    "requiredDateEnd": 1560936600000,
    "orderID": 10545,
    "customerID": "LAZYK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 11.92,
    "shipName": "Lazy K Kountry Store",
    "shipAddress": "12 Orchestra Terrace",
    "shipCity": "Walla Walla",
    "shipRegion": "WA",
    "shipPostalCode": "99362",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1072.425,
    "orderDate": 1559768400000,
    "requiredDate": 1562187600000,
    "shippedDate": 1560027600000,
    "requiredDateStart": 1562238000000,
    "requiredDateEnd": 1562239800000,
    "orderID": 10560,
    "customerID": "FRANK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 36.65,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 639.9,
    "orderDate": 1560200400000,
    "requiredDate": 1562619600000,
    "shippedDate": 1560805200000,
    "requiredDateStart": 1562670000000,
    "requiredDateEnd": 1562673600000,
    "orderID": 10565,
    "customerID": "MEREP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 7.15,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 550.5875,
    "orderDate": 1560718800000,
    "requiredDate": 1564347600000,
    "shippedDate": 1562187600000,
    "requiredDateStart": 1564396200000,
    "requiredDateEnd": 1564399800000,
    "orderID": 10571,
    "customerID": "ERNSH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 26.06,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 72,
    "orderDate": 1562187600000,
    "requiredDate": 1564606800000,
    "shippedDate": 1563051600000,
    "requiredDateStart": 1564648200000,
    "requiredDateEnd": 1564650000000,
    "orderID": 10589,
    "customerID": "GREAL",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 4.42,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 1180.88,
    "orderDate": 1562792400000,
    "requiredDate": 1565211600000,
    "shippedDate": 1565557200000,
    "requiredDateStart": 1565265600000,
    "requiredDateEnd": 1565269200000,
    "orderID": 10596,
    "customerID": "WHITC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 16.34,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 48.75,
    "orderDate": 1563310800000,
    "requiredDate": 1565730000000,
    "shippedDate": 1563742800000,
    "requiredDateStart": 1565780400000,
    "requiredDateEnd": 1565785800000,
    "orderID": 10602,
    "customerID": "VAFFE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 2.92,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1483,
    "orderDate": 1563397200000,
    "requiredDate": 1565816400000,
    "shippedDate": 1565211600000,
    "requiredDateStart": 1565861400000,
    "requiredDateEnd": 1565866800000,
    "orderID": 10603,
    "customerID": "SAVEA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 48.77,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 299.25,
    "orderDate": 1564002000000,
    "requiredDate": 1566421200000,
    "shippedDate": 1565038800000,
    "requiredDateStart": 1566473400000,
    "requiredDateEnd": 1566477000000,
    "orderID": 10610,
    "customerID": "LAMAI",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 26.78,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 464,
    "orderDate": 1564347600000,
    "requiredDate": 1566766800000,
    "shippedDate": 1564606800000,
    "requiredDateStart": 1566810000000,
    "requiredDateEnd": 1566815400000,
    "orderID": 10614,
    "customerID": "BLAUS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 1.93,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1336.95,
    "orderDate": 1565125200000,
    "requiredDate": 1567544400000,
    "shippedDate": 1565557200000,
    "requiredDateStart": 1567596600000,
    "requiredDateEnd": 1567600200000,
    "orderID": 10623,
    "customerID": "FRANK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 97.18,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1185.75,
    "orderDate": 1565470800000,
    "requiredDate": 1569099600000,
    "shippedDate": 1566334800000,
    "requiredDateStart": 1569141000000,
    "requiredDateEnd": 1569144600000,
    "orderID": 10627,
    "customerID": "SAVEA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 107.46,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 55.8,
    "orderDate": 1565730000000,
    "requiredDate": 1568149200000,
    "shippedDate": 1565816400000,
    "requiredDateStart": 1568208600000,
    "requiredDateEnd": 1568212200000,
    "orderID": 10631,
    "customerID": "LAMAI",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 0.87,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 589,
    "orderDate": 1565730000000,
    "requiredDate": 1568149200000,
    "shippedDate": 1566162000000,
    "requiredDateStart": 1568205000000,
    "requiredDateEnd": 1568206800000,
    "orderID": 10632,
    "customerID": "WANDK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 41.38,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 1326.225,
    "orderDate": 1566075600000,
    "requiredDate": 1568494800000,
    "shippedDate": 1566334800000,
    "requiredDateStart": 1568536200000,
    "requiredDateEnd": 1568541600000,
    "orderID": 10635,
    "customerID": "MAGAA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 47.46,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 397.8,
    "orderDate": 1567285200000,
    "requiredDate": 1569704400000,
    "shippedDate": 1568149200000,
    "requiredDateStart": 1569758400000,
    "requiredDateEnd": 1569762000000,
    "orderID": 10651,
    "customerID": "WANDK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 20.6,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Hungry Coyote Import Store",
    "customerContactName": "Yoshi Latimer",
    "orderTotal": 1701,
    "orderDate": 1567890000000,
    "requiredDate": 1570309200000,
    "shippedDate": 1571086800000,
    "requiredDateStart": 1570348800000,
    "requiredDateEnd": 1570350600000,
    "orderID": 10660,
    "customerID": "HUNGC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 111.29,
    "shipName": "Hungry Coyote Import Store",
    "shipAddress": "City Center Plaza 516 Main St.",
    "shipCity": "Elgin",
    "shipRegion": "OR",
    "shipPostalCode": "97827",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 660,
    "orderDate": 1569186000000,
    "requiredDate": 1571605200000,
    "shippedDate": 1569790800000,
    "requiredDateStart": 1571648400000,
    "requiredDateEnd": 1571650200000,
    "orderID": 10679,
    "customerID": "BLONP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 27.94,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 4825,
    "orderDate": 1570309200000,
    "requiredDate": 1572732000000,
    "shippedDate": 1570568400000,
    "requiredDateStart": 1572786000000,
    "requiredDateEnd": 1572787800000,
    "orderID": 10694,
    "customerID": "QUICK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 398.36,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 996,
    "orderDate": 1570482000000,
    "requiredDate": 1574114400000,
    "shippedDate": 1571000400000,
    "requiredDateStart": 1574166600000,
    "requiredDateEnd": 1574172000000,
    "orderID": 10696,
    "customerID": "WHITC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 102.55,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 1893,
    "orderDate": 1571173200000,
    "requiredDate": 1573596000000,
    "shippedDate": 1571605200000,
    "requiredDateStart": 1573653600000,
    "requiredDateEnd": 1573659000000,
    "orderID": 10706,
    "customerID": "OLDWO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 135.63,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Let's Stop N Shop",
    "customerContactName": "Jaime Yorres",
    "orderTotal": 844.2525,
    "orderDate": 1572123600000,
    "requiredDate": 1574546400000,
    "shippedDate": 1572904800000,
    "requiredDateStart": 1574598600000,
    "requiredDateEnd": 1574602200000,
    "orderID": 10719,
    "customerID": "LETSS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 51.44,
    "shipName": "Let-s Stop N Shop",
    "shipAddress": "87 Polk St. Suite 5",
    "shipCity": "San Francisco",
    "shipRegion": "CA",
    "shipPostalCode": "94117",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 550,
    "orderDate": 1572213600000,
    "requiredDate": 1573423200000,
    "shippedDate": 1572904800000,
    "requiredDateStart": 1573473600000,
    "requiredDateEnd": 1573477200000,
    "orderID": 10720,
    "customerID": "QUEDE",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 9.53,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1570,
    "orderDate": 1572300000000,
    "requiredDate": 1575928800000,
    "shippedDate": 1572818400000,
    "requiredDateStart": 1575979200000,
    "requiredDateEnd": 1575981000000,
    "orderID": 10722,
    "customerID": "SAVEA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 74.58,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 638.5,
    "orderDate": 1572386400000,
    "requiredDate": 1576015200000,
    "shippedDate": 1572904800000,
    "requiredDateStart": 1576072800000,
    "requiredDateEnd": 1576078200000,
    "orderID": 10724,
    "customerID": "MEREP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 57.75,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 1850,
    "orderDate": 1572818400000,
    "requiredDate": 1576447200000,
    "shippedDate": 1573682400000,
    "requiredDateStart": 1576490400000,
    "requiredDateEnd": 1576495800000,
    "orderID": 10729,
    "customerID": "LINOD",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 141.06,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 1990,
    "orderDate": 1574805600000,
    "requiredDate": 1577224800000,
    "shippedDate": 1575237600000,
    "requiredDateStart": 1577275200000,
    "requiredDateEnd": 1577277000000,
    "orderID": 10756,
    "customerID": "SPLIR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 73.21,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 236.25,
    "orderDate": 1575842400000,
    "requiredDate": 1578261600000,
    "shippedDate": 1576533600000,
    "requiredDateStart": 1578303000000,
    "requiredDateEnd": 1578304800000,
    "orderID": 10770,
    "customerID": "HANAR",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 5.32,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1531.08,
    "orderDate": 1576706400000,
    "requiredDate": 1579125600000,
    "shippedDate": 1577052000000,
    "requiredDateStart": 1579177800000,
    "requiredDateEnd": 1579183200000,
    "orderID": 10786,
    "customerID": "QUEEN",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 110.87,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2158,
    "orderDate": 1577138400000,
    "requiredDate": 1579557600000,
    "shippedDate": 1579471200000,
    "requiredDateStart": 1579609800000,
    "requiredDateEnd": 1579613400000,
    "orderID": 10795,
    "customerID": "ERNSH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 126.66,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 852,
    "orderDate": 1577916000000,
    "requiredDate": 1580335200000,
    "shippedDate": 1578434400000,
    "requiredDateStart": 1580376600000,
    "requiredDateEnd": 1580380200000,
    "orderID": 10811,
    "customerID": "LINOD",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 31.22,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 250.8,
    "orderDate": 1578520800000,
    "requiredDate": 1580940000000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1580994000000,
    "requiredDateEnd": 1580995800000,
    "orderID": 10824,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 1.23,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 735,
    "orderDate": 1579557600000,
    "requiredDate": 1581976800000,
    "shippedDate": 1579989600000,
    "requiredDateStart": 1582018200000,
    "requiredDateEnd": 1582021800000,
    "orderID": 10844,
    "customerID": "PICCO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 25.22,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3812.7,
    "orderDate": 1579557600000,
    "requiredDate": 1580767200000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1580826600000,
    "requiredDateEnd": 1580832000000,
    "orderID": 10845,
    "customerID": "QUICK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 212.98,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 2984,
    "orderDate": 1579989600000,
    "requiredDate": 1581199200000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1581258600000,
    "requiredDateEnd": 1581264000000,
    "orderID": 10852,
    "customerID": "RATTC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 174.05,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 2048.2125,
    "orderDate": 1580162400000,
    "requiredDate": 1582581600000,
    "shippedDate": 1580940000000,
    "requiredDateStart": 1582632000000,
    "requiredDateEnd": 1582637400000,
    "orderID": 10857,
    "customerID": "BERGS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 188.85,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 581,
    "orderDate": 1580335200000,
    "requiredDate": 1584050400000,
    "shippedDate": 1580594400000,
    "requiredDateStart": 1584100800000,
    "requiredDateEnd": 1584102600000,
    "orderID": 10862,
    "customerID": "LEHMS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 53.23,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 36,
    "orderDate": 1581458400000,
    "requiredDate": 1583964000000,
    "shippedDate": 1582149600000,
    "requiredDateStart": 1584012600000,
    "requiredDateEnd": 1584018000000,
    "orderID": 10883,
    "customerID": "LONEP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 0.53,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Galería del gastrónomo",
    "customerContactName": "Eduardo Saavedra",
    "orderTotal": 70,
    "orderDate": 1581544800000,
    "requiredDate": 1584050400000,
    "shippedDate": 1581804000000,
    "requiredDateStart": 1584095400000,
    "requiredDateEnd": 1584097200000,
    "orderID": 10887,
    "customerID": "GALED",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 1.25,
    "shipName": "Galería del gastronómo",
    "shipAddress": "Rambla de Cataluña, 23",
    "shipCity": "Barcelona",
    "shipRegion": "",
    "shipPostalCode": "8022",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1788.63,
    "orderDate": 1583445600000,
    "requiredDate": 1585861200000,
    "shippedDate": 1585000800000,
    "requiredDateStart": 1585911600000,
    "requiredDateEnd": 1585917000000,
    "orderID": 10932,
    "customerID": "BONAP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 134.64,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 360,
    "orderDate": 1583877600000,
    "requiredDate": 1586293200000,
    "shippedDate": 1584914400000,
    "requiredDateStart": 1586334600000,
    "requiredDateEnd": 1586336400000,
    "orderID": 10940,
    "customerID": "BONAP",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 19.77,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 74.4,
    "orderDate": 1584396000000,
    "requiredDate": 1586811600000,
    "shippedDate": 1584655200000,
    "requiredDateStart": 1586871000000,
    "requiredDateEnd": 1586872800000,
    "orderID": 10955,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 3.26,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 1762.7,
    "orderDate": 1584482400000,
    "requiredDate": 1586898000000,
    "shippedDate": 1585260000000,
    "requiredDateStart": 1586944800000,
    "requiredDateEnd": 1586948400000,
    "orderID": 10957,
    "customerID": "HILAA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 105.36,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1119.9,
    "orderDate": 1584568800000,
    "requiredDate": 1586984400000,
    "shippedDate": 1585515600000,
    "requiredDateStart": 1587031200000,
    "requiredDateEnd": 1587033000000,
    "orderID": 10961,
    "customerID": "QUEEN",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 104.47,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3584,
    "orderDate": 1584568800000,
    "requiredDate": 1586984400000,
    "shippedDate": 1584914400000,
    "requiredDateStart": 1587038400000,
    "requiredDateEnd": 1587043800000,
    "orderID": 10962,
    "customerID": "QUICK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 275.79,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 2233,
    "orderDate": 1585173600000,
    "requiredDate": 1587589200000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1587634200000,
    "requiredDateEnd": 1587636000000,
    "orderID": 10977,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 208.5,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4813.5,
    "orderDate": 1585173600000,
    "requiredDate": 1587589200000,
    "shippedDate": 1585602000000,
    "requiredDateStart": 1587639600000,
    "requiredDateEnd": 1587643200000,
    "orderID": 10979,
    "customerID": "ERNSH",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 353.07,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Océano Atlántico Ltda.",
    "customerContactName": "Yvonne Moncada",
    "orderTotal": 2220,
    "orderDate": 1585515600000,
    "requiredDate": 1587934800000,
    "shippedDate": 1587416400000,
    "requiredDateStart": 1587979800000,
    "requiredDateEnd": 1587983400000,
    "orderID": 10986,
    "customerID": "OCEAN",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 217.86,
    "shipName": "Océano Atlántico Ltda.",
    "shipAddress": "Ing. Gustavo Moncada 8585 Piso 20-A",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 2772,
    "orderDate": 1585602000000,
    "requiredDate": 1588021200000,
    "shippedDate": 1586120400000,
    "requiredDateStart": 1588060800000,
    "requiredDateEnd": 1588064400000,
    "orderID": 10987,
    "customerID": "EASTC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 185.48,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1885,
    "orderDate": 1585861200000,
    "requiredDate": 1589490000000,
    "shippedDate": 1586725200000,
    "requiredDateStart": 1589542200000,
    "requiredDateEnd": 1589547600000,
    "orderID": 10997,
    "customerID": "LILAS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 73.91,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 686,
    "orderDate": 1585861200000,
    "requiredDate": 1587070800000,
    "shippedDate": 1587070800000,
    "requiredDateStart": 1587121200000,
    "requiredDateEnd": 1587123000000,
    "orderID": 10998,
    "customerID": "WOLZA",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 20.31,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Princesa Isabel Vinhos",
    "customerContactName": "Isabel de Castro",
    "orderTotal": 2633.9,
    "orderDate": 1586293200000,
    "requiredDate": 1588712400000,
    "shippedDate": 1586725200000,
    "requiredDateStart": 1588759200000,
    "requiredDateEnd": 1588764600000,
    "orderID": 11007,
    "customerID": "PRINI",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 202.24,
    "shipName": "Princesa Isabel Vinhos",
    "shipAddress": "Estrada da saúde n. 58",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1756",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 539.4,
    "orderDate": 1587330000000,
    "requiredDate": 1590958800000,
    "shippedDate": 1587934800000,
    "requiredDateStart": 1591016400000,
    "requiredDateEnd": 1591021800000,
    "orderID": 11034,
    "customerID": "OLDWO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 40.32,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 1692,
    "orderDate": 1587330000000,
    "requiredDate": 1589749200000,
    "shippedDate": 1587502800000,
    "requiredDateStart": 1589794200000,
    "requiredDateEnd": 1589797800000,
    "orderID": 11036,
    "customerID": "DRACD",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 3,
    "freight": 149.47,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 1485.8,
    "orderDate": 1587589200000,
    "requiredDate": 1590008400000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1590066000000,
    "requiredDateEnd": 1590067800000,
    "orderID": 11046,
    "customerID": "WANDK",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 71.64,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 810,
    "orderDate": 1587934800000,
    "requiredDate": 1590354000000,
    "shippedDate": 1588626000000,
    "requiredDateStart": 1590404400000,
    "requiredDateEnd": 1590409800000,
    "orderID": 11050,
    "customerID": "FOLKO",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 59.41,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 305,
    "orderDate": 1588021200000,
    "requiredDate": 1590440400000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1590498000000,
    "requiredDateEnd": 1590499800000,
    "orderID": 11054,
    "customerID": "CACTU",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 0.33,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 3740,
    "orderDate": 1588021200000,
    "requiredDate": 1589230800000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1589270400000,
    "requiredDateEnd": 1589274000000,
    "orderID": 11056,
    "customerID": "EASTC",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 278.96,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 189.42,
    "orderDate": 1588280400000,
    "requiredDate": 1590699600000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1590755400000,
    "requiredDateEnd": 1590757200000,
    "orderID": 11065,
    "customerID": "LILAS",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 1,
    "freight": 12.91,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 2027.08,
    "orderDate": 1588539600000,
    "requiredDate": 1590958800000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1591011000000,
    "requiredDateEnd": 1591016400000,
    "orderID": 11068,
    "customerID": "QUEEN",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 81.75,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 498.1,
    "orderDate": 1588712400000,
    "requiredDate": 1591131600000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1591180200000,
    "requiredDateEnd": 1591185600000,
    "orderID": 11075,
    "customerID": "RICSU",
    "employeeID": "ab6704b8-20ed-4881-ab28-96e3d55f7792",
    "teamID": 4,
    "shipVia": 2,
    "freight": 6.19,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 479.4,
    "orderDate": 1535230800000,
    "requiredDate": 1537650000000,
    "shippedDate": 1535403600000,
    "requiredDateStart": 1537698600000,
    "requiredDateEnd": 1537704000000,
    "orderID": 10289,
    "customerID": "BSBEV",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 22.77,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 1117.8,
    "orderDate": 1536613200000,
    "requiredDate": 1539032400000,
    "shippedDate": 1537218000000,
    "requiredDateStart": 1539090000000,
    "requiredDateEnd": 1539093600000,
    "orderID": 10303,
    "customerID": "GODOS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 107.83,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Ana Trujillo Emparedados y helados",
    "customerContactName": "Ana Trujillo",
    "orderTotal": 88.8,
    "orderDate": 1537218000000,
    "requiredDate": 1539637200000,
    "shippedDate": 1537736400000,
    "requiredDateStart": 1539696600000,
    "requiredDateEnd": 1539702000000,
    "orderID": 10308,
    "customerID": "ANATR",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 1.61,
    "shipName": "Ana Trujillo Emparedados y helados",
    "shipAddress": "Avda. de la Constitución 2222",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5021",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 1191.2,
    "orderDate": 1538427600000,
    "requiredDate": 1540850400000,
    "shippedDate": 1539205200000,
    "requiredDateStart": 1540909800000,
    "requiredDateEnd": 1540915200000,
    "orderID": 10319,
    "customerID": "TORTU",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 64.5,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 112,
    "orderDate": 1538600400000,
    "requiredDate": 1541023200000,
    "shippedDate": 1540242000000,
    "requiredDateStart": 1541080800000,
    "requiredDateEnd": 1541086200000,
    "orderID": 10322,
    "customerID": "PERIC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 0.4,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2036.16,
    "orderDate": 1540155600000,
    "requiredDate": 1542578400000,
    "shippedDate": 1540328400000,
    "requiredDateStart": 1542619800000,
    "requiredDateEnd": 1542623400000,
    "orderID": 10335,
    "customerID": "HUNGO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 42.11,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Princesa Isabel Vinhos",
    "customerContactName": "Isabel de Castro",
    "orderTotal": 285.12,
    "orderDate": 1540242000000,
    "requiredDate": 1542664800000,
    "shippedDate": 1540414800000,
    "requiredDateStart": 1542720600000,
    "requiredDateEnd": 1542722400000,
    "orderID": 10336,
    "customerID": "PRINI",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 15.51,
    "shipName": "Princesa Isabel Vinhos",
    "shipAddress": "Estrada da saúde n. 58",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1756",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 352.6,
    "orderDate": 1540764000000,
    "requiredDate": 1543183200000,
    "shippedDate": 1541368800000,
    "requiredDateStart": 1543222800000,
    "requiredDateEnd": 1543224600000,
    "orderID": 10341,
    "customerID": "SIMOB",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 26.78,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 141.6,
    "orderDate": 1541628000000,
    "requiredDate": 1544047200000,
    "shippedDate": 1542232800000,
    "requiredDateStart": 1544104800000,
    "requiredDateEnd": 1544106600000,
    "orderID": 10349,
    "customerID": "SPLIR",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 8.63,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 8593.28,
    "orderDate": 1542060000000,
    "requiredDate": 1544479200000,
    "shippedDate": 1543096800000,
    "requiredDateStart": 1544520600000,
    "requiredDateEnd": 1544524200000,
    "orderID": 10353,
    "customerID": "PICCO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 360.63,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 834.2,
    "orderDate": 1543356000000,
    "requiredDate": 1545775200000,
    "shippedDate": 1543701600000,
    "requiredDateStart": 1545834600000,
    "requiredDateEnd": 1545838200000,
    "orderID": 10367,
    "customerID": "VAFFE",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 13.55,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1830.78,
    "orderDate": 1546812000000,
    "requiredDate": 1550440800000,
    "shippedDate": 1547330400000,
    "requiredDateStart": 1550498400000,
    "requiredDateEnd": 1550500200000,
    "orderID": 10406,
    "customerID": "QUEEN",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 108.04,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 9194.56,
    "orderDate": 1548194400000,
    "requiredDate": 1550613600000,
    "shippedDate": 1548540000000,
    "requiredDateStart": 1550665800000,
    "requiredDateEnd": 1550667600000,
    "orderID": 10424,
    "customerID": "MEREP",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 370.61,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 192,
    "orderDate": 1548626400000,
    "requiredDate": 1551045600000,
    "shippedDate": 1549231200000,
    "requiredDateStart": 1551097800000,
    "requiredDateEnd": 1551103200000,
    "orderID": 10428,
    "customerID": "REGGC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 11.09,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 3891,
    "orderDate": 1551132000000,
    "requiredDate": 1553551200000,
    "shippedDate": 1551650400000,
    "requiredDateStart": 1553607000000,
    "requiredDateEnd": 1553612400000,
    "orderID": 10458,
    "customerID": "SUPRD",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 147.06,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 668.8,
    "orderDate": 1553378400000,
    "requiredDate": 1555794000000,
    "shippedDate": 1556139600000,
    "requiredDateStart": 1555839000000,
    "requiredDateEnd": 1555840800000,
    "orderID": 10483,
    "customerID": "WHITC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 15.28,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 3163.2,
    "orderDate": 1553983200000,
    "requiredDate": 1556398800000,
    "shippedDate": 1554238800000,
    "requiredDateStart": 1556445600000,
    "requiredDateEnd": 1556451000000,
    "orderID": 10490,
    "customerID": "HILAA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 210.19,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 190,
    "orderDate": 1554325200000,
    "requiredDate": 1556744400000,
    "shippedDate": 1554584400000,
    "requiredDateStart": 1556796600000,
    "requiredDateEnd": 1556800200000,
    "orderID": 10496,
    "customerID": "TRADH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 46.77,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1380.6,
    "orderDate": 1554325200000,
    "requiredDate": 1556744400000,
    "shippedDate": 1554584400000,
    "requiredDateStart": 1556802000000,
    "requiredDateEnd": 1556803800000,
    "orderID": 10497,
    "customerID": "LEHMS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 36.21,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 749.0625,
    "orderDate": 1555275600000,
    "requiredDate": 1557694800000,
    "shippedDate": 1555880400000,
    "requiredDateStart": 1557736200000,
    "requiredDateEnd": 1557738000000,
    "orderID": 10507,
    "customerID": "ANTON",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 47.45,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 525.3,
    "orderDate": 1555794000000,
    "requiredDate": 1558213200000,
    "shippedDate": 1556053200000,
    "requiredDateStart": 1558265400000,
    "requiredDateEnd": 1558267200000,
    "orderID": 10512,
    "customerID": "FAMIA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 3.53,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 1942,
    "orderDate": 1555880400000,
    "requiredDate": 1559509200000,
    "shippedDate": 1556398800000,
    "requiredDateStart": 1559563200000,
    "requiredDateEnd": 1559566800000,
    "orderID": 10513,
    "customerID": "WANDK",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 105.65,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 200,
    "orderDate": 1556485200000,
    "requiredDate": 1558904400000,
    "shippedDate": 1556658000000,
    "requiredDateStart": 1558958400000,
    "requiredDateEnd": 1558962000000,
    "orderID": 10520,
    "customerID": "SANTG",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 13.37,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 2444.31,
    "orderDate": 1556658000000,
    "requiredDate": 1559077200000,
    "shippedDate": 1559163600000,
    "requiredDateStart": 1559120400000,
    "requiredDateEnd": 1559124000000,
    "orderID": 10523,
    "customerID": "SEVES",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 77.63,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 1503,
    "orderDate": 1557003600000,
    "requiredDate": 1559422800000,
    "shippedDate": 1557176400000,
    "requiredDateStart": 1559462400000,
    "requiredDateEnd": 1559466000000,
    "orderID": 10527,
    "customerID": "QUICK",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 41.9,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Océano Atlántico Ltda.",
    "customerContactName": "Yvonne Moncada",
    "orderTotal": 110,
    "orderDate": 1557262800000,
    "requiredDate": 1559682000000,
    "shippedDate": 1558213200000,
    "requiredDateStart": 1559725200000,
    "requiredDateEnd": 1559730600000,
    "orderID": 10531,
    "customerID": "OCEAN",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 8.12,
    "shipName": "Océano Atlántico Ltda.",
    "shipAddress": "Ing. Gustavo Moncada 8585 Piso 20-A",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 796.35,
    "orderDate": 1557349200000,
    "requiredDate": 1559768400000,
    "shippedDate": 1557608400000,
    "requiredDateStart": 1559808000000,
    "requiredDateEnd": 1559813400000,
    "orderID": 10532,
    "customerID": "EASTC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 74.46,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 683.3,
    "orderDate": 1558990800000,
    "requiredDate": 1561410000000,
    "shippedDate": 1559768400000,
    "requiredDateStart": 1561469400000,
    "requiredDateEnd": 1561471200000,
    "orderID": 10550,
    "customerID": "GODOS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 4.32,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 2082,
    "orderDate": 1560891600000,
    "requiredDate": 1563310800000,
    "shippedDate": 1560978000000,
    "requiredDateStart": 1563354000000,
    "requiredDateEnd": 1563359400000,
    "orderID": 10573,
    "customerID": "ANTON",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 84.84,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 142.5,
    "orderDate": 1561928400000,
    "requiredDate": 1564347600000,
    "shippedDate": 1562706000000,
    "requiredDateStart": 1564398000000,
    "requiredDateEnd": 1564399800000,
    "orderID": 10585,
    "customerID": "WELLI",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 13.41,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1994.4,
    "orderDate": 1562619600000,
    "requiredDate": 1565038800000,
    "shippedDate": 1565643600000,
    "requiredDateStart": 1565091000000,
    "requiredDateEnd": 1565092800000,
    "orderID": 10593,
    "customerID": "LEHMS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 174.2,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 718.08,
    "orderDate": 1562792400000,
    "requiredDate": 1565211600000,
    "shippedDate": 1563397200000,
    "requiredDateStart": 1565258400000,
    "requiredDateEnd": 1565263800000,
    "orderID": 10597,
    "customerID": "PICCO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 35.12,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 2285,
    "orderDate": 1563224400000,
    "requiredDate": 1566853200000,
    "shippedDate": 1563742800000,
    "requiredDateStart": 1566912600000,
    "requiredDateEnd": 1566914400000,
    "orderID": 10601,
    "customerID": "HILAA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 58.3,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Du monde entier",
    "customerContactName": "Janine Labrune",
    "orderTotal": 424,
    "orderDate": 1563915600000,
    "requiredDate": 1566334800000,
    "shippedDate": 1564434000000,
    "requiredDateStart": 1566392400000,
    "requiredDateEnd": 1566396000000,
    "orderID": 10609,
    "customerID": "DUMON",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 1.85,
    "shipName": "Du monde entier",
    "shipAddress": "67, rue des Cinquante Otages",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 5510.5925,
    "orderDate": 1565816400000,
    "requiredDate": 1568235600000,
    "shippedDate": 1566075600000,
    "requiredDateStart": 1568286000000,
    "requiredDateEnd": 1568287800000,
    "orderID": 10633,
    "customerID": "ERNSH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 477.9,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 500,
    "orderDate": 1566248400000,
    "requiredDate": 1568667600000,
    "shippedDate": 1566853200000,
    "requiredDateStart": 1568727000000,
    "requiredDateEnd": 1568732400000,
    "orderID": 10639,
    "customerID": "SANTG",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 38.64,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 696,
    "orderDate": 1566421200000,
    "requiredDate": 1568840400000,
    "shippedDate": 1567630800000,
    "requiredDateStart": 1568896200000,
    "requiredDateEnd": 1568898000000,
    "orderID": 10642,
    "customerID": "SIMOB",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 41.89,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1227.02,
    "orderDate": 1567630800000,
    "requiredDate": 1570050000000,
    "shippedDate": 1568062800000,
    "requiredDateStart": 1570098600000,
    "requiredDateEnd": 1570100400000,
    "orderID": 10659,
    "customerID": "QUEEN",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 105.81,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 562.6,
    "orderDate": 1567976400000,
    "requiredDate": 1570395600000,
    "shippedDate": 1568494800000,
    "requiredDateStart": 1570437000000,
    "requiredDateEnd": 1570442400000,
    "orderID": 10661,
    "customerID": "HUNGO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 17.55,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 4666.94,
    "orderDate": 1568235600000,
    "requiredDate": 1570654800000,
    "shippedDate": 1569099600000,
    "requiredDateStart": 1570696200000,
    "requiredDateEnd": 1570701600000,
    "orderID": 10666,
    "customerID": "RICSU",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 232.42,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1536.8,
    "orderDate": 1568235600000,
    "requiredDate": 1570654800000,
    "shippedDate": 1568840400000,
    "requiredDateStart": 1570707000000,
    "requiredDateEnd": 1570710600000,
    "orderID": 10667,
    "customerID": "ERNSH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 78.09,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 5256.5,
    "orderDate": 1569186000000,
    "requiredDate": 1571605200000,
    "shippedDate": 1571173200000,
    "requiredDateStart": 1571657400000,
    "requiredDateEnd": 1571659200000,
    "orderID": 10678,
    "customerID": "SAVEA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 388.98,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 642,
    "orderDate": 1570395600000,
    "requiredDate": 1574028000000,
    "shippedDate": 1571000400000,
    "requiredDateStart": 1574067600000,
    "requiredDateEnd": 1574069400000,
    "orderID": 10695,
    "customerID": "WILMK",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 16.72,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 1890.5,
    "orderDate": 1572991200000,
    "requiredDate": 1575410400000,
    "shippedDate": 1573682400000,
    "requiredDateStart": 1575469800000,
    "requiredDateEnd": 1575473400000,
    "orderID": 10731,
    "customerID": "CHOPS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 96.65,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "The Cracker Box",
    "customerContactName": "Liu Wong",
    "orderTotal": 228,
    "orderDate": 1576101600000,
    "requiredDate": 1578520800000,
    "shippedDate": 1577311200000,
    "requiredDateStart": 1578580200000,
    "requiredDateEnd": 1578585600000,
    "orderID": 10775,
    "customerID": "THECR",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 20.25,
    "shipName": "The Cracker Box",
    "shipAddress": "55 Grizzly Peak Rd.",
    "shipCity": "Butte",
    "shipRegion": "MT",
    "shipPostalCode": "59801",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 224,
    "orderDate": 1576360800000,
    "requiredDate": 1577570400000,
    "shippedDate": 1579557600000,
    "requiredDateStart": 1577611800000,
    "requiredDateEnd": 1577617200000,
    "orderID": 10777,
    "customerID": "GOURL",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 3.01,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 420,
    "orderDate": 1577224800000,
    "requiredDate": 1579644000000,
    "shippedDate": 1578175200000,
    "requiredDateStart": 1579690800000,
    "requiredDateEnd": 1579692600000,
    "orderID": 10797,
    "customerID": "DRACD",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 33.35,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 140,
    "orderDate": 1577829600000,
    "requiredDate": 1580248800000,
    "shippedDate": 1578348000000,
    "requiredDateStart": 1580292000000,
    "requiredDateEnd": 1580293800000,
    "orderID": 10809,
    "customerID": "WELLI",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 4.87,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 833,
    "orderDate": 1578348000000,
    "requiredDate": 1580767200000,
    "shippedDate": 1578780000000,
    "requiredDateStart": 1580823000000,
    "requiredDateEnd": 1580828400000,
    "orderID": 10818,
    "customerID": "MAGAA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 65.48,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4705.5,
    "orderDate": 1579125600000,
    "requiredDate": 1581544800000,
    "shippedDate": 1579557600000,
    "requiredDateStart": 1581600600000,
    "requiredDateEnd": 1581602400000,
    "orderID": 10836,
    "customerID": "ERNSH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 411.88,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Consolidated Holdings",
    "customerContactName": "Elizabeth Brown",
    "orderTotal": 931.5,
    "orderDate": 1579730400000,
    "requiredDate": 1582149600000,
    "shippedDate": 1580248800000,
    "requiredDateStart": 1582209000000,
    "requiredDateEnd": 1582210800000,
    "orderID": 10848,
    "customerID": "CONSH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 38.24,
    "shipName": "Consolidated Holdings",
    "shipAddress": "Berkeley Gardens 12  Brewery",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX1 6LT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1920.6,
    "orderDate": 1580767200000,
    "requiredDate": 1583272800000,
    "shippedDate": 1582408800000,
    "requiredDateStart": 1583319600000,
    "requiredDateEnd": 1583321400000,
    "orderID": 10868,
    "customerID": "QUEEN",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 191.27,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 917,
    "orderDate": 1581199200000,
    "requiredDate": 1583704800000,
    "shippedDate": 1581458400000,
    "requiredDateStart": 1583749800000,
    "requiredDateEnd": 1583755200000,
    "orderID": 10876,
    "customerID": "BONAP",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 60.42,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 1500,
    "orderDate": 1581285600000,
    "requiredDate": 1585000800000,
    "shippedDate": 1581976800000,
    "requiredDateStart": 1585058400000,
    "requiredDateEnd": 1585063800000,
    "orderID": 10880,
    "customerID": "FOLKO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 88.01,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Du monde entier",
    "customerContactName": "Janine Labrune",
    "orderTotal": 860.1,
    "orderDate": 1581804000000,
    "requiredDate": 1584309600000,
    "shippedDate": 1581976800000,
    "requiredDateStart": 1584354600000,
    "requiredDateEnd": 1584360000000,
    "orderID": 10890,
    "customerID": "DUMON",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 32.76,
    "shipName": "Du monde entier",
    "shipAddress": "67, rue des Cinquante Otages",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 368.9325,
    "orderDate": 1581890400000,
    "requiredDate": 1584396000000,
    "shippedDate": 1582063200000,
    "requiredDateStart": 1584441000000,
    "requiredDateEnd": 1584444600000,
    "orderID": 10891,
    "customerID": "LEHMS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 20.37,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 750.5,
    "orderDate": 1582063200000,
    "requiredDate": 1584568800000,
    "shippedDate": 1582754400000,
    "requiredDateStart": 1584610200000,
    "requiredDateEnd": 1584613800000,
    "orderID": 10896,
    "customerID": "MAISD",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 32.45,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 748.8,
    "orderDate": 1583186400000,
    "requiredDate": 1586811600000,
    "shippedDate": 1584050400000,
    "requiredDateStart": 1586865600000,
    "requiredDateEnd": 1586869200000,
    "orderID": 10923,
    "customerID": "LAMAI",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 68.26,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 644.8,
    "orderDate": 1583791200000,
    "requiredDate": 1585000800000,
    "shippedDate": 1584050400000,
    "requiredDateStart": 1585053000000,
    "requiredDateEnd": 1585054800000,
    "orderID": 10937,
    "customerID": "CACTU",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 31.51,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4011.75,
    "orderDate": 1583877600000,
    "requiredDate": 1586293200000,
    "shippedDate": 1584655200000,
    "requiredDateStart": 1586347200000,
    "requiredDateEnd": 1586352600000,
    "orderID": 10941,
    "customerID": "SAVEA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 400.81,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Océano Atlántico Ltda.",
    "customerContactName": "Yvonne Moncada",
    "orderTotal": 781,
    "orderDate": 1584482400000,
    "requiredDate": 1586898000000,
    "shippedDate": 1585260000000,
    "requiredDateStart": 1586948400000,
    "requiredDateEnd": 1586950200000,
    "orderID": 10958,
    "customerID": "OCEAN",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 49.56,
    "shipName": "Océano Atlántico Ltda.",
    "shipAddress": "Ing. Gustavo Moncada 8585 Piso 20-A",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 4895.4375,
    "orderDate": 1585688400000,
    "requiredDate": 1588107600000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588152600000,
    "requiredDateEnd": 1588154400000,
    "orderID": 10993,
    "customerID": "FOLKO",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 8.81,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4680.9,
    "orderDate": 1586293200000,
    "requiredDate": 1588712400000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588759200000,
    "requiredDateEnd": 1588762800000,
    "orderID": 11008,
    "customerID": "ERNSH",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 79.46,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 12615.05,
    "orderDate": 1587070800000,
    "requiredDate": 1589490000000,
    "shippedDate": 1587934800000,
    "requiredDateStart": 1589544000000,
    "requiredDateEnd": 1589545800000,
    "orderID": 11030,
    "customerID": "SAVEA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 830.75,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 3232.8,
    "orderDate": 1587070800000,
    "requiredDate": 1589490000000,
    "shippedDate": 1587589200000,
    "requiredDateStart": 1589549400000,
    "requiredDateEnd": 1589553000000,
    "orderID": 11033,
    "customerID": "RICSU",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 84.74,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 60,
    "orderDate": 1587416400000,
    "requiredDate": 1589835600000,
    "shippedDate": 1587934800000,
    "requiredDateStart": 1589882400000,
    "requiredDateEnd": 1589886000000,
    "orderID": 11037,
    "customerID": "GODOS",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 1,
    "freight": 3.2,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 817.875,
    "orderDate": 1587675600000,
    "requiredDate": 1590094800000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1590141600000,
    "requiredDateEnd": 1590145200000,
    "orderID": 11047,
    "customerID": "EASTC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 46.62,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 525,
    "orderDate": 1587675600000,
    "requiredDate": 1590094800000,
    "shippedDate": 1588194000000,
    "requiredDateStart": 1590143400000,
    "requiredDateEnd": 1590145200000,
    "orderID": 11048,
    "customerID": "BOTTM",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 24.12,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 36,
    "orderDate": 1587934800000,
    "requiredDate": 1590354000000,
    "shippedDate": 1588194000000,
    "requiredDateStart": 1590413400000,
    "requiredDateEnd": 1590418800000,
    "orderID": 11051,
    "customerID": "LAMAI",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 3,
    "freight": 2.79,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 1727.5,
    "orderDate": 1588021200000,
    "requiredDate": 1590440400000,
    "shippedDate": 1588626000000,
    "requiredDateStart": 1590492600000,
    "requiredDateEnd": 1590494400000,
    "orderID": 11055,
    "customerID": "HILAA",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 120.92,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 928.75,
    "orderDate": 1588280400000,
    "requiredDate": 1590699600000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1590751800000,
    "requiredDateEnd": 1590755400000,
    "orderID": 11066,
    "customerID": "WHITC",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 44.72,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 232.085,
    "orderDate": 1588712400000,
    "requiredDate": 1591131600000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1591189200000,
    "requiredDateEnd": 1591192800000,
    "orderID": 11074,
    "customerID": "SIMOB",
    "employeeID": "7b991e13-bef7-4f19-bd18-d5aa9d263cba",
    "teamID": 3,
    "shipVia": 2,
    "freight": 18.44,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 1863.4,
    "orderDate": 1530738000000,
    "requiredDate": 1534366800000,
    "shippedDate": 1531170000000,
    "requiredDateStart": 1534426200000,
    "requiredDateEnd": 1534429800000,
    "orderID": 10249,
    "customerID": "TOMSP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 11.61,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 695.625,
    "orderDate": 1532379600000,
    "requiredDate": 1534798800000,
    "shippedDate": 1534971600000,
    "requiredDateStart": 1534851000000,
    "requiredDateEnd": 1534852800000,
    "orderID": 10264,
    "customerID": "FOLKO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 3.67,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 48,
    "orderDate": 1533070800000,
    "requiredDate": 1535490000000,
    "shippedDate": 1535576400000,
    "requiredDateStart": 1535533200000,
    "requiredDateEnd": 1535538600000,
    "orderID": 10271,
    "customerID": "SPLIR",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 4.54,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1456,
    "orderDate": 1533157200000,
    "requiredDate": 1535576400000,
    "shippedDate": 1533502800000,
    "requiredDateStart": 1535623200000,
    "requiredDateEnd": 1535626800000,
    "orderID": 10272,
    "customerID": "RATTC",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 98.03,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Vins et alcools Chevalier",
    "customerContactName": "Paul Henriot",
    "orderTotal": 538.6,
    "orderDate": 1533502800000,
    "requiredDate": 1535922000000,
    "shippedDate": 1534366800000,
    "requiredDateStart": 1535977800000,
    "requiredDateEnd": 1535981400000,
    "orderID": 10274,
    "customerID": "VINET",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 6.01,
    "shipName": "Vins et alcools Chevalier",
    "shipAddress": "59 rue de l-Abbaye",
    "shipCity": "Reims",
    "shipRegion": "",
    "shipPostalCode": "51100",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 497.52,
    "orderDate": 1535317200000,
    "requiredDate": 1537736400000,
    "shippedDate": 1536008400000,
    "requiredDateStart": 1537779600000,
    "requiredDateEnd": 1537785000000,
    "orderID": 10291,
    "customerID": "QUEDE",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 6.4,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1050.6,
    "orderDate": 1535922000000,
    "requiredDate": 1538341200000,
    "shippedDate": 1536613200000,
    "requiredDateStart": 1538382600000,
    "requiredDateEnd": 1538386200000,
    "orderID": 10296,
    "customerID": "LILAS",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 0.12,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2645,
    "orderDate": 1536094800000,
    "requiredDate": 1538514000000,
    "shippedDate": 1536613200000,
    "requiredDateStart": 1538553600000,
    "requiredDateEnd": 1538555400000,
    "orderID": 10298,
    "customerID": "HUNGO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 168.22,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 288,
    "orderDate": 1538254800000,
    "requiredDate": 1540674000000,
    "shippedDate": 1539118800000,
    "requiredDateStart": 1540726200000,
    "requiredDateEnd": 1540729800000,
    "orderID": 10317,
    "customerID": "LONEP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 12.69,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 642.06,
    "orderDate": 1541887200000,
    "requiredDate": 1544306400000,
    "shippedDate": 1543788000000,
    "requiredDateStart": 1544349600000,
    "requiredDateEnd": 1544353200000,
    "orderID": 10350,
    "customerID": "LAMAI",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 64.19,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 480,
    "orderDate": 1542232800000,
    "requiredDate": 1544652000000,
    "shippedDate": 1542664800000,
    "requiredDateStart": 1544711400000,
    "requiredDateEnd": 1544716800000,
    "orderID": 10355,
    "customerID": "AROUT",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 41.95,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 1106.4,
    "orderDate": 1542492000000,
    "requiredDate": 1544911200000,
    "shippedDate": 1543269600000,
    "requiredDateStart": 1544970600000,
    "requiredDateEnd": 1544972400000,
    "orderID": 10356,
    "customerID": "WANDK",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 36.71,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 1117.6,
    "orderDate": 1543788000000,
    "requiredDate": 1546207200000,
    "shippedDate": 1545861600000,
    "requiredDateStart": 1546263000000,
    "requiredDateEnd": 1546268400000,
    "orderID": 10370,
    "customerID": "CHOPS",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 1.17,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2090.88,
    "orderDate": 1545516000000,
    "requiredDate": 1547935200000,
    "shippedDate": 1545775200000,
    "requiredDateStart": 1547992800000,
    "requiredDateEnd": 1547998200000,
    "orderID": 10390,
    "customerID": "ERNSH",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 126.38,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 2122.92,
    "orderDate": 1545775200000,
    "requiredDate": 1548194400000,
    "shippedDate": 1546466400000,
    "requiredDateStart": 1548235800000,
    "requiredDateEnd": 1548239400000,
    "orderID": 10395,
    "customerID": "HILAA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 184.41,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 1020,
    "orderDate": 1548194400000,
    "requiredDate": 1549404000000,
    "shippedDate": 1550959200000,
    "requiredDateStart": 1549443600000,
    "requiredDateEnd": 1549447200000,
    "orderID": 10423,
    "customerID": "GOURL",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 24.5,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 360,
    "orderDate": 1548280800000,
    "requiredDate": 1550700000000,
    "shippedDate": 1550095200000,
    "requiredDateStart": 1550745000000,
    "requiredDateEnd": 1550748600000,
    "orderID": 10425,
    "customerID": "LAMAI",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 7.93,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 1078,
    "orderDate": 1549490400000,
    "requiredDate": 1551909600000,
    "shippedDate": 1549749600000,
    "requiredDateStart": 1551952800000,
    "requiredDateEnd": 1551954600000,
    "orderID": 10439,
    "customerID": "MEREP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 4.07,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 246.24,
    "orderDate": 1550095200000,
    "requiredDate": 1552514400000,
    "shippedDate": 1550527200000,
    "requiredDateStart": 1552564800000,
    "requiredDateEnd": 1552566600000,
    "orderID": 10446,
    "customerID": "TOMSP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 14.68,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folies gourmandes",
    "customerContactName": "Martine Rancé",
    "orderTotal": 756,
    "orderDate": 1553032800000,
    "requiredDate": 1555448400000,
    "shippedDate": 1553378400000,
    "requiredDateStart": 1555498800000,
    "requiredDateEnd": 1555504200000,
    "orderID": 10480,
    "customerID": "FOLIG",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 1.35,
    "shipName": "Folies gourmandes",
    "shipAddress": "184, chaussée de Tournai",
    "shipCity": "Lille",
    "shipRegion": "",
    "shipPostalCode": "59000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 439.2,
    "orderDate": 1553724000000,
    "requiredDate": 1556139600000,
    "shippedDate": 1554757200000,
    "requiredDateStart": 1556188200000,
    "requiredDateEnd": 1556190000000,
    "orderID": 10489,
    "customerID": "PICCO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 5.29,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 523.26,
    "orderDate": 1554757200000,
    "requiredDate": 1557176400000,
    "shippedDate": 1555448400000,
    "requiredDateStart": 1557235800000,
    "requiredDateEnd": 1557241200000,
    "orderID": 10500,
    "customerID": "LAMAI",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 42.68,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2048.5,
    "orderDate": 1554930000000,
    "requiredDate": 1557349200000,
    "shippedDate": 1555362000000,
    "requiredDateStart": 1557397800000,
    "requiredDateEnd": 1557399600000,
    "orderID": 10503,
    "customerID": "HUNGO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 16.74,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4707.54,
    "orderDate": 1555534800000,
    "requiredDate": 1557954000000,
    "shippedDate": 1556398800000,
    "requiredDateStart": 1558006200000,
    "requiredDateEnd": 1558009800000,
    "orderID": 10510,
    "customerID": "SAVEA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 367.63,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 2314.2,
    "orderDate": 1556398800000,
    "requiredDate": 1558818000000,
    "shippedDate": 1556658000000,
    "requiredDateStart": 1558868400000,
    "requiredDateEnd": 1558873800000,
    "orderID": 10519,
    "customerID": "CHOPS",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 91.76,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 392.2,
    "orderDate": 1557090000000,
    "requiredDate": 1558299600000,
    "shippedDate": 1557349200000,
    "requiredDateStart": 1558350000000,
    "requiredDateEnd": 1558355400000,
    "orderID": 10528,
    "customerID": "GREAL",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 3.35,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 355.5,
    "orderDate": 1557954000000,
    "requiredDate": 1560373200000,
    "shippedDate": 1558558800000,
    "requiredDateStart": 1560421800000,
    "requiredDateEnd": 1560425400000,
    "orderID": 10539,
    "customerID": "BSBEV",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 12.36,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2944.4,
    "orderDate": 1559422800000,
    "requiredDate": 1561842000000,
    "shippedDate": 1559595600000,
    "requiredDateStart": 1561890600000,
    "requiredDateEnd": 1561892400000,
    "orderID": 10555,
    "customerID": "SAVEA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 252.49,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 520.41,
    "orderDate": 1559682000000,
    "requiredDate": 1562101200000,
    "shippedDate": 1560373200000,
    "requiredDateStart": 1562144400000,
    "requiredDateEnd": 1562149800000,
    "orderID": 10559,
    "customerID": "BLONP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 8.05,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 493,
    "orderDate": 1563138000000,
    "requiredDate": 1566766800000,
    "shippedDate": 1563656400000,
    "requiredDateStart": 1566811800000,
    "requiredDateEnd": 1566815400000,
    "orderID": 10599,
    "customerID": "BSBEV",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 29.98,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 808,
    "orderDate": 1564002000000,
    "requiredDate": 1566421200000,
    "shippedDate": 1564606800000,
    "requiredDateStart": 1566477000000,
    "requiredDateEnd": 1566478800000,
    "orderID": 10611,
    "customerID": "WOLZA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 80.65,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 2761.9375,
    "orderDate": 1566162000000,
    "requiredDate": 1568581200000,
    "shippedDate": 1566766800000,
    "requiredDateStart": 1568629800000,
    "requiredDateEnd": 1568633400000,
    "orderID": 10637,
    "customerID": "QUEEN",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 201.29,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 814.5,
    "orderDate": 1566680400000,
    "requiredDate": 1569099600000,
    "shippedDate": 1567371600000,
    "requiredDateStart": 1569150000000,
    "requiredDateEnd": 1569153600000,
    "orderID": 10643,
    "customerID": "ALFKI",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 29.46,
    "shipName": "Alfreds Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 604.215,
    "orderDate": 1567544400000,
    "requiredDate": 1569963600000,
    "shippedDate": 1568062800000,
    "requiredDateStart": 1570021200000,
    "requiredDateEnd": 1570023000000,
    "orderID": 10656,
    "customerID": "GREAL",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 57.15,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2864.5,
    "orderDate": 1570914000000,
    "requiredDate": 1572123600000,
    "shippedDate": 1571086800000,
    "requiredDateStart": 1572183000000,
    "requiredDateEnd": 1572184800000,
    "orderID": 10701,
    "customerID": "HUNGO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 220.31,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 2545,
    "orderDate": 1571000400000,
    "requiredDate": 1573423200000,
    "shippedDate": 1571518800000,
    "requiredDateStart": 1573466400000,
    "requiredDateEnd": 1573470000000,
    "orderID": 10703,
    "customerID": "FOLKO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 152.3,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 595.5,
    "orderDate": 1571000400000,
    "requiredDate": 1573423200000,
    "shippedDate": 1573077600000,
    "requiredDateStart": 1573471800000,
    "requiredDateEnd": 1573477200000,
    "orderID": 10704,
    "customerID": "QUEEN",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 4.78,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "The Big Cheese",
    "customerContactName": "Liz Nixon",
    "orderTotal": 180.4,
    "orderDate": 1571259600000,
    "requiredDate": 1574892000000,
    "shippedDate": 1572904800000,
    "requiredDateStart": 1574944200000,
    "requiredDateEnd": 1574946000000,
    "orderID": 10708,
    "customerID": "THEBI",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 2.96,
    "shipName": "The Big Cheese",
    "shipAddress": "89 Jefferson Way Suite 2",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97201",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Let's Stop N Shop",
    "customerContactName": "Jaime Yorres",
    "orderTotal": 536.4,
    "orderDate": 1573336800000,
    "requiredDate": 1575756000000,
    "shippedDate": 1574287200000,
    "requiredDateStart": 1575813600000,
    "requiredDateEnd": 1575819000000,
    "orderID": 10735,
    "customerID": "LETSS",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 45.97,
    "shipName": "Let-s Stop N Shop",
    "shipAddress": "87 Polk St. Suite 5",
    "shipCity": "San Francisco",
    "shipRegion": "CA",
    "shipPostalCode": "94117",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 736,
    "orderDate": 1573941600000,
    "requiredDate": 1576360800000,
    "shippedDate": 1574546400000,
    "requiredDateStart": 1576413000000,
    "requiredDateEnd": 1576418400000,
    "orderID": 10744,
    "customerID": "VAFFE",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 69.19,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 1912.85,
    "orderDate": 1574114400000,
    "requiredDate": 1576533600000,
    "shippedDate": 1574719200000,
    "requiredDateStart": 1576576800000,
    "requiredDateEnd": 1576580400000,
    "orderID": 10747,
    "customerID": "PICCO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 117.33,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 55.2,
    "orderDate": 1574632800000,
    "requiredDate": 1577052000000,
    "shippedDate": 1574805600000,
    "requiredDateStart": 1577095200000,
    "requiredDateEnd": 1577097000000,
    "orderID": 10754,
    "customerID": "MAGAA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 2.38,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 3082,
    "orderDate": 1574805600000,
    "requiredDate": 1577224800000,
    "shippedDate": 1576360800000,
    "requiredDateStart": 1577266200000,
    "requiredDateEnd": 1577269800000,
    "orderID": 10757,
    "customerID": "SAVEA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 8.19,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2286,
    "orderDate": 1575324000000,
    "requiredDate": 1577743200000,
    "shippedDate": 1575756000000,
    "requiredDateStart": 1577797200000,
    "requiredDateEnd": 1577799000000,
    "orderID": 10764,
    "customerID": "ERNSH",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 145.45,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 722.5,
    "orderDate": 1576965600000,
    "requiredDate": 1579384800000,
    "shippedDate": 1577311200000,
    "requiredDateStart": 1579433400000,
    "requiredDateEnd": 1579438800000,
    "orderID": 10790,
    "customerID": "GOURL",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 28.23,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1829.757,
    "orderDate": 1577052000000,
    "requiredDate": 1579471200000,
    "shippedDate": 1577829600000,
    "requiredDateStart": 1579527000000,
    "requiredDateEnd": 1579530600000,
    "orderID": 10791,
    "customerID": "FRANK",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 16.85,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 314.76,
    "orderDate": 1577138400000,
    "requiredDate": 1579557600000,
    "shippedDate": 1577916000000,
    "requiredDateStart": 1579613400000,
    "requiredDateEnd": 1579618800000,
    "orderID": 10794,
    "customerID": "QUEDE",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 21.49,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 2278.4,
    "orderDate": 1577656800000,
    "requiredDate": 1580076000000,
    "shippedDate": 1578348000000,
    "requiredDateStart": 1580124600000,
    "requiredDateEnd": 1580126400000,
    "orderID": 10804,
    "customerID": "SEVES",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 27.33,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Trail's Head Gourmet Provisioners",
    "customerContactName": "Helvetius Nagy",
    "orderTotal": 237.9,
    "orderDate": 1578434400000,
    "requiredDate": 1580853600000,
    "shippedDate": 1579125600000,
    "requiredDateStart": 1580898600000,
    "requiredDateEnd": 1580900400000,
    "orderID": 10822,
    "customerID": "TRAIH",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 7,
    "shipName": "Trail-s Head Gourmet Provisioners",
    "shipAddress": "722 DaVinci Blvd.",
    "shipCity": "Kirkland",
    "shipRegion": "WA",
    "shipPostalCode": "98034",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 730,
    "orderDate": 1578780000000,
    "requiredDate": 1581199200000,
    "shippedDate": 1580940000000,
    "requiredDateStart": 1581242400000,
    "requiredDateEnd": 1581247800000,
    "orderID": 10826,
    "customerID": "BLONP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 7.09,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 906.93,
    "orderDate": 1579039200000,
    "requiredDate": 1581458400000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1581514200000,
    "requiredDateEnd": 1581516000000,
    "orderID": 10833,
    "customerID": "OTTIK",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 71.49,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 98.4,
    "orderDate": 1580680800000,
    "requiredDate": 1584396000000,
    "shippedDate": 1581372000000,
    "requiredDateStart": 1584451800000,
    "requiredDateEnd": 1584453600000,
    "orderID": 10867,
    "customerID": "LONEP",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 1.93,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 1209,
    "orderDate": 1581458400000,
    "requiredDate": 1583964000000,
    "shippedDate": 1581976800000,
    "requiredDateStart": 1584016200000,
    "requiredDateEnd": 1584018000000,
    "orderID": 10885,
    "customerID": "SUPRD",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 5.64,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Spécialités du monde",
    "customerContactName": "Dominique Perrier",
    "orderTotal": 108.5,
    "orderDate": 1582581600000,
    "requiredDate": 1585087200000,
    "shippedDate": 1582754400000,
    "requiredDateStart": 1585130400000,
    "requiredDateEnd": 1585134000000,
    "orderID": 10907,
    "customerID": "SPECD",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 9.19,
    "shipName": "Spécialités du monde",
    "shipAddress": "25, rue Lauriston",
    "shipCity": "Paris",
    "shipRegion": "",
    "shipPostalCode": "75016",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 537.5,
    "orderDate": 1582754400000,
    "requiredDate": 1585260000000,
    "shippedDate": 1583100000000,
    "requiredDateStart": 1585306800000,
    "requiredDateEnd": 1585312200000,
    "orderID": 10914,
    "customerID": "QUEEN",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 21.19,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1174.75,
    "orderDate": 1583359200000,
    "requiredDate": 1585774800000,
    "shippedDate": 1583964000000,
    "requiredDateStart": 1585823400000,
    "requiredDateEnd": 1585828800000,
    "orderID": 10929,
    "customerID": "FRANK",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 1,
    "freight": 33.93,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 920.6,
    "orderDate": 1583445600000,
    "requiredDate": 1585861200000,
    "shippedDate": 1584309600000,
    "requiredDateStart": 1585906200000,
    "requiredDateEnd": 1585908000000,
    "orderID": 10933,
    "customerID": "ISLAT",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 54.15,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1025.325,
    "orderDate": 1583964000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1584050400000,
    "requiredDateStart": 1585231200000,
    "requiredDateEnd": 1585233000000,
    "orderID": 10944,
    "customerID": "BOTTM",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 52.92,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 677,
    "orderDate": 1584396000000,
    "requiredDate": 1588021200000,
    "shippedDate": 1584655200000,
    "requiredDateStart": 1588068000000,
    "requiredDateEnd": 1588073400000,
    "orderID": 10956,
    "customerID": "BLAUS",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 44.65,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 131.75,
    "orderDate": 1584482400000,
    "requiredDate": 1588107600000,
    "shippedDate": 1584914400000,
    "requiredDateStart": 1588161600000,
    "requiredDateEnd": 1588167000000,
    "orderID": 10959,
    "customerID": "GOURL",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 4.98,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 848,
    "orderDate": 1584655200000,
    "requiredDate": 1587070800000,
    "shippedDate": 1585515600000,
    "requiredDateStart": 1587128400000,
    "requiredDateEnd": 1587132000000,
    "orderID": 10965,
    "customerID": "OLDWO",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 144.38,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "La corne d'abondance",
    "customerContactName": "Daniel Tonini",
    "orderTotal": 291.55,
    "orderDate": 1585000800000,
    "requiredDate": 1587416400000,
    "shippedDate": 1585260000000,
    "requiredDateStart": 1587459600000,
    "requiredDateEnd": 1587461400000,
    "orderID": 10973,
    "customerID": "LACOR",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 15.17,
    "shipName": "La corne d-abondance",
    "shipAddress": "67, avenue de l-Europe",
    "shipCity": "Versailles",
    "shipRegion": "",
    "shipPostalCode": "78000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1197.95,
    "orderDate": 1585861200000,
    "requiredDate": 1588280400000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588339800000,
    "requiredDateEnd": 1588345200000,
    "orderID": 10999,
    "customerID": "OTTIK",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 96.35,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rancho grande",
    "customerContactName": "Sergio Gutiérrez",
    "orderTotal": 76,
    "orderDate": 1586725200000,
    "requiredDate": 1589144400000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1589202000000,
    "requiredDateEnd": 1589203800000,
    "orderID": 11019,
    "customerID": "RANCH",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 3.17,
    "shipName": "Rancho grande",
    "shipAddress": "Av. del Libertador 900",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 270,
    "orderDate": 1586898000000,
    "requiredDate": 1589317200000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1589362200000,
    "requiredDateEnd": 1589364000000,
    "orderID": 11025,
    "customerID": "WARTH",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 3,
    "freight": 29.17,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2393.5,
    "orderDate": 1587070800000,
    "requiredDate": 1589490000000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1589549400000,
    "requiredDateEnd": 1589551200000,
    "orderID": 11031,
    "customerID": "SAVEA",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 227.22,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1309.5,
    "orderDate": 1587589200000,
    "requiredDate": 1590008400000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1590057000000,
    "requiredDateEnd": 1590058800000,
    "orderID": 11045,
    "customerID": "BOTTM",
    "employeeID": "b75a42e9-db84-479c-96b0-d88109abfebb",
    "teamID": 3,
    "shipVia": 2,
    "freight": 70.58,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Vins et alcools Chevalier",
    "customerContactName": "Paul Henriot",
    "orderTotal": 440,
    "orderDate": 1530651600000,
    "requiredDate": 1533070800000,
    "shippedDate": 1531688400000,
    "requiredDateStart": 1533123000000,
    "requiredDateEnd": 1533126600000,
    "orderID": 10248,
    "customerID": "VINET",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 32.38,
    "shipName": "Vins et alcools Chevalier",
    "shipAddress": "59 rue de l-Abbaye",
    "shipCity": "Reims",
    "shipRegion": "",
    "shipPostalCode": "51100",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 556.62,
    "orderDate": 1531256400000,
    "requiredDate": 1533675600000,
    "shippedDate": 1532293200000,
    "requiredDateStart": 1533729600000,
    "requiredDateEnd": 1533735000000,
    "orderID": 10254,
    "customerID": "CHOPS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 22.98,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 642.2,
    "orderDate": 1532984400000,
    "requiredDate": 1534194000000,
    "shippedDate": 1533762000000,
    "requiredDateStart": 1534248000000,
    "requiredDateEnd": 1534251600000,
    "orderID": 10269,
    "customerID": "WHITC",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 4.56,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 1420,
    "orderDate": 1536008400000,
    "requiredDate": 1539637200000,
    "shippedDate": 1536526800000,
    "requiredDateStart": 1539696600000,
    "requiredDateEnd": 1539698400000,
    "orderID": 10297,
    "customerID": "BLONP",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 5.74,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 516,
    "orderDate": 1538514000000,
    "requiredDate": 1539723600000,
    "shippedDate": 1539810000000,
    "requiredDateStart": 1539770400000,
    "requiredDateEnd": 1539772200000,
    "orderID": 10320,
    "customerID": "WARTH",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 34.57,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 877.2,
    "orderDate": 1539810000000,
    "requiredDate": 1542232800000,
    "shippedDate": 1540414800000,
    "requiredDateStart": 1542276000000,
    "requiredDateEnd": 1542277800000,
    "orderID": 10333,
    "customerID": "WARTH",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 0.59,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 429.4,
    "orderDate": 1542664800000,
    "requiredDate": 1545084000000,
    "shippedDate": 1543269600000,
    "requiredDateStart": 1545141600000,
    "requiredDateEnd": 1545143400000,
    "orderID": 10358,
    "customerID": "LAMAI",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 19.64,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 3471.68,
    "orderDate": 1542751200000,
    "requiredDate": 1545170400000,
    "shippedDate": 1543183200000,
    "requiredDateStart": 1545220800000,
    "requiredDateEnd": 1545226200000,
    "orderID": 10359,
    "customerID": "SEVES",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 288.43,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 9210.9,
    "orderDate": 1543874400000,
    "requiredDate": 1546293600000,
    "shippedDate": 1544306400000,
    "requiredDateStart": 1546335000000,
    "requiredDateEnd": 1546338600000,
    "orderID": 10372,
    "customerID": "QUEEN",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 890.78,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 103.2,
    "orderDate": 1544392800000,
    "requiredDate": 1546812000000,
    "shippedDate": 1545170400000,
    "requiredDateStart": 1546871400000,
    "requiredDateEnd": 1546876800000,
    "orderID": 10378,
    "customerID": "FOLKO",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 5.44,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Princesa Isabel Vinhos",
    "customerContactName": "Isabel de Castro",
    "orderTotal": 716.72,
    "orderDate": 1545861600000,
    "requiredDate": 1548280800000,
    "shippedDate": 1546380000000,
    "requiredDateStart": 1548322200000,
    "requiredDateEnd": 1548325800000,
    "orderID": 10397,
    "customerID": "PRINI",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 60.26,
    "shipName": "Princesa Isabel Vinhos",
    "shipAddress": "Estrada da saúde n. 58",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1756",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 713.3,
    "orderDate": 1551650400000,
    "requiredDate": 1554066000000,
    "shippedDate": 1551823200000,
    "requiredDateStart": 1554112800000,
    "requiredDateEnd": 1554114600000,
    "orderID": 10463,
    "customerID": "SUPRD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 14.78,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 1249.1,
    "orderDate": 1552428000000,
    "requiredDate": 1554843600000,
    "shippedDate": 1553119200000,
    "requiredDateStart": 1554888600000,
    "requiredDateEnd": 1554892200000,
    "orderID": 10474,
    "customerID": "PERIC",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 83.49,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Princesa Isabel Vinhos",
    "customerContactName": "Isabel de Castro",
    "orderTotal": 558,
    "orderDate": 1552773600000,
    "requiredDate": 1555189200000,
    "shippedDate": 1553464800000,
    "requiredDateStart": 1555228800000,
    "requiredDateEnd": 1555234200000,
    "orderID": 10477,
    "customerID": "PRINI",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 13.02,
    "shipName": "Princesa Isabel Vinhos",
    "shipAddress": "Estrada da saúde n. 58",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1756",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 946,
    "orderDate": 1557176400000,
    "requiredDate": 1559595600000,
    "shippedDate": 1557349200000,
    "requiredDateStart": 1559653200000,
    "requiredDateEnd": 1559656800000,
    "orderID": 10529,
    "customerID": "MAISD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 66.69,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3554.275,
    "orderDate": 1558904400000,
    "requiredDate": 1560114000000,
    "shippedDate": 1559163600000,
    "requiredDateStart": 1560153600000,
    "requiredDateEnd": 1560155400000,
    "orderID": 10549,
    "customerID": "QUICK",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 171.24,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 890,
    "orderDate": 1560632400000,
    "requiredDate": 1563051600000,
    "shippedDate": 1562792400000,
    "requiredDateStart": 1563098400000,
    "requiredDateEnd": 1563102000000,
    "orderID": 10569,
    "customerID": "RATTC",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 58.98,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Morgenstern Gesundkost",
    "customerContactName": "Alexander Feuer",
    "orderTotal": 2147.4,
    "orderDate": 1560978000000,
    "requiredDate": 1562187600000,
    "shippedDate": 1561842000000,
    "requiredDateStart": 1562232600000,
    "requiredDateEnd": 1562238000000,
    "orderID": 10575,
    "customerID": "MORGK",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 127.34,
    "shipName": "Morgenstern Gesundkost",
    "shipAddress": "Heerstr. 22",
    "shipCity": "Leipzig",
    "shipRegion": "",
    "shipPostalCode": "4179",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 6475.4,
    "orderDate": 1563742800000,
    "requiredDate": 1566162000000,
    "shippedDate": 1564002000000,
    "requiredDateStart": 1566207000000,
    "requiredDateEnd": 1566208800000,
    "orderID": 10607,
    "customerID": "SAVEA",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 200.24,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 372.375,
    "orderDate": 1566939600000,
    "requiredDate": 1570568400000,
    "shippedDate": 1567976400000,
    "requiredDateStart": 1570609800000,
    "requiredDateEnd": 1570611600000,
    "orderID": 10648,
    "customerID": "RICAR",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 14.25,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 1434,
    "orderDate": 1566939600000,
    "requiredDate": 1569358800000,
    "shippedDate": 1567026000000,
    "requiredDateStart": 1569400200000,
    "requiredDateEnd": 1569403800000,
    "orderID": 10649,
    "customerID": "MAISD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 6.2,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 1779.2,
    "orderDate": 1567026000000,
    "requiredDate": 1569445200000,
    "shippedDate": 1567458000000,
    "requiredDateStart": 1569490200000,
    "requiredDateEnd": 1569493800000,
    "orderID": 10650,
    "customerID": "FAMIA",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 176.81,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 601.83,
    "orderDate": 1567371600000,
    "requiredDate": 1569790800000,
    "shippedDate": 1568149200000,
    "requiredDateStart": 1569850200000,
    "requiredDateEnd": 1569855600000,
    "orderID": 10654,
    "customerID": "BERGS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 55.26,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1423,
    "orderDate": 1568840400000,
    "requiredDate": 1571259600000,
    "shippedDate": 1569186000000,
    "requiredDateStart": 1571299200000,
    "requiredDateEnd": 1571304600000,
    "orderID": 10675,
    "customerID": "FRANK",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 31.85,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4451.7,
    "orderDate": 1571605200000,
    "requiredDate": 1575237600000,
    "shippedDate": 1572300000000,
    "requiredDateStart": 1575297000000,
    "requiredDateEnd": 1575298800000,
    "orderID": 10711,
    "customerID": "SAVEA",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 52.41,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2205.75,
    "orderDate": 1571691600000,
    "requiredDate": 1574114400000,
    "shippedDate": 1572123600000,
    "requiredDateStart": 1574173800000,
    "requiredDateEnd": 1574179200000,
    "orderID": 10714,
    "customerID": "SAVEA",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 24.49,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 923.875,
    "orderDate": 1572300000000,
    "requiredDate": 1574719200000,
    "shippedDate": 1572472800000,
    "requiredDateStart": 1574775000000,
    "requiredDateEnd": 1574780400000,
    "orderID": 10721,
    "customerID": "QUICK",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 48.92,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 484.2625,
    "orderDate": 1572904800000,
    "requiredDate": 1575324000000,
    "shippedDate": 1573682400000,
    "requiredDateStart": 1575376200000,
    "requiredDateEnd": 1575379800000,
    "orderID": 10730,
    "customerID": "BONAP",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 20.12,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 507,
    "orderDate": 1575237600000,
    "requiredDate": 1577656800000,
    "shippedDate": 1575756000000,
    "requiredDateStart": 1577712600000,
    "requiredDateEnd": 1577716200000,
    "orderID": 10761,
    "customerID": "RATTC",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 18.66,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 1692.8,
    "orderDate": 1577916000000,
    "requiredDate": 1580335200000,
    "shippedDate": 1578780000000,
    "requiredDateStart": 1580394600000,
    "requiredDateEnd": 1580396400000,
    "orderID": 10812,
    "customerID": "REGGC",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 59.78,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 2826,
    "orderDate": 1578520800000,
    "requiredDate": 1580940000000,
    "shippedDate": 1578866400000,
    "requiredDateStart": 1580988600000,
    "requiredDateEnd": 1580994000000,
    "orderID": 10823,
    "customerID": "LILAS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 163.97,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 4581,
    "orderDate": 1579471200000,
    "requiredDate": 1581890400000,
    "shippedDate": 1580248800000,
    "requiredDateStart": 1581948000000,
    "requiredDateEnd": 1581953400000,
    "orderID": 10841,
    "customerID": "SUPRD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 424.3,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 2603,
    "orderDate": 1579989600000,
    "requiredDate": 1582408800000,
    "shippedDate": 1580594400000,
    "requiredDateStart": 1582468200000,
    "requiredDateEnd": 1582470000000,
    "orderID": 10851,
    "customerID": "RICAR",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 160.55,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1096.2,
    "orderDate": 1580680800000,
    "requiredDate": 1583186400000,
    "shippedDate": 1581458400000,
    "requiredDateStart": 1583233200000,
    "requiredDateEnd": 1583238600000,
    "orderID": 10866,
    "customerID": "BERGS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 109.11,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 1630,
    "orderDate": 1580767200000,
    "requiredDate": 1583272800000,
    "shippedDate": 1581199200000,
    "requiredDateStart": 1583328600000,
    "requiredDateEnd": 1583334000000,
    "orderID": 10869,
    "customerID": "SEVES",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 143.28,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 160,
    "orderDate": 1580767200000,
    "requiredDate": 1583272800000,
    "shippedDate": 1581544800000,
    "requiredDateStart": 1583330400000,
    "requiredDateEnd": 1583334000000,
    "orderID": 10870,
    "customerID": "WOLZA",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 12.04,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 2058.46,
    "orderDate": 1580853600000,
    "requiredDate": 1583359200000,
    "shippedDate": 1581199200000,
    "requiredDateStart": 1583418600000,
    "requiredDateEnd": 1583422200000,
    "orderID": 10872,
    "customerID": "GODOS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 175.32,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 310,
    "orderDate": 1580940000000,
    "requiredDate": 1583445600000,
    "shippedDate": 1581372000000,
    "requiredDateStart": 1583505000000,
    "requiredDateEnd": 1583508600000,
    "orderID": 10874,
    "customerID": "GODOS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 19.58,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 122.4,
    "orderDate": 1582149600000,
    "requiredDate": 1584655200000,
    "shippedDate": 1582668000000,
    "requiredDateStart": 1584703800000,
    "requiredDateEnd": 1584709200000,
    "orderID": 10899,
    "customerID": "LILAS",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 1.21,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 742.5,
    "orderDate": 1583186400000,
    "requiredDate": 1585602000000,
    "shippedDate": 1583359200000,
    "requiredDateStart": 1585652400000,
    "requiredDateEnd": 1585656000000,
    "orderID": 10922,
    "customerID": "HANAR",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 3,
    "freight": 62.74,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 1659.535,
    "orderDate": 1584396000000,
    "requiredDate": 1588021200000,
    "shippedDate": 1584655200000,
    "requiredDateStart": 1588075200000,
    "requiredDateEnd": 1588080600000,
    "orderID": 10954,
    "customerID": "LINOD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 1,
    "freight": 27.91,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Spécialités du monde",
    "customerContactName": "Dominique Perrier",
    "orderTotal": 210,
    "orderDate": 1587502800000,
    "requiredDate": 1589922000000,
    "shippedDate": 1588107600000,
    "requiredDateStart": 1589981400000,
    "requiredDateEnd": 1589985000000,
    "orderID": 11043,
    "customerID": "SPECD",
    "employeeID": "2757a92d-5eed-4215-818f-fdf15b6b27d0",
    "teamID": 3,
    "shipVia": 2,
    "freight": 8.8,
    "shipName": "Spécialités du monde",
    "shipAddress": "25, rue Lauriston",
    "shipCity": "Paris",
    "shipRegion": "",
    "shipPostalCode": "75016",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1552.6,
    "orderDate": 1530997200000,
    "requiredDate": 1533416400000,
    "shippedDate": 1531342800000,
    "requiredDateStart": 1533466800000,
    "requiredDateEnd": 1533468600000,
    "orderID": 10250,
    "customerID": "HANAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 65.83,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 3597.9,
    "orderDate": 1531083600000,
    "requiredDate": 1533502800000,
    "shippedDate": 1531256400000,
    "requiredDateStart": 1533547800000,
    "requiredDateEnd": 1533551400000,
    "orderID": 10252,
    "customerID": "SUPRD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 51.3,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 1119.9,
    "orderDate": 1531688400000,
    "requiredDate": 1534107600000,
    "shippedDate": 1532206800000,
    "requiredDateStart": 1534154400000,
    "requiredDateEnd": 1534156200000,
    "orderID": 10257,
    "customerID": "HILAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 81.91,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Centro comercial Moctezuma",
    "customerContactName": "Francisco Chang",
    "orderTotal": 100.8,
    "orderDate": 1531861200000,
    "requiredDate": 1534280400000,
    "shippedDate": 1532466000000,
    "requiredDateStart": 1534320000000,
    "requiredDateEnd": 1534325400000,
    "orderID": 10259,
    "customerID": "CENTC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 3.25,
    "shipName": "Centro comercial Moctezuma",
    "shipAddress": "Sierras de Granada 9993",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5022",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1504.65,
    "orderDate": 1531947600000,
    "requiredDate": 1534366800000,
    "shippedDate": 1532811600000,
    "requiredDateStart": 1534410000000,
    "requiredDateEnd": 1534411800000,
    "orderID": 10260,
    "customerID": "OTTIK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 55.09,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 448,
    "orderDate": 1531947600000,
    "requiredDate": 1534366800000,
    "shippedDate": 1532898000000,
    "requiredDateStart": 1534422600000,
    "requiredDateEnd": 1534426200000,
    "orderID": 10261,
    "customerID": "QUEDE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 3.05,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 3536.6,
    "orderDate": 1532811600000,
    "requiredDate": 1535230800000,
    "shippedDate": 1533502800000,
    "requiredDateStart": 1535270400000,
    "requiredDateEnd": 1535274000000,
    "orderID": 10267,
    "customerID": "FRANK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 208.58,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Romero y tomillo",
    "customerContactName": "Alejandra Camino",
    "orderTotal": 86.5,
    "orderDate": 1534194000000,
    "requiredDate": 1535403600000,
    "shippedDate": 1534798800000,
    "requiredDateStart": 1535459400000,
    "requiredDateEnd": 1535464800000,
    "orderID": 10281,
    "customerID": "ROMEY",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 2.94,
    "shipName": "Romero y tomillo",
    "shipAddress": "Gran Vía, 1",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28001",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Romero y tomillo",
    "customerContactName": "Alejandra Camino",
    "orderTotal": 155.4,
    "orderDate": 1534280400000,
    "requiredDate": 1536699600000,
    "shippedDate": 1534798800000,
    "requiredDateStart": 1536755400000,
    "requiredDateEnd": 1536757200000,
    "orderID": 10282,
    "customerID": "ROMEY",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 12.69,
    "shipName": "Romero y tomillo",
    "shipAddress": "Gran Vía, 1",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28001",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1170.375,
    "orderDate": 1534626000000,
    "requiredDate": 1537045200000,
    "shippedDate": 1535317200000,
    "requiredDateStart": 1537084800000,
    "requiredDateEnd": 1537086600000,
    "orderID": 10284,
    "customerID": "LEHMS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 76.56,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 80.1,
    "orderDate": 1534971600000,
    "requiredDate": 1537390800000,
    "shippedDate": 1535922000000,
    "requiredDateStart": 1537435800000,
    "requiredDateEnd": 1537439400000,
    "orderID": 10288,
    "customerID": "REGGC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 7.45,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1887.6,
    "orderDate": 1535576400000,
    "requiredDate": 1537995600000,
    "shippedDate": 1536094800000,
    "requiredDateStart": 1538049600000,
    "requiredDateEnd": 1538055000000,
    "orderID": 10294,
    "customerID": "RATTC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 147.26,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 349.5,
    "orderDate": 1536181200000,
    "requiredDate": 1538600400000,
    "shippedDate": 1536786000000,
    "requiredDateStart": 1538658000000,
    "requiredDateEnd": 1538661600000,
    "orderID": 10299,
    "customerID": "RICAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 29.76,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 2708.8,
    "orderDate": 1536526800000,
    "requiredDate": 1538946000000,
    "shippedDate": 1539032400000,
    "requiredDateStart": 1538998200000,
    "requiredDateEnd": 1539003600000,
    "orderID": 10302,
    "customerID": "SUPRD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 6.27,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 516.8,
    "orderDate": 1537909200000,
    "requiredDate": 1540328400000,
    "shippedDate": 1538514000000,
    "requiredDateStart": 1540371600000,
    "requiredDateEnd": 1540375200000,
    "orderID": 10315,
    "customerID": "ISLAT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 41.76,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 164.4,
    "orderDate": 1538859600000,
    "requiredDate": 1541282400000,
    "shippedDate": 1539464400000,
    "requiredDateStart": 1541323800000,
    "requiredDateEnd": 1541325600000,
    "orderID": 10323,
    "customerID": "KOENE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 4.88,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bólido Comidas preparadas",
    "customerContactName": "Martín Sommer",
    "orderTotal": 982,
    "orderDate": 1539118800000,
    "requiredDate": 1541541600000,
    "shippedDate": 1539464400000,
    "requiredDateStart": 1541601000000,
    "requiredDateEnd": 1541604600000,
    "orderID": 10326,
    "customerID": "BOLID",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 77.92,
    "shipName": "Bólido Comidas preparadas",
    "shipAddress": "C/ Araquil, 67",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28023",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 1168,
    "orderDate": 1539464400000,
    "requiredDate": 1541887200000,
    "shippedDate": 1539723600000,
    "requiredDateStart": 1541939400000,
    "requiredDateEnd": 1541944800000,
    "orderID": 10328,
    "customerID": "FURIB",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 87.03,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 4578.43,
    "orderDate": 1539550800000,
    "requiredDate": 1543183200000,
    "shippedDate": 1540242000000,
    "requiredDateStart": 1543224600000,
    "requiredDateEnd": 1543230000000,
    "orderID": 10329,
    "customerID": "SPLIR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 191.67,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 2467,
    "orderDate": 1540328400000,
    "requiredDate": 1542751200000,
    "shippedDate": 1540764000000,
    "requiredDateStart": 1542805200000,
    "requiredDateEnd": 1542810600000,
    "orderID": 10337,
    "customerID": "FRANK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 108.26,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 934.5,
    "orderDate": 1540414800000,
    "requiredDate": 1542837600000,
    "shippedDate": 1540764000000,
    "requiredDateStart": 1542893400000,
    "requiredDateEnd": 1542898800000,
    "orderID": 10338,
    "customerID": "OLDWO",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 84.21,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1840.64,
    "orderDate": 1540850400000,
    "requiredDate": 1542060000000,
    "shippedDate": 1541282400000,
    "requiredDateStart": 1542115800000,
    "requiredDateEnd": 1542121200000,
    "orderID": 10342,
    "customerID": "FRANK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 54.83,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1584,
    "orderDate": 1540936800000,
    "requiredDate": 1543356000000,
    "shippedDate": 1541455200000,
    "requiredDateStart": 1543415400000,
    "requiredDateEnd": 1543419000000,
    "orderID": 10343,
    "customerID": "LEHMS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 110.37,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 2296,
    "orderDate": 1541023200000,
    "requiredDate": 1543442400000,
    "shippedDate": 1541368800000,
    "requiredDateStart": 1543489200000,
    "requiredDateEnd": 1543494600000,
    "orderID": 10344,
    "customerID": "WHITC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 23.29,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 814.42,
    "orderDate": 1541455200000,
    "requiredDate": 1543874400000,
    "shippedDate": 1541628000000,
    "requiredDateStart": 1543914000000,
    "requiredDateEnd": 1543915800000,
    "orderID": 10347,
    "customerID": "FAMIA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 3.1,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 363.6,
    "orderDate": 1541541600000,
    "requiredDate": 1543960800000,
    "shippedDate": 1542232800000,
    "requiredDateStart": 1544020200000,
    "requiredDateEnd": 1544025600000,
    "orderID": 10348,
    "customerID": "WANDK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 0.78,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 7390.2,
    "orderDate": 1542837600000,
    "requiredDate": 1545256800000,
    "shippedDate": 1543701600000,
    "requiredDateStart": 1545298200000,
    "requiredDateEnd": 1545300000000,
    "orderID": 10360,
    "customerID": "BLONP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 131.7,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 447.2,
    "orderDate": 1543183200000,
    "requiredDate": 1545602400000,
    "shippedDate": 1543874400000,
    "requiredDateStart": 1545658200000,
    "requiredDateEnd": 1545661800000,
    "orderID": 10363,
    "customerID": "DRACD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 30.54,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1366.4,
    "orderDate": 1543960800000,
    "requiredDate": 1546380000000,
    "shippedDate": 1544479200000,
    "requiredDateStart": 1546430400000,
    "requiredDateEnd": 1546432200000,
    "orderID": 10373,
    "customerID": "HUNGO",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 124.12,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2900,
    "orderDate": 1544652000000,
    "requiredDate": 1547071200000,
    "shippedDate": 1544911200000,
    "requiredDateStart": 1547112600000,
    "requiredDateEnd": 1547114400000,
    "orderID": 10382,
    "customerID": "ERNSH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 94.77,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1832.8,
    "orderDate": 1545256800000,
    "requiredDate": 1547676000000,
    "shippedDate": 1545602400000,
    "requiredDateStart": 1547722800000,
    "requiredDateEnd": 1547728200000,
    "orderID": 10389,
    "customerID": "BOTTM",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 47.42,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 855.015,
    "orderDate": 1546466400000,
    "requiredDate": 1548885600000,
    "shippedDate": 1546984800000,
    "requiredDateStart": 1548941400000,
    "requiredDateEnd": 1548946800000,
    "orderID": 10403,
    "customerID": "ERNSH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 73.79,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 11188.4,
    "orderDate": 1547589600000,
    "requiredDate": 1550008800000,
    "shippedDate": 1548626400000,
    "requiredDateStart": 1550068200000,
    "requiredDateEnd": 1550071800000,
    "orderID": 10417,
    "customerID": "SIMOB",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 70.29,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 1814.8,
    "orderDate": 1547676000000,
    "requiredDate": 1550095200000,
    "shippedDate": 1548280800000,
    "requiredDateStart": 1550147400000,
    "requiredDateEnd": 1550149200000,
    "orderID": 10418,
    "customerID": "QUICK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 17.55,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 2097.6,
    "orderDate": 1547935200000,
    "requiredDate": 1550354400000,
    "shippedDate": 1548799200000,
    "requiredDateStart": 1550413800000,
    "requiredDateEnd": 1550417400000,
    "orderID": 10419,
    "customerID": "RICSU",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 137.35,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Galería del gastrónomo",
    "customerContactName": "Eduardo Saavedra",
    "orderTotal": 338.2,
    "orderDate": 1548540000000,
    "requiredDate": 1550959200000,
    "shippedDate": 1549404000000,
    "requiredDateStart": 1551002400000,
    "requiredDateEnd": 1551007800000,
    "orderID": 10426,
    "customerID": "GALED",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 18.69,
    "shipName": "Galería del gastronómo",
    "shipAddress": "Rambla de Cataluña, 23",
    "shipCity": "Barcelona",
    "shipRegion": "",
    "shipPostalCode": "8022",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 651,
    "orderDate": 1548540000000,
    "requiredDate": 1550959200000,
    "shippedDate": 1551564000000,
    "requiredDateStart": 1551004200000,
    "requiredDateEnd": 1551006000000,
    "orderID": 10427,
    "customerID": "PICCO",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 31.29,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4899.2,
    "orderDate": 1548799200000,
    "requiredDate": 1550008800000,
    "shippedDate": 1549144800000,
    "requiredDateStart": 1550068200000,
    "requiredDateEnd": 1550073600000,
    "orderID": 10430,
    "customerID": "ERNSH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 458.78,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1892.25,
    "orderDate": 1548799200000,
    "requiredDate": 1550008800000,
    "shippedDate": 1549490400000,
    "requiredDateStart": 1550068200000,
    "requiredDateEnd": 1550071800000,
    "orderID": 10431,
    "customerID": "BOTTM",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 44.17,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4924.135,
    "orderDate": 1549749600000,
    "requiredDate": 1552168800000,
    "shippedDate": 1551304800000,
    "requiredDateStart": 1552208400000,
    "requiredDateEnd": 1552210200000,
    "orderID": 10440,
    "customerID": "SAVEA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 86.53,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 914.4,
    "orderDate": 1550095200000,
    "requiredDate": 1552514400000,
    "shippedDate": 1551909600000,
    "requiredDateStart": 1552555800000,
    "requiredDateEnd": 1552557600000,
    "orderID": 10447,
    "customerID": "RICAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 68.66,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Rancho grande",
    "customerContactName": "Sergio Gutiérrez",
    "orderTotal": 443.4,
    "orderDate": 1550354400000,
    "requiredDate": 1552773600000,
    "shippedDate": 1550959200000,
    "requiredDateStart": 1552825800000,
    "requiredDateEnd": 1552829400000,
    "orderID": 10448,
    "customerID": "RANCH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 38.82,
    "shipName": "Rancho grande",
    "shipAddress": "Av. del Libertador 900",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3849.66,
    "orderDate": 1550527200000,
    "requiredDate": 1551736800000,
    "shippedDate": 1552341600000,
    "requiredDateStart": 1551787200000,
    "requiredDateEnd": 1551789000000,
    "orderID": 10451,
    "customerID": "QUICK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 189.09,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 331.2,
    "orderDate": 1550700000000,
    "requiredDate": 1553119200000,
    "shippedDate": 1551045600000,
    "requiredDateStart": 1553164200000,
    "requiredDateEnd": 1553166000000,
    "orderID": 10454,
    "customerID": "LAMAI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 2.74,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 1659.2,
    "orderDate": 1551218400000,
    "requiredDate": 1553637600000,
    "shippedDate": 1551304800000,
    "requiredDateStart": 1553689800000,
    "requiredDateEnd": 1553695200000,
    "orderID": 10459,
    "customerID": "VICTE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 25.09,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 1609.28,
    "orderDate": 1551650400000,
    "requiredDate": 1554066000000,
    "shippedDate": 1552514400000,
    "requiredDateStart": 1554105600000,
    "requiredDateEnd": 1554107400000,
    "orderID": 10464,
    "customerID": "FURIB",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 89,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Comércio Mineiro",
    "customerContactName": "Pedro Afonso",
    "orderTotal": 216,
    "orderDate": 1551823200000,
    "requiredDate": 1554238800000,
    "shippedDate": 1552428000000,
    "requiredDateStart": 1554291000000,
    "requiredDateEnd": 1554296400000,
    "orderID": 10466,
    "customerID": "COMMI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 11.93,
    "shipName": "Comércio Mineiro",
    "shipAddress": "Av. dos Lusíadas, 23",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05432-043",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1820.8,
    "orderDate": 1552255200000,
    "requiredDate": 1554670800000,
    "shippedDate": 1552514400000,
    "requiredDateStart": 1554712200000,
    "requiredDateEnd": 1554714000000,
    "orderID": 10470,
    "customerID": "BONAP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 64.56,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 1584,
    "orderDate": 1553464800000,
    "requiredDate": 1554670800000,
    "shippedDate": 1553983200000,
    "requiredDateStart": 1554728400000,
    "requiredDateEnd": 1554733800000,
    "orderID": 10485,
    "customerID": "LINOD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 64.45,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 608.4,
    "orderDate": 1554152400000,
    "requiredDate": 1556571600000,
    "shippedDate": 1554843600000,
    "requiredDateStart": 1556627400000,
    "requiredDateEnd": 1556629200000,
    "orderID": 10493,
    "customerID": "LAMAI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 10.64,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Comércio Mineiro",
    "customerContactName": "Pedro Afonso",
    "orderTotal": 912,
    "orderDate": 1554152400000,
    "requiredDate": 1556571600000,
    "shippedDate": 1554757200000,
    "requiredDateStart": 1556622000000,
    "requiredDateEnd": 1556625600000,
    "orderID": 10494,
    "customerID": "COMMI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 65.99,
    "shipName": "Comércio Mineiro",
    "shipAddress": "Av. dos Lusíadas, 23",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05432-043",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1412,
    "orderDate": 1554670800000,
    "requiredDate": 1557090000000,
    "shippedDate": 1555362000000,
    "requiredDateStart": 1557149400000,
    "requiredDateEnd": 1557154800000,
    "orderID": 10499,
    "customerID": "LILAS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 102.02,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 1388.5,
    "orderDate": 1554930000000,
    "requiredDate": 1557349200000,
    "shippedDate": 1555534800000,
    "requiredDateStart": 1557388800000,
    "requiredDateEnd": 1557392400000,
    "orderID": 10504,
    "customerID": "WHITC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 59.13,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 136.8,
    "orderDate": 1555448400000,
    "requiredDate": 1557867600000,
    "shippedDate": 1556485200000,
    "requiredDateStart": 1557909000000,
    "requiredDateEnd": 1557912600000,
    "orderID": 10509,
    "customerID": "BLAUS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 0.15,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 2550,
    "orderDate": 1555534800000,
    "requiredDate": 1557954000000,
    "shippedDate": 1555794000000,
    "requiredDateStart": 1558009800000,
    "requiredDateEnd": 1558011600000,
    "orderID": 10511,
    "customerID": "BONAP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 350.64,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 4150.05,
    "orderDate": 1556139600000,
    "requiredDate": 1557349200000,
    "shippedDate": 1557003600000,
    "requiredDateStart": 1557392400000,
    "requiredDateEnd": 1557397800000,
    "orderID": 10518,
    "customerID": "TORTU",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 218.15,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 2318.24,
    "orderDate": 1556571600000,
    "requiredDate": 1558990800000,
    "shippedDate": 1557090000000,
    "requiredDateStart": 1559050200000,
    "requiredDateEnd": 1559052000000,
    "orderID": 10522,
    "customerID": "LEHMS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 45.33,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 1151.4,
    "orderDate": 1557003600000,
    "requiredDate": 1559422800000,
    "shippedDate": 1557867600000,
    "requiredDateStart": 1559466000000,
    "requiredDateEnd": 1559471400000,
    "orderID": 10526,
    "customerID": "WARTH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.59,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 1940.85,
    "orderDate": 1557694800000,
    "requiredDate": 1560114000000,
    "shippedDate": 1558386000000,
    "requiredDateStart": 1560171600000,
    "requiredDateEnd": 1560173400000,
    "orderID": 10535,
    "customerID": "ANTON",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 15.64,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 417.2,
    "orderDate": 1558386000000,
    "requiredDate": 1560805200000,
    "shippedDate": 1559163600000,
    "requiredDateStart": 1560862800000,
    "requiredDateEnd": 1560864600000,
    "orderID": 10544,
    "customerID": "LONEP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 24.91,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 1677.3,
    "orderDate": 1558990800000,
    "requiredDate": 1562619600000,
    "shippedDate": 1559768400000,
    "requiredDateStart": 1562679000000,
    "requiredDateEnd": 1562684400000,
    "orderID": 10551,
    "customerID": "FURIB",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 72.95,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1728.525,
    "orderDate": 1559163600000,
    "requiredDate": 1561582800000,
    "shippedDate": 1559682000000,
    "requiredDateStart": 1561624200000,
    "requiredDateEnd": 1561627800000,
    "orderID": 10554,
    "customerID": "OTTIK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 120.97,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1234.05,
    "orderDate": 1560114000000,
    "requiredDate": 1562533200000,
    "shippedDate": 1560632400000,
    "requiredDateStart": 1562587200000,
    "requiredDateEnd": 1562592600000,
    "orderID": 10564,
    "customerID": "RATTC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 13.75,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Trail's Head Gourmet Provisioners",
    "customerContactName": "Helvetius Nagy",
    "orderTotal": 764.3,
    "orderDate": 1560891600000,
    "requiredDate": 1563310800000,
    "shippedDate": 1561842000000,
    "requiredDateStart": 1563370200000,
    "requiredDateEnd": 1563373800000,
    "orderID": 10574,
    "customerID": "TRAIH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 37.6,
    "shipName": "Trail-s Head Gourmet Provisioners",
    "shipAddress": "722 DaVinci Blvd.",
    "shipCity": "Kirkland",
    "shipRegion": "WA",
    "shipPostalCode": "98034",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 477,
    "orderDate": 1561323600000,
    "requiredDate": 1563742800000,
    "shippedDate": 1564002000000,
    "requiredDateStart": 1563786000000,
    "requiredDateEnd": 1563789600000,
    "orderID": 10578,
    "customerID": "BSBEV",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 29.6,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1013.745,
    "orderDate": 1561496400000,
    "requiredDate": 1563915600000,
    "shippedDate": 1561928400000,
    "requiredDateStart": 1563971400000,
    "requiredDateEnd": 1563973200000,
    "orderID": 10580,
    "customerID": "OTTIK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 75.89,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 593.75,
    "orderDate": 1561842000000,
    "requiredDate": 1564261200000,
    "shippedDate": 1562187600000,
    "requiredDateStart": 1564315200000,
    "requiredDateEnd": 1564318800000,
    "orderID": 10584,
    "customerID": "BLONP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 59.14,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 1101,
    "orderDate": 1562446800000,
    "requiredDate": 1564866000000,
    "shippedDate": 1563051600000,
    "requiredDateStart": 1564916400000,
    "requiredDateEnd": 1564918200000,
    "orderID": 10590,
    "customerID": "MEREP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 44.77,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Hungry Coyote Import Store",
    "customerContactName": "Yoshi Latimer",
    "orderTotal": 479.8,
    "orderDate": 1563224400000,
    "requiredDate": 1565643600000,
    "shippedDate": 1563656400000,
    "requiredDateStart": 1565688600000,
    "requiredDateEnd": 1565690400000,
    "orderID": 10600,
    "customerID": "HUNGC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 45.13,
    "shipName": "Hungry Coyote Import Store",
    "shipAddress": "City Center Plaza 516 Main St.",
    "shipCity": "Elgin",
    "shipRegion": "OR",
    "shipPostalCode": "97827",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 1130.4,
    "orderDate": 1563742800000,
    "requiredDate": 1566162000000,
    "shippedDate": 1564520400000,
    "requiredDateStart": 1566219600000,
    "requiredDateEnd": 1566225000000,
    "orderID": 10606,
    "customerID": "TRADH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 79.4,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 1064,
    "orderDate": 1563829200000,
    "requiredDate": 1566248400000,
    "shippedDate": 1564606800000,
    "requiredDateStart": 1566304200000,
    "requiredDateEnd": 1566307800000,
    "orderID": 10608,
    "customerID": "TOMSP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 27.79,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 353.2,
    "orderDate": 1564347600000,
    "requiredDate": 1566766800000,
    "shippedDate": 1564606800000,
    "requiredDateStart": 1566819000000,
    "requiredDateEnd": 1566822600000,
    "orderID": 10613,
    "customerID": "HILAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 8.11,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 1402.5,
    "orderDate": 1564520400000,
    "requiredDate": 1566939600000,
    "shippedDate": 1564866000000,
    "requiredDateStart": 1566997200000,
    "requiredDateEnd": 1567000800000,
    "orderID": 10617,
    "customerID": "GREAL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 18.53,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 758.5,
    "orderDate": 1564952400000,
    "requiredDate": 1567371600000,
    "shippedDate": 1565470800000,
    "requiredDateStart": 1567414800000,
    "requiredDateEnd": 1567418400000,
    "orderID": 10621,
    "customerID": "ISLAT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 23.73,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 560,
    "orderDate": 1565038800000,
    "requiredDate": 1567458000000,
    "shippedDate": 1565470800000,
    "requiredDateStart": 1567499400000,
    "requiredDateEnd": 1567503000000,
    "orderID": 10622,
    "customerID": "RICAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 50.97,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "The Cracker Box",
    "customerContactName": "Liu Wong",
    "orderTotal": 1393.24,
    "orderDate": 1565125200000,
    "requiredDate": 1567544400000,
    "shippedDate": 1566162000000,
    "requiredDateStart": 1567594800000,
    "requiredDateEnd": 1567598400000,
    "orderID": 10624,
    "customerID": "THECR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 94.8,
    "shipName": "The Cracker Box",
    "shipAddress": "55 Grizzly Peak Rd.",
    "shipCity": "Butte",
    "shipRegion": "MT",
    "shipPostalCode": "59801",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 450,
    "orderDate": 1565557200000,
    "requiredDate": 1567976400000,
    "shippedDate": 1566248400000,
    "requiredDateStart": 1568017800000,
    "requiredDateEnd": 1568021400000,
    "orderID": 10628,
    "customerID": "BLONP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 30.36,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 2775.05,
    "orderDate": 1565557200000,
    "requiredDate": 1567976400000,
    "shippedDate": 1566248400000,
    "requiredDateStart": 1568035800000,
    "requiredDateEnd": 1568039400000,
    "orderID": 10629,
    "customerID": "GODOS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 85.46,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Folies gourmandes",
    "customerContactName": "Martine Rancé",
    "orderTotal": 4985.5,
    "orderDate": 1565816400000,
    "requiredDate": 1568235600000,
    "shippedDate": 1566334800000,
    "requiredDateStart": 1568295000000,
    "requiredDateEnd": 1568298600000,
    "orderID": 10634,
    "customerID": "FOLIG",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 487.38,
    "shipName": "Folies gourmandes",
    "shipAddress": "184, chaussée de Tournai",
    "shipCity": "Lille",
    "shipRegion": "",
    "shipPostalCode": "59000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 629.5,
    "orderDate": 1566162000000,
    "requiredDate": 1568581200000,
    "shippedDate": 1566766800000,
    "requiredDateStart": 1568629800000,
    "requiredDateEnd": 1568631600000,
    "orderID": 10636,
    "customerID": "WARTH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 1.15,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 708.75,
    "orderDate": 1566334800000,
    "requiredDate": 1568754000000,
    "shippedDate": 1566939600000,
    "requiredDateStart": 1568799000000,
    "requiredDateEnd": 1568802600000,
    "orderID": 10640,
    "customerID": "WANDK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 23.55,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 2054,
    "orderDate": 1566421200000,
    "requiredDate": 1568840400000,
    "shippedDate": 1566766800000,
    "requiredDateStart": 1568896200000,
    "requiredDateEnd": 1568899800000,
    "orderID": 10641,
    "customerID": "HILAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 179.61,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1535,
    "orderDate": 1566766800000,
    "requiredDate": 1569186000000,
    "shippedDate": 1567371600000,
    "requiredDateStart": 1569243600000,
    "requiredDateEnd": 1569247200000,
    "orderID": 10645,
    "customerID": "HANAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 12.41,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 636,
    "orderDate": 1566853200000,
    "requiredDate": 1568062800000,
    "shippedDate": 1567458000000,
    "requiredDateStart": 1568116800000,
    "requiredDateEnd": 1568120400000,
    "orderID": 10647,
    "customerID": "QUEDE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 45.54,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 318.835,
    "orderDate": 1567285200000,
    "requiredDate": 1569704400000,
    "shippedDate": 1567890000000,
    "requiredDateStart": 1569756600000,
    "requiredDateEnd": 1569758400000,
    "orderID": 10652,
    "customerID": "GOURL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 7.14,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 4464.6,
    "orderDate": 1567630800000,
    "requiredDate": 1570050000000,
    "shippedDate": 1567890000000,
    "requiredDateStart": 1570096800000,
    "requiredDateEnd": 1570100400000,
    "orderID": 10658,
    "customerID": "QUICK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 364.15,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 2301.75,
    "orderDate": 1568581200000,
    "requiredDate": 1571000400000,
    "shippedDate": 1568754000000,
    "requiredDateStart": 1571043600000,
    "requiredDateEnd": 1571045400000,
    "orderID": 10670,
    "customerID": "FRANK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 203.48,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 45,
    "orderDate": 1568754000000,
    "requiredDate": 1571173200000,
    "shippedDate": 1569790800000,
    "requiredDateStart": 1571214600000,
    "requiredDateEnd": 1571220000000,
    "orderID": 10674,
    "customerID": "ISLAT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 0.9,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 801.1,
    "orderDate": 1569704400000,
    "requiredDate": 1570914000000,
    "shippedDate": 1570050000000,
    "requiredDateStart": 1570960800000,
    "requiredDateEnd": 1570962600000,
    "orderID": 10685,
    "customerID": "GOURL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 33.75,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 3160.6,
    "orderDate": 1569877200000,
    "requiredDate": 1571086800000,
    "shippedDate": 1570395600000,
    "requiredDateStart": 1571137200000,
    "requiredDateEnd": 1571140800000,
    "orderID": 10688,
    "customerID": "VAFFE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 299.09,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 878,
    "orderDate": 1570050000000,
    "requiredDate": 1572472800000,
    "shippedDate": 1570914000000,
    "requiredDateStart": 1572526800000,
    "requiredDateEnd": 1572528600000,
    "orderID": 10692,
    "customerID": "ALFKI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 61.02,
    "shipName": "Alfred-s Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 3436.4435,
    "orderDate": 1570568400000,
    "requiredDate": 1572991200000,
    "shippedDate": 1571259600000,
    "requiredDateStart": 1573050600000,
    "requiredDateEnd": 1573054200000,
    "orderID": 10698,
    "customerID": "ERNSH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 272.47,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 330,
    "orderDate": 1570914000000,
    "requiredDate": 1574546400000,
    "shippedDate": 1571605200000,
    "requiredDateStart": 1574586000000,
    "requiredDateEnd": 1574587800000,
    "orderID": 10702,
    "customerID": "ALFKI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 23.94,
    "shipName": "Alfred-s Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 1641,
    "orderDate": 1571173200000,
    "requiredDate": 1572386400000,
    "shippedDate": 1571778000000,
    "requiredDateStart": 1572431400000,
    "requiredDateEnd": 1572435000000,
    "orderID": 10707,
    "customerID": "AROUT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 21.74,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Rancho grande",
    "customerContactName": "Sergio Gutiérrez",
    "orderTotal": 706,
    "orderDate": 1571864400000,
    "requiredDate": 1574287200000,
    "shippedDate": 1572123600000,
    "requiredDateStart": 1574343000000,
    "requiredDateEnd": 1574346600000,
    "orderID": 10716,
    "customerID": "RANCH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 22.57,
    "shipName": "Rancho grande",
    "shipAddress": "Av. del Libertador 900",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 287.8,
    "orderDate": 1572472800000,
    "requiredDate": 1574892000000,
    "shippedDate": 1572904800000,
    "requiredDateStart": 1574933400000,
    "requiredDateEnd": 1574938800000,
    "orderID": 10725,
    "customerID": "FAMIA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 10.83,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 655,
    "orderDate": 1572732000000,
    "requiredDate": 1573941600000,
    "shippedDate": 1575496800000,
    "requiredDateStart": 1573986600000,
    "requiredDateEnd": 1573990200000,
    "orderID": 10726,
    "customerID": "EASTC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 16.56,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 1296.75,
    "orderDate": 1572818400000,
    "requiredDate": 1575237600000,
    "shippedDate": 1573423200000,
    "requiredDateStart": 1575288000000,
    "requiredDateEnd": 1575289800000,
    "orderID": 10728,
    "customerID": "QUEEN",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.33,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 1416,
    "orderDate": 1573596000000,
    "requiredDate": 1576015200000,
    "shippedDate": 1574632800000,
    "requiredDateStart": 1576065600000,
    "requiredDateEnd": 1576069200000,
    "orderID": 10740,
    "customerID": "WHITC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 81.88,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 228,
    "orderDate": 1573682400000,
    "requiredDate": 1574892000000,
    "shippedDate": 1574028000000,
    "requiredDateStart": 1574946000000,
    "requiredDateEnd": 1574949600000,
    "orderID": 10741,
    "customerID": "AROUT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 10.96,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 1080,
    "orderDate": 1574200800000,
    "requiredDate": 1576620000000,
    "shippedDate": 1576706400000,
    "requiredDateStart": 1576677600000,
    "requiredDateEnd": 1576679400000,
    "orderID": 10749,
    "customerID": "ISLAT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 61.53,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1948.5,
    "orderDate": 1574719200000,
    "requiredDate": 1577138400000,
    "shippedDate": 1574892000000,
    "requiredDateStart": 1577178000000,
    "requiredDateEnd": 1577179800000,
    "orderID": 10755,
    "customerID": "BONAP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 16.71,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 2917,
    "orderDate": 1575151200000,
    "requiredDate": 1577570400000,
    "shippedDate": 1575928800000,
    "requiredDateStart": 1577629800000,
    "requiredDateEnd": 1577631600000,
    "orderID": 10760,
    "customerID": "MAISD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 155.64,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 2310,
    "orderDate": 1575496800000,
    "requiredDate": 1577916000000,
    "shippedDate": 1575842400000,
    "requiredDateStart": 1577964600000,
    "requiredDateEnd": 1577968200000,
    "orderID": 10766,
    "customerID": "OTTIK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 157.55,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 28,
    "orderDate": 1575496800000,
    "requiredDate": 1577916000000,
    "shippedDate": 1576360800000,
    "requiredDateStart": 1577961000000,
    "requiredDateEnd": 1577966400000,
    "orderID": 10767,
    "customerID": "SUPRD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 1.59,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 868.75,
    "orderDate": 1576015200000,
    "requiredDate": 1577224800000,
    "shippedDate": 1576101600000,
    "requiredDateStart": 1577278800000,
    "requiredDateEnd": 1577282400000,
    "orderID": 10774,
    "customerID": "FOLKO",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 48.2,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1442.5,
    "orderDate": 1576620000000,
    "requiredDate": 1579039200000,
    "shippedDate": 1576706400000,
    "requiredDateStart": 1579082400000,
    "requiredDateEnd": 1579086000000,
    "orderID": 10783,
    "customerID": "HANAR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 124.98,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 1488,
    "orderDate": 1576620000000,
    "requiredDate": 1579039200000,
    "shippedDate": 1576965600000,
    "requiredDateStart": 1579082400000,
    "requiredDateEnd": 1579087800000,
    "orderID": 10784,
    "customerID": "MAGAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 70.09,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Bólido Comidas preparadas",
    "customerContactName": "Martín Sommer",
    "orderTotal": 3026.85,
    "orderDate": 1577570400000,
    "requiredDate": 1579989600000,
    "shippedDate": 1577743200000,
    "requiredDateStart": 1580038200000,
    "requiredDateEnd": 1580043600000,
    "orderID": 10801,
    "customerID": "BOLID",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 97.09,
    "shipName": "Bólido Comidas preparadas",
    "shipAddress": "C/ Araquil, 67",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28023",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 2942.8125,
    "orderDate": 1577570400000,
    "requiredDate": 1579989600000,
    "shippedDate": 1577916000000,
    "requiredDateStart": 1580034600000,
    "requiredDateEnd": 1580036400000,
    "orderID": 10802,
    "customerID": "SIMOB",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 257.26,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 1193.01,
    "orderDate": 1577656800000,
    "requiredDate": 1580076000000,
    "shippedDate": 1578261600000,
    "requiredDateStart": 1580130000000,
    "requiredDateEnd": 1580135400000,
    "orderID": 10803,
    "customerID": "WELLI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 55.23,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 18.4,
    "orderDate": 1577743200000,
    "requiredDate": 1580162400000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1580220000000,
    "requiredDateEnd": 1580225400000,
    "orderID": 10807,
    "customerID": "FRANS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 1.36,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 8446.45,
    "orderDate": 1578261600000,
    "requiredDate": 1580680800000,
    "shippedDate": 1580767200000,
    "requiredDateStart": 1580734800000,
    "requiredDateEnd": 1580736600000,
    "orderID": 10816,
    "customerID": "GREAL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 719.78,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 1974,
    "orderDate": 1578866400000,
    "requiredDate": 1582495200000,
    "shippedDate": 1579557600000,
    "requiredDateStart": 1582551000000,
    "requiredDateEnd": 1582552800000,
    "orderID": 10830,
    "customerID": "TRADH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 81.83,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 211.2,
    "orderDate": 1579384800000,
    "requiredDate": 1583100000000,
    "shippedDate": 1581804000000,
    "requiredDateStart": 1583139600000,
    "requiredDateEnd": 1583145000000,
    "orderID": 10840,
    "customerID": "LINOD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 2.71,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 159,
    "orderDate": 1579557600000,
    "requiredDate": 1581976800000,
    "shippedDate": 1579989600000,
    "requiredDateStart": 1582032600000,
    "requiredDateEnd": 1582036200000,
    "orderID": 10843,
    "customerID": "VICTE",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 9.26,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4931.92,
    "orderDate": 1579644000000,
    "requiredDate": 1580853600000,
    "shippedDate": 1581285600000,
    "requiredDateStart": 1580896800000,
    "requiredDateEnd": 1580898600000,
    "orderID": 10847,
    "customerID": "SAVEA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 487.57,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 3523.4,
    "orderDate": 1580335200000,
    "requiredDate": 1582754400000,
    "shippedDate": 1581890400000,
    "requiredDateStart": 1582799400000,
    "requiredDateEnd": 1582803000000,
    "orderID": 10861,
    "customerID": "WHITC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 14.93,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 441.15,
    "orderDate": 1580594400000,
    "requiredDate": 1583100000000,
    "shippedDate": 1581890400000,
    "requiredDateStart": 1583141400000,
    "requiredDateEnd": 1583143200000,
    "orderID": 10863,
    "customerID": "HILAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 30.26,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 282,
    "orderDate": 1580594400000,
    "requiredDate": 1583100000000,
    "shippedDate": 1581199200000,
    "requiredDateStart": 1583159400000,
    "requiredDateEnd": 1583164800000,
    "orderID": 10864,
    "customerID": "AROUT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 3.04,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 336.8,
    "orderDate": 1580940000000,
    "requiredDate": 1583445600000,
    "shippedDate": 1581199200000,
    "requiredDateStart": 1583497800000,
    "requiredDateEnd": 1583501400000,
    "orderID": 10873,
    "customerID": "WILMK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 0.82,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 709.55,
    "orderDate": 1580940000000,
    "requiredDate": 1583445600000,
    "shippedDate": 1583186400000,
    "requiredDateStart": 1583505000000,
    "requiredDateEnd": 1583510400000,
    "orderID": 10875,
    "customerID": "BERGS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 32.37,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 1539,
    "orderDate": 1581285600000,
    "requiredDate": 1583791200000,
    "shippedDate": 1581458400000,
    "requiredDateStart": 1583839800000,
    "requiredDateEnd": 1583845200000,
    "orderID": 10878,
    "customerID": "QUICK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 46.69,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 150,
    "orderDate": 1581372000000,
    "requiredDate": 1583877600000,
    "shippedDate": 1581976800000,
    "requiredDateStart": 1583931600000,
    "requiredDateEnd": 1583935200000,
    "orderID": 10881,
    "customerID": "CACTU",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 2.84,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 892.64,
    "orderDate": 1581372000000,
    "requiredDate": 1583877600000,
    "shippedDate": 1582149600000,
    "requiredDateStart": 1583928000000,
    "requiredDateEnd": 1583929800000,
    "orderID": 10882,
    "customerID": "SAVEA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 23.1,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Let's Stop N Shop",
    "customerContactName": "Jaime Yorres",
    "orderTotal": 1378.07,
    "orderDate": 1581458400000,
    "requiredDate": 1583964000000,
    "shippedDate": 1581544800000,
    "requiredDateStart": 1584007200000,
    "requiredDateEnd": 1584012600000,
    "orderID": 10884,
    "customerID": "LETSS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 90.97,
    "shipName": "Let-s Stop N Shop",
    "shipAddress": "87 Polk St. Suite 5",
    "shipCity": "San Francisco",
    "shipRegion": "CA",
    "shipPostalCode": "94117",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 2090,
    "orderDate": 1581890400000,
    "requiredDate": 1584396000000,
    "shippedDate": 1582063200000,
    "requiredDateStart": 1584435600000,
    "requiredDateEnd": 1584437400000,
    "orderID": 10892,
    "customerID": "MAISD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 120.27,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Océano Atlántico Ltda.",
    "customerContactName": "Yvonne Moncada",
    "orderTotal": 30,
    "orderDate": 1582149600000,
    "requiredDate": 1584655200000,
    "shippedDate": 1583445600000,
    "requiredDateStart": 1584696600000,
    "requiredDateEnd": 1584702000000,
    "orderID": 10898,
    "customerID": "OCEAN",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 1.27,
    "shipName": "Océano Atlántico Ltda.",
    "shipAddress": "Ing. Gustavo Moncada 8585 Piso 20-A",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 934.5,
    "orderDate": 1582408800000,
    "requiredDate": 1584914400000,
    "shippedDate": 1582668000000,
    "requiredDateStart": 1584968400000,
    "requiredDateEnd": 1584972000000,
    "orderID": 10901,
    "customerID": "HILAA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 62.09,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 427.5,
    "orderDate": 1582581600000,
    "requiredDate": 1583877600000,
    "shippedDate": 1583186400000,
    "requiredDateStart": 1583928000000,
    "requiredDateEnd": 1583933400000,
    "orderID": 10906,
    "customerID": "WOLZA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 26.29,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 663.1,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1583445600000,
    "requiredDateStart": 1585224000000,
    "requiredDateEnd": 1585229400000,
    "orderID": 10908,
    "customerID": "REGGC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 32.96,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 768.75,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1583272800000,
    "requiredDateStart": 1585220400000,
    "requiredDateEnd": 1585224000000,
    "orderID": 10913,
    "customerID": "QUEEN",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 33.05,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Romero y tomillo",
    "customerContactName": "Alejandra Camino",
    "orderTotal": 365.89,
    "orderDate": 1583100000000,
    "requiredDate": 1585515600000,
    "shippedDate": 1583877600000,
    "requiredDateStart": 1585566000000,
    "requiredDateEnd": 1585569600000,
    "orderID": 10917,
    "customerID": "ROMEY",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 8.29,
    "shipName": "Romero y tomillo",
    "shipAddress": "Gran Vía, 1",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28001",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 390,
    "orderDate": 1583186400000,
    "requiredDate": 1585602000000,
    "shippedDate": 1583704800000,
    "requiredDateStart": 1585648800000,
    "requiredDateEnd": 1585652400000,
    "orderID": 10920,
    "customerID": "AROUT",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 29.61,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Ana Trujillo Emparedados y helados",
    "customerContactName": "Ana Trujillo",
    "orderTotal": 514.4,
    "orderDate": 1583272800000,
    "requiredDate": 1585688400000,
    "shippedDate": 1583877600000,
    "requiredDateStart": 1585746000000,
    "requiredDateEnd": 1585749600000,
    "orderID": 10926,
    "customerID": "ANATR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 39.92,
    "shipName": "Ana Trujillo Emparedados y helados",
    "shipAddress": "Avda. de la Constitución 2222",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5021",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "La corne d'abondance",
    "customerContactName": "Daniel Tonini",
    "orderTotal": 800,
    "orderDate": 1583359200000,
    "requiredDate": 1585774800000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1585814400000,
    "requiredDateEnd": 1585816200000,
    "orderID": 10927,
    "customerID": "LACOR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 19.79,
    "shipName": "La corne d-abondance",
    "shipAddress": "67, avenue de l-Europe",
    "shipCity": "Versailles",
    "shipRegion": "",
    "shipPostalCode": "78000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 2255.5,
    "orderDate": 1583445600000,
    "requiredDate": 1587070800000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1587123000000,
    "requiredDateEnd": 1587124800000,
    "orderID": 10930,
    "customerID": "SUPRD",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 15.55,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 799.2,
    "orderDate": 1583445600000,
    "requiredDate": 1584655200000,
    "shippedDate": 1584568800000,
    "requiredDateStart": 1584702000000,
    "requiredDateEnd": 1584705600000,
    "orderID": 10931,
    "customerID": "RICSU",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 13.6,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 619.5,
    "orderDate": 1583704800000,
    "requiredDate": 1586120400000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1586160000000,
    "requiredDateEnd": 1586165400000,
    "orderID": 10935,
    "customerID": "WELLI",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 47.59,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 711,
    "orderDate": 1583877600000,
    "requiredDate": 1586293200000,
    "shippedDate": 1584568800000,
    "requiredDateStart": 1586345400000,
    "requiredDateEnd": 1586349000000,
    "orderID": 10943,
    "customerID": "BSBEV",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 2.17,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Morgenstern Gesundkost",
    "customerContactName": "Alexander Feuer",
    "orderTotal": 245,
    "orderDate": 1583964000000,
    "requiredDate": 1586379600000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1586435400000,
    "requiredDateEnd": 1586440800000,
    "orderID": 10945,
    "customerID": "MORGK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 10.22,
    "shipName": "Morgenstern Gesundkost",
    "shipAddress": "Heerstr. 22",
    "shipCity": "Leipzig",
    "shipRegion": "",
    "shipPostalCode": "4179",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 1098.46,
    "orderDate": 1584655200000,
    "requiredDate": 1587070800000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1587115800000,
    "requiredDateEnd": 1587117600000,
    "orderID": 10966,
    "customerID": "CHOPS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 27.19,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "La corne d'abondance",
    "customerContactName": "Daniel Tonini",
    "orderTotal": 251.5,
    "orderDate": 1585000800000,
    "requiredDate": 1587416400000,
    "shippedDate": 1585173600000,
    "requiredDateStart": 1587461400000,
    "requiredDateEnd": 1587465000000,
    "orderID": 10972,
    "customerID": "LACOR",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 0.02,
    "shipName": "La corne d-abondance",
    "shipAddress": "67, avenue de l-Europe",
    "shipCity": "Versailles",
    "shipRegion": "",
    "shipPostalCode": "78000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 248,
    "orderDate": 1585260000000,
    "requiredDate": 1588885200000,
    "shippedDate": 1587070800000,
    "requiredDateStart": 1588930200000,
    "requiredDateEnd": 1588935600000,
    "orderID": 10980,
    "customerID": "FOLKO",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 1.26,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 560,
    "orderDate": 1585774800000,
    "requiredDate": 1588194000000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588233600000,
    "requiredDateEnd": 1588237200000,
    "orderID": 10996,
    "customerID": "QUICK",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 1.12,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1811.1,
    "orderDate": 1586120400000,
    "requiredDate": 1588539600000,
    "shippedDate": 1586984400000,
    "requiredDateStart": 1588579200000,
    "requiredDateEnd": 1588581000000,
    "orderID": 11002,
    "customerID": "SAVEA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 141.16,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 1575,
    "orderDate": 1586725200000,
    "requiredDate": 1589144400000,
    "shippedDate": 1586984400000,
    "requiredDateStart": 1589193000000,
    "requiredDateEnd": 1589194800000,
    "orderID": 11018,
    "customerID": "LONEP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 11.65,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 1966.81,
    "orderDate": 1586898000000,
    "requiredDate": 1589317200000,
    "shippedDate": 1587330000000,
    "requiredDateStart": 1589365800000,
    "requiredDateEnd": 1589371200000,
    "orderID": 11024,
    "customerID": "EASTC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 74.36,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 1030,
    "orderDate": 1586898000000,
    "requiredDate": 1589317200000,
    "shippedDate": 1588021200000,
    "requiredDateStart": 1589369400000,
    "requiredDateEnd": 1589371200000,
    "orderID": 11026,
    "customerID": "FRANS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 47.09,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 1286.8,
    "orderDate": 1586984400000,
    "requiredDate": 1589403600000,
    "shippedDate": 1587934800000,
    "requiredDateStart": 1589443200000,
    "requiredDateEnd": 1589445000000,
    "orderID": 11029,
    "customerID": "CHOPS",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 47.84,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 200,
    "orderDate": 1587502800000,
    "requiredDate": 1589922000000,
    "shippedDate": 1587934800000,
    "requiredDateStart": 1589981400000,
    "requiredDateEnd": 1589983200000,
    "orderID": 11040,
    "customerID": "GREAL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 18.84,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 591.6,
    "orderDate": 1587589200000,
    "requiredDate": 1590008400000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1590055200000,
    "requiredDateEnd": 1590058800000,
    "orderID": 11044,
    "customerID": "WOLZA",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 1,
    "freight": 8.72,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 510,
    "orderDate": 1588194000000,
    "requiredDate": 1591822800000,
    "shippedDate": 1532984400000,
    "requiredDateStart": 1591862400000,
    "requiredDateEnd": 1591867800000,
    "orderID": 11061,
    "customerID": "GREAL",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 3,
    "freight": 14.01,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 406.4,
    "orderDate": 1588194000000,
    "requiredDate": 1590613200000,
    "shippedDate": 1531688400000,
    "requiredDateStart": 1590669000000,
    "requiredDateEnd": 1590674400000,
    "orderID": 11062,
    "customerID": "REGGC",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 29.93,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 5218,
    "orderDate": 1588626000000,
    "requiredDate": 1591045200000,
    "shippedDate": 1532984400000,
    "requiredDateStart": 1591102800000,
    "requiredDateEnd": 1591104600000,
    "orderID": 11072,
    "customerID": "ERNSH",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 258.64,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 792.75,
    "orderDate": 1588712400000,
    "requiredDate": 1591131600000,
    "shippedDate": 1531688400000,
    "requiredDateStart": 1591183800000,
    "requiredDateEnd": 1591185600000,
    "orderID": 11076,
    "customerID": "BONAP",
    "employeeID": "df3dffe4-6fb4-4a9c-8512-71f7cb508180",
    "teamID": 2,
    "shipVia": 2,
    "freight": 38.28,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 654.06,
    "orderDate": 1530997200000,
    "requiredDate": 1533416400000,
    "shippedDate": 1531602000000,
    "requiredDateStart": 1533459600000,
    "requiredDateEnd": 1533463200000,
    "orderID": 10251,
    "customerID": "VICTE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 41.34,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1444.8,
    "orderDate": 1531170000000,
    "requiredDate": 1532379600000,
    "shippedDate": 1531688400000,
    "requiredDateStart": 1532433600000,
    "requiredDateEnd": 1532437200000,
    "orderID": 10253,
    "customerID": "HANAR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.17,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 517.8,
    "orderDate": 1531602000000,
    "requiredDate": 1534021200000,
    "shippedDate": 1531774800000,
    "requiredDateStart": 1534075200000,
    "requiredDateEnd": 1534080600000,
    "orderID": 10256,
    "customerID": "WELLI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 13.97,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 346.56,
    "orderDate": 1532552400000,
    "requiredDate": 1536181200000,
    "shippedDate": 1532984400000,
    "requiredDateStart": 1536222600000,
    "requiredDateEnd": 1536228000000,
    "orderID": 10266,
    "customerID": "WARTH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 25.73,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 2037.28,
    "orderDate": 1533416400000,
    "requiredDate": 1535835600000,
    "shippedDate": 1534021200000,
    "requiredDateStart": 1535880600000,
    "requiredDateEnd": 1535884200000,
    "orderID": 10273,
    "customerID": "QUICK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 76.07,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1414.8,
    "orderDate": 1534366800000,
    "requiredDate": 1536786000000,
    "shippedDate": 1534971600000,
    "requiredDateStart": 1536841800000,
    "requiredDateEnd": 1536845400000,
    "orderID": 10283,
    "customerID": "LILAS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 84.81,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1762,
    "orderDate": 1537304400000,
    "requiredDate": 1539723600000,
    "shippedDate": 1540242000000,
    "requiredDateStart": 1539770400000,
    "requiredDateEnd": 1539775800000,
    "orderID": 10309,
    "customerID": "HUNGO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 47.3,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 144,
    "orderDate": 1538514000000,
    "requiredDate": 1540936800000,
    "shippedDate": 1539205200000,
    "requiredDateStart": 1540987200000,
    "requiredDateEnd": 1540989000000,
    "orderID": 10321,
    "customerID": "ISLAT",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 3.43,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1649,
    "orderDate": 1539637200000,
    "requiredDate": 1542060000000,
    "shippedDate": 1540674000000,
    "requiredDateStart": 1542117600000,
    "requiredDateEnd": 1542121200000,
    "orderID": 10330,
    "customerID": "LILAS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 12.75,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 1786.88,
    "orderDate": 1539723600000,
    "requiredDate": 1543356000000,
    "shippedDate": 1540069200000,
    "requiredDateStart": 1543395600000,
    "requiredDateEnd": 1543397400000,
    "orderID": 10332,
    "customerID": "MEREP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 52.84,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1618.88,
    "orderDate": 1541368800000,
    "requiredDate": 1544997600000,
    "shippedDate": 1541628000000,
    "requiredDateStart": 1545051600000,
    "requiredDateEnd": 1545055200000,
    "orderID": 10346,
    "customerID": "RATTC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 142.08,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 136.3,
    "orderDate": 1541973600000,
    "requiredDate": 1543183200000,
    "shippedDate": 1542492000000,
    "requiredDateStart": 1543224600000,
    "requiredDateEnd": 1543228200000,
    "orderID": 10352,
    "customerID": "FURIB",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 1.3,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1549.6,
    "orderDate": 1543096800000,
    "requiredDate": 1545516000000,
    "shippedDate": 1543356000000,
    "requiredDateStart": 1545570000000,
    "requiredDateEnd": 1545573600000,
    "orderID": 10362,
    "customerID": "BONAP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 96.04,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 403.2,
    "orderDate": 1543269600000,
    "requiredDate": 1545688800000,
    "shippedDate": 1543701600000,
    "requiredDateStart": 1545739200000,
    "requiredDateEnd": 1545741000000,
    "orderID": 10365,
    "customerID": "ANTON",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 22,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Hungry Coyote Import Store",
    "customerContactName": "Yoshi Latimer",
    "orderTotal": 338,
    "orderDate": 1544047200000,
    "requiredDate": 1546466400000,
    "shippedDate": 1544306400000,
    "requiredDateStart": 1546506000000,
    "requiredDateEnd": 1546507800000,
    "orderID": 10375,
    "customerID": "HUNGC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 20.12,
    "shipName": "Hungry Coyote Import Store",
    "shipAddress": "City Center Plaza 516 Main St.",
    "shipCity": "Elgin",
    "shipRegion": "OR",
    "shipPostalCode": "97827",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 112,
    "orderDate": 1544565600000,
    "requiredDate": 1546984800000,
    "shippedDate": 1544652000000,
    "requiredDateStart": 1547035200000,
    "requiredDateEnd": 1547037000000,
    "orderID": 10381,
    "customerID": "LILAS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 7.99,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 2222.4,
    "orderDate": 1544911200000,
    "requiredDate": 1547330400000,
    "shippedDate": 1545256800000,
    "requiredDateStart": 1547375400000,
    "requiredDateEnd": 1547380800000,
    "orderID": 10384,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 168.64,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 86.4,
    "orderDate": 1545516000000,
    "requiredDate": 1547935200000,
    "shippedDate": 1546207200000,
    "requiredDateStart": 1547974800000,
    "requiredDateEnd": 1547980200000,
    "orderID": 10391,
    "customerID": "DRACD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 5.45,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Océano Atlántico Ltda.",
    "customerContactName": "Yvonne Moncada",
    "orderTotal": 319.2,
    "orderDate": 1546984800000,
    "requiredDate": 1549404000000,
    "shippedDate": 1547416800000,
    "requiredDateStart": 1549461600000,
    "requiredDateEnd": 1549465200000,
    "orderID": 10409,
    "customerID": "OCEAN",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 29.83,
    "shipName": "Océano Atlántico Ltda.",
    "shipAddress": "Ing. Gustavo Moncada 8585 Piso 20-A",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 802,
    "orderDate": 1547071200000,
    "requiredDate": 1549490400000,
    "shippedDate": 1547503200000,
    "requiredDateStart": 1549540800000,
    "requiredDateEnd": 1549544400000,
    "orderID": 10410,
    "customerID": "BOTTM",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 2.4,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 2123.2,
    "orderDate": 1547416800000,
    "requiredDate": 1549836000000,
    "shippedDate": 1547589600000,
    "requiredDateStart": 1549890000000,
    "requiredDateEnd": 1549895400000,
    "orderID": 10413,
    "customerID": "LAMAI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 95.66,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Hungry Coyote Import Store",
    "customerContactName": "Yoshi Latimer",
    "orderTotal": 102.4,
    "orderDate": 1547503200000,
    "requiredDate": 1549922400000,
    "shippedDate": 1548280800000,
    "requiredDateStart": 1549967400000,
    "requiredDateEnd": 1549969200000,
    "orderID": 10415,
    "customerID": "HUNGC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 0.2,
    "shipName": "Hungry Coyote Import Store",
    "shipAddress": "City Center Plaza 516 Main St.",
    "shipCity": "Elgin",
    "shipRegion": "OR",
    "shipPostalCode": "97827",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 1707.84,
    "orderDate": 1548021600000,
    "requiredDate": 1550440800000,
    "shippedDate": 1548540000000,
    "requiredDateStart": 1550482200000,
    "requiredDateEnd": 1550487600000,
    "orderID": 10420,
    "customerID": "WELLI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 44.12,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1441.375,
    "orderDate": 1548712800000,
    "requiredDate": 1552341600000,
    "shippedDate": 1549490400000,
    "requiredDateStart": 1552383000000,
    "requiredDateEnd": 1552384800000,
    "orderID": 10429,
    "customerID": "HUNGO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 56.63,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 485,
    "orderDate": 1548885600000,
    "requiredDate": 1550095200000,
    "shippedDate": 1549490400000,
    "requiredDateStart": 1550152800000,
    "requiredDateEnd": 1550158200000,
    "orderID": 10432,
    "customerID": "SPLIR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 4.34,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Princesa Isabel Vinhos",
    "customerContactName": "Isabel de Castro",
    "orderTotal": 851.2,
    "orderDate": 1549144800000,
    "requiredDate": 1551564000000,
    "shippedDate": 1551650400000,
    "requiredDateStart": 1551618000000,
    "requiredDateEnd": 1551619800000,
    "orderID": 10433,
    "customerID": "PRINI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 73.83,
    "shipName": "Princesa Isabel Vinhos",
    "shipAddress": "Estrada da saúde n. 58",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1756",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 321.12,
    "orderDate": 1549144800000,
    "requiredDate": 1551564000000,
    "shippedDate": 1550008800000,
    "requiredDateStart": 1551609000000,
    "requiredDateEnd": 1551612600000,
    "orderID": 10434,
    "customerID": "FOLKO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 17.92,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 1994.52,
    "orderDate": 1549317600000,
    "requiredDate": 1551736800000,
    "shippedDate": 1549836000000,
    "requiredDateStart": 1551787200000,
    "requiredDateEnd": 1551789000000,
    "orderID": 10436,
    "customerID": "BLONP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 156.66,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 454,
    "orderDate": 1549404000000,
    "requiredDate": 1551823200000,
    "shippedDate": 1550095200000,
    "requiredDateStart": 1551868200000,
    "requiredDateEnd": 1551870000000,
    "orderID": 10438,
    "customerID": "TOMSP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 8.24,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 1755,
    "orderDate": 1549749600000,
    "requiredDate": 1553378400000,
    "shippedDate": 1552514400000,
    "requiredDateStart": 1553434200000,
    "requiredDateEnd": 1553437800000,
    "orderID": 10441,
    "customerID": "OLDWO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 73.02,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1792,
    "orderDate": 1549836000000,
    "requiredDate": 1552255200000,
    "shippedDate": 1550440800000,
    "requiredDateStart": 1552302000000,
    "requiredDateEnd": 1552303800000,
    "orderID": 10442,
    "customerID": "ERNSH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 47.94,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1031.7,
    "orderDate": 1549922400000,
    "requiredDate": 1552341600000,
    "shippedDate": 1550700000000,
    "requiredDateStart": 1552388400000,
    "requiredDateEnd": 1552390200000,
    "orderID": 10444,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 3.5,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 174.9,
    "orderDate": 1550008800000,
    "requiredDate": 1552428000000,
    "shippedDate": 1550613600000,
    "requiredDateStart": 1552471200000,
    "requiredDateEnd": 1552476600000,
    "orderID": 10445,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 9.3,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 1838.2,
    "orderDate": 1550440800000,
    "requiredDate": 1552860000000,
    "shippedDate": 1551218400000,
    "requiredDateStart": 1552917600000,
    "requiredDateEnd": 1552923000000,
    "orderID": 10449,
    "customerID": "BLONP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 53.3,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 717.6,
    "orderDate": 1551909600000,
    "requiredDate": 1554325200000,
    "shippedDate": 1552341600000,
    "requiredDateStart": 1554364800000,
    "requiredDateEnd": 1554370200000,
    "orderID": 10468,
    "customerID": "KOENE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 44.12,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 10495.6,
    "orderDate": 1552946400000,
    "requiredDate": 1555362000000,
    "shippedDate": 1553119200000,
    "requiredDateStart": 1555412400000,
    "requiredDateEnd": 1555414200000,
    "orderID": 10479,
    "customerID": "RATTC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 708.95,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 386.2,
    "orderDate": 1553378400000,
    "requiredDate": 1555794000000,
    "shippedDate": 1554066000000,
    "requiredDateStart": 1555846200000,
    "requiredDateEnd": 1555848000000,
    "orderID": 10484,
    "customerID": "BSBEV",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 6.88,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 851.2,
    "orderDate": 1554066000000,
    "requiredDate": 1556485200000,
    "shippedDate": 1554930000000,
    "requiredDateStart": 1556533800000,
    "requiredDateEnd": 1556535600000,
    "orderID": 10492,
    "customerID": "BOTTM",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 62.89,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Laughing Bacchus Wine Cellars",
    "customerContactName": "Yoshi Tannamuri",
    "orderTotal": 278,
    "orderDate": 1554238800000,
    "requiredDate": 1556658000000,
    "shippedDate": 1554930000000,
    "requiredDateStart": 1556703000000,
    "requiredDateEnd": 1556708400000,
    "orderID": 10495,
    "customerID": "LAUGB",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 4.65,
    "shipName": "Laughing Bacchus Wine Cellars",
    "shipAddress": "2319 Elm St.",
    "shipCity": "Vancouver",
    "shipRegion": "BC",
    "shipPostalCode": "V3F 2K1",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 147.9,
    "orderDate": 1555189200000,
    "requiredDate": 1557608400000,
    "shippedDate": 1555794000000,
    "requiredDateStart": 1557649800000,
    "requiredDateEnd": 1557655200000,
    "orderID": 10505,
    "customerID": "MEREP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 7.13,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 8623.45,
    "orderDate": 1555880400000,
    "requiredDate": 1558299600000,
    "shippedDate": 1557954000000,
    "requiredDateStart": 1558359000000,
    "requiredDateEnd": 1558364400000,
    "orderID": 10514,
    "customerID": "ERNSH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 789.95,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "North/South",
    "customerContactName": "Simon Crowther",
    "orderTotal": 352,
    "orderDate": 1556053200000,
    "requiredDate": 1558472400000,
    "shippedDate": 1556485200000,
    "requiredDateStart": 1558530000000,
    "requiredDateEnd": 1558533600000,
    "orderID": 10517,
    "customerID": "NORTS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 32.07,
    "shipName": "North/South",
    "shipAddress": "South House 300 Queensbridge",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "SW7 1RZ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 4180,
    "orderDate": 1557262800000,
    "requiredDate": 1559682000000,
    "shippedDate": 1557608400000,
    "requiredDateStart": 1559730600000,
    "requiredDateEnd": 1559736000000,
    "orderID": 10530,
    "customerID": "PICCO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 339.22,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1645,
    "orderDate": 1557781200000,
    "requiredDate": 1560200400000,
    "shippedDate": 1559768400000,
    "requiredDateStart": 1560247200000,
    "requiredDateEnd": 1560252600000,
    "orderID": 10536,
    "customerID": "LEHMS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.88,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 10191.7,
    "orderDate": 1558213200000,
    "requiredDate": 1560632400000,
    "shippedDate": 1560373200000,
    "requiredDateStart": 1560684600000,
    "requiredDateEnd": 1560690000000,
    "orderID": 10540,
    "customerID": "QUICK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 1007.64,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 1792.8,
    "orderDate": 1558558800000,
    "requiredDate": 1560978000000,
    "shippedDate": 1559422800000,
    "requiredDateStart": 1561017600000,
    "requiredDateEnd": 1561021200000,
    "orderID": 10547,
    "customerID": "SEVES",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 178.43,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 240.1,
    "orderDate": 1558818000000,
    "requiredDate": 1561237200000,
    "shippedDate": 1559422800000,
    "requiredDateStart": 1561285800000,
    "requiredDateEnd": 1561287600000,
    "orderID": 10548,
    "customerID": "TOMSP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 1.43,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Galería del gastrónomo",
    "customerContactName": "Eduardo Saavedra",
    "orderTotal": 155,
    "orderDate": 1560373200000,
    "requiredDate": 1562792400000,
    "shippedDate": 1562619600000,
    "requiredDateStart": 1562846400000,
    "requiredDateEnd": 1562848200000,
    "orderID": 10568,
    "customerID": "GALED",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 6.54,
    "shipName": "Galería del gastronómo",
    "shipAddress": "Rambla de Cataluña, 23",
    "shipCity": "Barcelona",
    "shipRegion": "",
    "shipPostalCode": "8022",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 2465.25,
    "orderDate": 1560718800000,
    "requiredDate": 1563138000000,
    "shippedDate": 1560891600000,
    "requiredDateStart": 1563192000000,
    "requiredDateEnd": 1563195600000,
    "orderID": 10570,
    "customerID": "MEREP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 188.99,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1501.085,
    "orderDate": 1560805200000,
    "requiredDate": 1563224400000,
    "shippedDate": 1561410000000,
    "requiredDateStart": 1563280200000,
    "requiredDateEnd": 1563285600000,
    "orderID": 10572,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 116.43,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 838.45,
    "orderDate": 1561237200000,
    "requiredDate": 1562446800000,
    "shippedDate": 1561842000000,
    "requiredDateStart": 1562502600000,
    "requiredDateEnd": 1562504400000,
    "orderID": 10576,
    "customerID": "TORTU",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 18.56,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 310,
    "orderDate": 1561496400000,
    "requiredDate": 1563915600000,
    "shippedDate": 1562014800000,
    "requiredDateStart": 1563971400000,
    "requiredDateEnd": 1563975000000,
    "orderID": 10581,
    "customerID": "FAMIA",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 3.01,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Blauer See Delikatessen",
    "customerContactName": "Hanna Moos",
    "orderTotal": 330,
    "orderDate": 1561582800000,
    "requiredDate": 1564002000000,
    "shippedDate": 1563051600000,
    "requiredDateStart": 1564048800000,
    "requiredDateEnd": 1564054200000,
    "orderID": 10582,
    "customerID": "BLAUS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 27.71,
    "shipName": "Blauer See Delikatessen",
    "shipAddress": "Forsterstr. 57",
    "shipCity": "Mannheim",
    "shipRegion": "",
    "shipPostalCode": "68306",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 516.4675,
    "orderDate": 1562533200000,
    "requiredDate": 1564952400000,
    "shippedDate": 1563224400000,
    "requiredDateStart": 1565002800000,
    "requiredDateEnd": 1565004600000,
    "orderID": 10592,
    "customerID": "LEHMS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 32.1,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 565.5,
    "orderDate": 1562619600000,
    "requiredDate": 1565038800000,
    "shippedDate": 1563224400000,
    "requiredDateStart": 1565096400000,
    "requiredDateEnd": 1565100000000,
    "orderID": 10594,
    "customerID": "OLDWO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 5.24,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 1260,
    "orderDate": 1564866000000,
    "requiredDate": 1567285200000,
    "shippedDate": 1565125200000,
    "requiredDateStart": 1567341000000,
    "requiredDateEnd": 1567342800000,
    "orderID": 10619,
    "customerID": "MEREP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 91.05,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Ana Trujillo Emparedados y helados",
    "customerContactName": "Ana Trujillo",
    "orderTotal": 479.75,
    "orderDate": 1565211600000,
    "requiredDate": 1567630800000,
    "shippedDate": 1565730000000,
    "requiredDateStart": 1567677600000,
    "requiredDateEnd": 1567679400000,
    "orderID": 10625,
    "customerID": "ANATR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 43.9,
    "shipName": "Ana Trujillo Emparedados y helados",
    "shipAddress": "Avda. de la Constitución 2222",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5021",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 2720.05,
    "orderDate": 1566248400000,
    "requiredDate": 1568667600000,
    "shippedDate": 1567285200000,
    "requiredDateStart": 1568709000000,
    "requiredDateEnd": 1568714400000,
    "orderID": 10638,
    "customerID": "LINOD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 158.44,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 1371.8,
    "orderDate": 1566680400000,
    "requiredDate": 1569099600000,
    "shippedDate": 1567285200000,
    "requiredDateStart": 1569146400000,
    "requiredDateEnd": 1569148200000,
    "orderID": 10644,
    "customerID": "WELLI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 0.14,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 125,
    "orderDate": 1567976400000,
    "requiredDate": 1570395600000,
    "shippedDate": 1568754000000,
    "requiredDateStart": 1570451400000,
    "requiredDateEnd": 1570456800000,
    "orderID": 10662,
    "customerID": "LONEP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 1.28,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 1287.4,
    "orderDate": 1569358800000,
    "requiredDate": 1571778000000,
    "shippedDate": 1569790800000,
    "requiredDateStart": 1571824800000,
    "requiredDateEnd": 1571830200000,
    "orderID": 10681,
    "customerID": "GREAL",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 76.13,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 375.5,
    "orderDate": 1569358800000,
    "requiredDate": 1571778000000,
    "shippedDate": 1569877200000,
    "requiredDateStart": 1571830200000,
    "requiredDateEnd": 1571833800000,
    "orderID": 10682,
    "customerID": "ANTON",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 36.13,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1768,
    "orderDate": 1569445200000,
    "requiredDate": 1571864400000,
    "shippedDate": 1569790800000,
    "requiredDateStart": 1571909400000,
    "requiredDateEnd": 1571914800000,
    "orderID": 10684,
    "customerID": "OTTIK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 145.63,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 2071.2,
    "orderDate": 1570309200000,
    "requiredDate": 1571518800000,
    "shippedDate": 1570654800000,
    "requiredDateStart": 1571567400000,
    "requiredDateEnd": 1571572800000,
    "orderID": 10693,
    "customerID": "WHITC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 139.34,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 805.425,
    "orderDate": 1570482000000,
    "requiredDate": 1572904800000,
    "shippedDate": 1571000400000,
    "requiredDateStart": 1572944400000,
    "requiredDateEnd": 1572948000000,
    "orderID": 10697,
    "customerID": "LINOD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 45.52,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Morgenstern Gesundkost",
    "customerContactName": "Alexander Feuer",
    "orderTotal": 114,
    "orderDate": 1570568400000,
    "requiredDate": 1572991200000,
    "shippedDate": 1570914000000,
    "requiredDateStart": 1573036200000,
    "requiredDateEnd": 1573039800000,
    "orderID": 10699,
    "customerID": "MORGK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 0.58,
    "shipName": "Morgenstern Gesundkost",
    "shipAddress": "Heerstr. 22",
    "shipCity": "Leipzig",
    "shipRegion": "",
    "shipPostalCode": "4179",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1638.4,
    "orderDate": 1570654800000,
    "requiredDate": 1573077600000,
    "shippedDate": 1571173200000,
    "requiredDateStart": 1573128000000,
    "requiredDateEnd": 1573131600000,
    "orderID": 10700,
    "customerID": "SAVEA",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 65.1,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1233.48,
    "orderDate": 1571605200000,
    "requiredDate": 1574028000000,
    "shippedDate": 1572472800000,
    "requiredDateStart": 1574067600000,
    "requiredDateEnd": 1574073000000,
    "orderID": 10712,
    "customerID": "HUNGO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 89.93,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1296,
    "orderDate": 1571778000000,
    "requiredDate": 1572991200000,
    "shippedDate": 1572300000000,
    "requiredDateStart": 1573030800000,
    "requiredDateEnd": 1573032600000,
    "orderID": 10715,
    "customerID": "BONAP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 63.2,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 468.45,
    "orderDate": 1572386400000,
    "requiredDate": 1574805600000,
    "shippedDate": 1574632800000,
    "requiredDateStart": 1574856000000,
    "requiredDateEnd": 1574859600000,
    "orderID": 10723,
    "customerID": "WHITC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 21.72,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 360,
    "orderDate": 1572991200000,
    "requiredDate": 1575410400000,
    "shippedDate": 1573077600000,
    "requiredDateStart": 1575469800000,
    "requiredDateEnd": 1575471600000,
    "orderID": 10732,
    "customerID": "BONAP",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 16.97,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Vins et alcools Chevalier",
    "customerContactName": "Paul Henriot",
    "orderTotal": 240,
    "orderDate": 1573509600000,
    "requiredDate": 1575928800000,
    "shippedDate": 1573941600000,
    "requiredDateStart": 1575973800000,
    "requiredDateEnd": 1575979200000,
    "orderID": 10739,
    "customerID": "VINET",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 11.08,
    "shipName": "Vins et alcools Chevalier",
    "shipAddress": "59 rue de l-Abbaye",
    "shipCity": "Reims",
    "shipRegion": "",
    "shipPostalCode": "51100",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 3118,
    "orderDate": 1573682400000,
    "requiredDate": 1576101600000,
    "shippedDate": 1574028000000,
    "requiredDateStart": 1576150200000,
    "requiredDateEnd": 1576153800000,
    "orderID": 10742,
    "customerID": "BOTTM",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 243.73,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2196,
    "orderDate": 1574200800000,
    "requiredDate": 1576620000000,
    "shippedDate": 1574892000000,
    "requiredDateStart": 1576670400000,
    "requiredDateEnd": 1576675800000,
    "orderID": 10748,
    "customerID": "SAVEA",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 232.55,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 1631.484,
    "orderDate": 1574546400000,
    "requiredDate": 1576965600000,
    "shippedDate": 1575324000000,
    "requiredDateStart": 1577007000000,
    "requiredDateEnd": 1577010600000,
    "orderID": 10751,
    "customerID": "RICSU",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 130.79,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 88,
    "orderDate": 1574632800000,
    "requiredDate": 1577052000000,
    "shippedDate": 1574805600000,
    "requiredDateStart": 1577093400000,
    "requiredDateEnd": 1577095200000,
    "orderID": 10753,
    "customerID": "FRANS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 7.7,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 1644.6,
    "orderDate": 1574892000000,
    "requiredDate": 1577311200000,
    "shippedDate": 1575410400000,
    "requiredDateStart": 1577352600000,
    "requiredDateEnd": 1577354400000,
    "orderID": 10758,
    "customerID": "RICSU",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 138.17,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Ana Trujillo Emparedados y helados",
    "customerContactName": "Ana Trujillo",
    "orderTotal": 320,
    "orderDate": 1574892000000,
    "requiredDate": 1577311200000,
    "shippedDate": 1576101600000,
    "requiredDateStart": 1577368800000,
    "requiredDateEnd": 1577374200000,
    "orderID": 10759,
    "customerID": "ANATR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 11.99,
    "shipName": "Ana Trujillo Emparedados y helados",
    "shipAddress": "Avda. de la Constitución 2222",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5021",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 4337,
    "orderDate": 1575237600000,
    "requiredDate": 1577656800000,
    "shippedDate": 1575842400000,
    "requiredDateStart": 1577710800000,
    "requiredDateEnd": 1577712600000,
    "orderID": 10762,
    "customerID": "FOLKO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 328.74,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Folies gourmandes",
    "customerContactName": "Martine Rancé",
    "orderTotal": 616,
    "orderDate": 1575324000000,
    "requiredDate": 1577743200000,
    "shippedDate": 1575756000000,
    "requiredDateStart": 1577799000000,
    "requiredDateEnd": 1577800800000,
    "orderID": 10763,
    "customerID": "FOLIG",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 37.35,
    "shipName": "Folies gourmandes",
    "shipAddress": "184, chaussée de Tournai",
    "shipCity": "Lille",
    "shipRegion": "",
    "shipPostalCode": "59000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 1515.6,
    "orderDate": 1575410400000,
    "requiredDate": 1577829600000,
    "shippedDate": 1575842400000,
    "requiredDateStart": 1577869200000,
    "requiredDateEnd": 1577872800000,
    "orderID": 10765,
    "customerID": "QUICK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 42.74,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 1477,
    "orderDate": 1575756000000,
    "requiredDate": 1578175200000,
    "shippedDate": 1576360800000,
    "requiredDateStart": 1578231000000,
    "requiredDateEnd": 1578232800000,
    "orderID": 10768,
    "customerID": "AROUT",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 146.32,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 1684.275,
    "orderDate": 1575756000000,
    "requiredDate": 1578175200000,
    "shippedDate": 1576101600000,
    "requiredDateStart": 1578223800000,
    "requiredDateEnd": 1578225600000,
    "orderID": 10769,
    "customerID": "VAFFE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 65.06,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 3603.22,
    "orderDate": 1575928800000,
    "requiredDate": 1578348000000,
    "shippedDate": 1576706400000,
    "requiredDateStart": 1578387600000,
    "requiredDateEnd": 1578393000000,
    "orderID": 10772,
    "customerID": "LEHMS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 91.28,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 96.5,
    "orderDate": 1576447200000,
    "requiredDate": 1578866400000,
    "shippedDate": 1577138400000,
    "requiredDateStart": 1578913200000,
    "requiredDateEnd": 1578915000000,
    "orderID": 10778,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 6.79,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Morgenstern Gesundkost",
    "customerContactName": "Alexander Feuer",
    "orderTotal": 1335,
    "orderDate": 1576447200000,
    "requiredDate": 1578866400000,
    "shippedDate": 1578952800000,
    "requiredDateStart": 1578913200000,
    "requiredDateEnd": 1578918600000,
    "orderID": 10779,
    "customerID": "MORGK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.13,
    "shipName": "Morgenstern Gesundkost",
    "shipAddress": "Heerstr. 22",
    "shipCity": "Leipzig",
    "shipRegion": "",
    "shipPostalCode": "4179",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 191.1,
    "orderDate": 1577138400000,
    "requiredDate": 1579557600000,
    "shippedDate": 1578434400000,
    "requiredDateStart": 1579613400000,
    "requiredDateEnd": 1579617000000,
    "orderID": 10793,
    "customerID": "AROUT",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 4.52,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 2341.364,
    "orderDate": 1577224800000,
    "requiredDate": 1579644000000,
    "shippedDate": 1578952800000,
    "requiredDateStart": 1579689000000,
    "requiredDateEnd": 1579692600000,
    "orderID": 10796,
    "customerID": "HILAA",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 26.52,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 439.6,
    "orderDate": 1577743200000,
    "requiredDate": 1580162400000,
    "shippedDate": 1578175200000,
    "requiredDateStart": 1580209200000,
    "requiredDateEnd": 1580212800000,
    "orderID": 10806,
    "customerID": "VICTE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 22.11,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 1788.45,
    "orderDate": 1578175200000,
    "requiredDate": 1580594400000,
    "shippedDate": 1578952800000,
    "requiredDateStart": 1580639400000,
    "requiredDateEnd": 1580644800000,
    "orderID": 10814,
    "customerID": "VICTE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 130.94,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 10952.845,
    "orderDate": 1578261600000,
    "requiredDate": 1579471200000,
    "shippedDate": 1578866400000,
    "requiredDateStart": 1579516200000,
    "requiredDateEnd": 1579521600000,
    "orderID": 10817,
    "customerID": "KOENE",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 306.07,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1140,
    "orderDate": 1578348000000,
    "requiredDate": 1580767200000,
    "shippedDate": 1578866400000,
    "requiredDateStart": 1580810400000,
    "requiredDateEnd": 1580812200000,
    "orderID": 10820,
    "customerID": "RATTC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 37.52,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 2684.4,
    "orderDate": 1578952800000,
    "requiredDate": 1581372000000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1581426000000,
    "requiredDateEnd": 1581427800000,
    "orderID": 10831,
    "customerID": "SANTG",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 72.19,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 1938.375,
    "orderDate": 1579384800000,
    "requiredDate": 1581804000000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1581852600000,
    "requiredDateEnd": 1581854400000,
    "orderID": 10838,
    "customerID": "LINOD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 59.28,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 827.55,
    "orderDate": 1579384800000,
    "requiredDate": 1581804000000,
    "shippedDate": 1579644000000,
    "requiredDateStart": 1581861600000,
    "requiredDateEnd": 1581865200000,
    "orderID": 10839,
    "customerID": "TRADH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 35.43,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2966.5,
    "orderDate": 1580076000000,
    "requiredDate": 1582495200000,
    "shippedDate": 1580853600000,
    "requiredDateStart": 1582536600000,
    "requiredDateEnd": 1582542000000,
    "orderID": 10854,
    "customerID": "ERNSH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 100.22,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 2227.8875,
    "orderDate": 1580076000000,
    "requiredDate": 1582495200000,
    "shippedDate": 1580767200000,
    "requiredDateStart": 1582551000000,
    "requiredDateEnd": 1582556400000,
    "orderID": 10855,
    "customerID": "OLDWO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 170.97,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 660,
    "orderDate": 1580162400000,
    "requiredDate": 1582581600000,
    "shippedDate": 1581285600000,
    "requiredDateStart": 1582626600000,
    "requiredDateEnd": 1582628400000,
    "orderID": 10856,
    "customerID": "ANTON",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 58.43,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "France restauration",
    "customerContactName": "Carine Schmitt",
    "orderTotal": 519,
    "orderDate": 1580248800000,
    "requiredDate": 1582668000000,
    "shippedDate": 1580767200000,
    "requiredDateStart": 1582727400000,
    "requiredDateEnd": 1582731000000,
    "orderID": 10860,
    "customerID": "FRANR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 19.26,
    "shipName": "France restauration",
    "shipAddress": "54, rue Royale",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 611.3,
    "orderDate": 1581285600000,
    "requiredDate": 1583791200000,
    "shippedDate": 1581458400000,
    "requiredDateStart": 1583838000000,
    "requiredDateEnd": 1583839800000,
    "orderID": 10879,
    "customerID": "WILMK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 8.5,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 6379.4,
    "orderDate": 1581976800000,
    "requiredDate": 1584482400000,
    "shippedDate": 1582408800000,
    "requiredDateStart": 1584540000000,
    "requiredDateEnd": 1584543600000,
    "orderID": 10895,
    "customerID": "ERNSH",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 162.75,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 10835.24,
    "orderDate": 1582063200000,
    "requiredDate": 1584568800000,
    "shippedDate": 1582581600000,
    "requiredDateStart": 1584628200000,
    "requiredDateEnd": 1584631800000,
    "orderID": 10897,
    "customerID": "HUNGO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 603.54,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 932.05,
    "orderDate": 1582495200000,
    "requiredDate": 1585000800000,
    "shippedDate": 1583272800000,
    "requiredDateStart": 1585047600000,
    "requiredDateEnd": 1585049400000,
    "orderID": 10903,
    "customerID": "HANAR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 36.71,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 1924.25,
    "orderDate": 1582495200000,
    "requiredDate": 1585000800000,
    "shippedDate": 1582754400000,
    "requiredDateStart": 1585056600000,
    "requiredDateEnd": 1585060200000,
    "orderID": 10904,
    "customerID": "WHITC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 162.95,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 858,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1583359200000,
    "requiredDateStart": 1585218600000,
    "requiredDateEnd": 1585222200000,
    "orderID": 10911,
    "customerID": "GODOS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 38.19,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1447.5,
    "orderDate": 1583100000000,
    "requiredDate": 1585515600000,
    "shippedDate": 1583877600000,
    "requiredDateStart": 1585555200000,
    "requiredDateEnd": 1585560600000,
    "orderID": 10918,
    "customerID": "BOTTM",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 48.83,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1835.7,
    "orderDate": 1583272800000,
    "requiredDate": 1585688400000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1585738800000,
    "requiredDateEnd": 1585744200000,
    "orderID": 10924,
    "customerID": "BERGS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 151.52,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 475.15,
    "orderDate": 1583272800000,
    "requiredDate": 1585688400000,
    "shippedDate": 1584050400000,
    "requiredDateStart": 1585735200000,
    "requiredDateEnd": 1585740600000,
    "orderID": 10925,
    "customerID": "HANAR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 2.27,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 500,
    "orderDate": 1583704800000,
    "requiredDate": 1586120400000,
    "shippedDate": 1583964000000,
    "requiredDateStart": 1586161800000,
    "requiredDateEnd": 1586163600000,
    "orderID": 10934,
    "customerID": "LEHMS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 32.01,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 456,
    "orderDate": 1583704800000,
    "requiredDate": 1586120400000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1586176200000,
    "requiredDateEnd": 1586181600000,
    "orderID": 10936,
    "customerID": "GREAL",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 33.68,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 2731.875,
    "orderDate": 1583791200000,
    "requiredDate": 1586206800000,
    "shippedDate": 1584309600000,
    "requiredDateStart": 1586253600000,
    "requiredDateEnd": 1586259000000,
    "orderID": 10938,
    "customerID": "QUICK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 31.89,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 220,
    "orderDate": 1584050400000,
    "requiredDate": 1586466000000,
    "shippedDate": 1584309600000,
    "requiredDateStart": 1586525400000,
    "requiredDateEnd": 1586527200000,
    "orderID": 10947,
    "customerID": "BSBEV",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 3.26,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 2362.25,
    "orderDate": 1584050400000,
    "requiredDate": 1586466000000,
    "shippedDate": 1584568800000,
    "requiredDateStart": 1586505600000,
    "requiredDateEnd": 1586509200000,
    "orderID": 10948,
    "customerID": "GODOS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 23.39,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 265.35,
    "orderDate": 1584568800000,
    "requiredDate": 1585774800000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1585830600000,
    "requiredDateEnd": 1585834200000,
    "orderID": 10960,
    "customerID": "HILAA",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 2.08,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Spécialités du monde",
    "customerContactName": "Dominique Perrier",
    "orderTotal": 2052.5,
    "orderDate": 1584655200000,
    "requiredDate": 1587070800000,
    "shippedDate": 1585000800000,
    "requiredDateStart": 1587114000000,
    "requiredDateEnd": 1587117600000,
    "orderID": 10964,
    "customerID": "SPECD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 87.38,
    "shipName": "Spécialités du monde",
    "shipAddress": "25, rue Lauriston",
    "shipCity": "Paris",
    "shipRegion": "",
    "shipPostalCode": "75016",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 439,
    "orderDate": 1585087200000,
    "requiredDate": 1586293200000,
    "shippedDate": 1585861200000,
    "requiredDateStart": 1586349000000,
    "requiredDateEnd": 1586354400000,
    "orderID": 10974,
    "customerID": "SPLIR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 12.96,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 3574.8,
    "orderDate": 1585602000000,
    "requiredDate": 1588021200000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588075200000,
    "requiredDateEnd": 1588080600000,
    "orderID": 10988,
    "customerID": "RATTC",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 61.14,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "The Cracker Box",
    "customerContactName": "Liu Wong",
    "orderTotal": 326,
    "orderDate": 1586120400000,
    "requiredDate": 1588539600000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1588591800000,
    "requiredDateEnd": 1588593600000,
    "orderID": 11003,
    "customerID": "THECR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 14.91,
    "shipName": "The Cracker Box",
    "shipAddress": "55 Grizzly Peak Rd.",
    "shipCity": "Butte",
    "shipRegion": "MT",
    "shipPostalCode": "59801",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Maison Dewey",
    "customerContactName": "Catherine Dewey",
    "orderTotal": 295.38,
    "orderDate": 1586206800000,
    "requiredDate": 1588626000000,
    "shippedDate": 1587330000000,
    "requiredDateStart": 1588671000000,
    "requiredDateEnd": 1588674600000,
    "orderID": 11004,
    "customerID": "MAISD",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 44.84,
    "shipName": "Maison Dewey",
    "shipAddress": "Rue Joseph-Bens 532",
    "shipCity": "Bruxelles",
    "shipRegion": "",
    "shipPostalCode": "B-1180",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 329.685,
    "orderDate": 1586206800000,
    "requiredDate": 1588626000000,
    "shippedDate": 1586898000000,
    "requiredDateStart": 1588680000000,
    "requiredDateEnd": 1588681800000,
    "orderID": 11006,
    "customerID": "GREAL",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 25.19,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 933.5,
    "orderDate": 1586379600000,
    "requiredDate": 1588798800000,
    "shippedDate": 1586725200000,
    "requiredDateStart": 1588847400000,
    "requiredDateEnd": 1588849200000,
    "orderID": 11011,
    "customerID": "ALFKI",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 1.21,
    "shipName": "Alfred-s Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 6306.24,
    "orderDate": 1586811600000,
    "requiredDate": 1589230800000,
    "shippedDate": 1587416400000,
    "requiredDateStart": 1589284800000,
    "requiredDateEnd": 1589288400000,
    "orderID": 11021,
    "customerID": "QUICK",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 297.18,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 1773,
    "orderDate": 1587502800000,
    "requiredDate": 1589922000000,
    "shippedDate": 1588021200000,
    "requiredDateStart": 1589968800000,
    "requiredDateEnd": 1589970600000,
    "orderID": 11041,
    "customerID": "CHOPS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 48.22,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 273.6,
    "orderDate": 1587675600000,
    "requiredDate": 1590094800000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1590141600000,
    "requiredDateEnd": 1590145200000,
    "orderID": 11049,
    "customerID": "GOURL",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 8.34,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1332,
    "orderDate": 1587934800000,
    "requiredDate": 1590354000000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1590395400000,
    "requiredDateEnd": 1590399000000,
    "orderID": 11052,
    "customerID": "HANAR",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 1,
    "freight": 67.26,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "North/South",
    "customerContactName": "Simon Crowther",
    "orderTotal": 45,
    "orderDate": 1588107600000,
    "requiredDate": 1590526800000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1590577200000,
    "requiredDateEnd": 1590582600000,
    "orderID": 11057,
    "customerID": "NORTS",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 3,
    "freight": 4.13,
    "shipName": "North/South",
    "shipAddress": "South House 300 Queensbridge",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "SW7 1RZ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 1342.95,
    "orderDate": 1588194000000,
    "requiredDate": 1590613200000,
    "shippedDate": 1588712400000,
    "requiredDateStart": 1590654600000,
    "requiredDateEnd": 1590656400000,
    "orderID": 11063,
    "customerID": "HUNGO",
    "employeeID": "c8389213-a2cd-4bb8-ba99-9c29e9b70881",
    "teamID": 2,
    "shipVia": 2,
    "freight": 81.73,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Blondesddsl père et fils",
    "customerContactName": "Frédérique Citeaux",
    "orderTotal": 1176,
    "orderDate": 1532466000000,
    "requiredDate": 1534885200000,
    "shippedDate": 1534021200000,
    "requiredDateStart": 1534933800000,
    "requiredDateEnd": 1534937400000,
    "orderID": 10265,
    "customerID": "BLONP",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 55.28,
    "shipName": "Blondel père et fils",
    "shipAddress": "24, place Kléber",
    "shipCity": "Strasbourg",
    "shipRegion": "",
    "shipPostalCode": "67000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Morgenstern Gesundkost",
    "customerContactName": "Alexander Feuer",
    "orderTotal": 1200.8,
    "orderDate": 1533762000000,
    "requiredDate": 1536181200000,
    "shippedDate": 1534107600000,
    "requiredDateStart": 1536231600000,
    "requiredDateEnd": 1536233400000,
    "orderID": 10277,
    "customerID": "MORGK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 125.77,
    "shipName": "Morgenstern Gesundkost",
    "shipAddress": "Heerstr. 22",
    "shipCity": "Leipzig",
    "shipRegion": "",
    "shipPostalCode": "4179",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 613.2,
    "orderDate": 1534194000000,
    "requiredDate": 1536613200000,
    "shippedDate": 1536699600000,
    "requiredDateStart": 1536652800000,
    "requiredDateEnd": 1536656400000,
    "orderID": 10280,
    "customerID": "BERGS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 8.98,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Vins et alcools Chevalier",
    "customerContactName": "Paul Henriot",
    "orderTotal": 121.6,
    "orderDate": 1535835600000,
    "requiredDate": 1538254800000,
    "shippedDate": 1536526800000,
    "requiredDateStart": 1538296200000,
    "requiredDateEnd": 1538299800000,
    "orderID": 10295,
    "customerID": "VINET",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 1.15,
    "shipName": "Vins et alcools Chevalier",
    "shipAddress": "59 rue de l-Abbaye",
    "shipCity": "Reims",
    "shipRegion": "",
    "shipPostalCode": "51100",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 608,
    "orderDate": 1536440400000,
    "requiredDate": 1538859600000,
    "shippedDate": 1537218000000,
    "requiredDateStart": 1538908200000,
    "requiredDateEnd": 1538913600000,
    "orderID": 10300,
    "customerID": "MAGAA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 17.68,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 424,
    "orderDate": 1537131600000,
    "requiredDate": 1539550800000,
    "shippedDate": 1537822800000,
    "requiredDateStart": 1539604800000,
    "requiredDateEnd": 1539606600000,
    "orderID": 10307,
    "customerID": "LONEP",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 0.56,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 1614.8,
    "orderDate": 1537650000000,
    "requiredDate": 1540069200000,
    "shippedDate": 1538514000000,
    "requiredDateStart": 1540116000000,
    "requiredDateEnd": 1540119600000,
    "orderID": 10312,
    "customerID": "WANDK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 40.26,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 182.4,
    "orderDate": 1537736400000,
    "requiredDate": 1540155600000,
    "shippedDate": 1538600400000,
    "requiredDateStart": 1540195200000,
    "requiredDateEnd": 1540200600000,
    "orderID": 10313,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 1.96,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 1810,
    "orderDate": 1539205200000,
    "requiredDate": 1541628000000,
    "shippedDate": 1539464400000,
    "requiredDateStart": 1541673000000,
    "requiredDateEnd": 1541674800000,
    "orderID": 10327,
    "customerID": "FOLKO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 63.36,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 3354,
    "orderDate": 1540674000000,
    "requiredDate": 1543096800000,
    "shippedDate": 1541282400000,
    "requiredDateStart": 1543140000000,
    "requiredDateEnd": 1543141800000,
    "orderID": 10339,
    "customerID": "MEREP",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 15.66,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 2924.8,
    "orderDate": 1541282400000,
    "requiredDate": 1543701600000,
    "shippedDate": 1541887200000,
    "requiredDateStart": 1543761000000,
    "requiredDateEnd": 1543766400000,
    "orderID": 10345,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 249.06,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1689.78,
    "orderDate": 1543442400000,
    "requiredDate": 1545861600000,
    "shippedDate": 1543701600000,
    "requiredDateStart": 1545906600000,
    "requiredDateEnd": 1545912000000,
    "orderID": 10368,
    "customerID": "ERNSH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 101.95,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 863.28,
    "orderDate": 1544479200000,
    "requiredDate": 1546898400000,
    "shippedDate": 1544652000000,
    "requiredDateStart": 1546956000000,
    "requiredDateEnd": 1546961400000,
    "orderID": 10379,
    "customerID": "QUEDE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 45.03,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 1228.8,
    "orderDate": 1545170400000,
    "requiredDate": 1547589600000,
    "shippedDate": 1545256800000,
    "requiredDateStart": 1547641800000,
    "requiredDateEnd": 1547647200000,
    "orderID": 10388,
    "customerID": "SEVES",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 34.86,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 1440,
    "orderDate": 1545602400000,
    "requiredDate": 1548021600000,
    "shippedDate": 1546293600000,
    "requiredDateStart": 1548063000000,
    "requiredDateEnd": 1548066600000,
    "orderID": 10392,
    "customerID": "PICCO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 122.46,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2505.6,
    "orderDate": 1546120800000,
    "requiredDate": 1548540000000,
    "shippedDate": 1546984800000,
    "requiredDateStart": 1548579600000,
    "requiredDateEnd": 1548585000000,
    "orderID": 10398,
    "customerID": "SAVEA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 89.16,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 1591.25,
    "orderDate": 1546466400000,
    "requiredDate": 1548885600000,
    "shippedDate": 1546898400000,
    "requiredDateStart": 1548928800000,
    "requiredDateEnd": 1548932400000,
    "orderID": 10404,
    "customerID": "MAGAA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 155.97,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 1194,
    "orderDate": 1546812000000,
    "requiredDate": 1549231200000,
    "shippedDate": 1548799200000,
    "requiredDateStart": 1549285200000,
    "requiredDateEnd": 1549288800000,
    "orderID": 10407,
    "customerID": "OTTIK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 91.48,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Familia Arquibaldo",
    "customerContactName": "Aria Cruz",
    "orderTotal": 224.83,
    "orderDate": 1547416800000,
    "requiredDate": 1549836000000,
    "shippedDate": 1547676000000,
    "requiredDateStart": 1549879200000,
    "requiredDateEnd": 1549881000000,
    "orderID": 10414,
    "customerID": "FAMIA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 21.48,
    "shipName": "Familia Arquibaldo",
    "shipAddress": "Rua Orós, 92",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05442-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 49.8,
    "orderDate": 1548108000000,
    "requiredDate": 1550527200000,
    "shippedDate": 1548885600000,
    "requiredDateStart": 1550583000000,
    "requiredDateEnd": 1550586600000,
    "orderID": 10422,
    "customerID": "FRANS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 3.02,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 1584,
    "orderDate": 1551045600000,
    "requiredDate": 1553464800000,
    "shippedDate": 1551564000000,
    "requiredDateStart": 1553513400000,
    "requiredDateEnd": 1553517000000,
    "orderID": 10457,
    "customerID": "KOENE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 11.57,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Consolidated Holdings",
    "customerContactName": "Elizabeth Brown",
    "orderTotal": 156,
    "orderDate": 1551564000000,
    "requiredDate": 1553983200000,
    "shippedDate": 1552860000000,
    "requiredDateStart": 1554042600000,
    "requiredDateEnd": 1554046200000,
    "orderID": 10462,
    "customerID": "CONSH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 6.17,
    "shipName": "Consolidated Holdings",
    "shipAddress": "Berkeley Gardens 12  Brewery",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX1 6LT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 1328,
    "orderDate": 1552255200000,
    "requiredDate": 1554670800000,
    "shippedDate": 1552860000000,
    "requiredDateStart": 1554715800000,
    "requiredDateEnd": 1554721200000,
    "orderID": 10471,
    "customerID": "BSBEV",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 45.59,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 471.2,
    "orderDate": 1552860000000,
    "requiredDate": 1554066000000,
    "shippedDate": 1553551200000,
    "requiredDateStart": 1554116400000,
    "requiredDateEnd": 1554120000000,
    "orderID": 10478,
    "customerID": "VICTE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 4.81,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Queen Cozinha",
    "customerContactName": "Lúcia Carvalho",
    "orderTotal": 889.7,
    "orderDate": 1553551200000,
    "requiredDate": 1555966800000,
    "shippedDate": 1553724000000,
    "requiredDateStart": 1556006400000,
    "requiredDateEnd": 1556010000000,
    "orderID": 10487,
    "customerID": "QUEEN",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 71.07,
    "shipName": "Queen Cozinha",
    "shipAddress": "Alameda dos Canàrios, 891",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05487-020",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 816.3,
    "orderDate": 1554843600000,
    "requiredDate": 1557262800000,
    "shippedDate": 1556485200000,
    "requiredDateStart": 1557313200000,
    "requiredDateEnd": 1557315000000,
    "orderID": 10502,
    "customerID": "PERIC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 69.32,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 9921.3,
    "orderDate": 1555966800000,
    "requiredDate": 1557176400000,
    "shippedDate": 1558558800000,
    "requiredDateStart": 1557217800000,
    "requiredDateEnd": 1557221400000,
    "orderID": 10515,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 204.47,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2381.05,
    "orderDate": 1556053200000,
    "requiredDate": 1558472400000,
    "shippedDate": 1556658000000,
    "requiredDateStart": 1558513800000,
    "requiredDateEnd": 1558519200000,
    "orderID": 10516,
    "customerID": "HUNGO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 62.78,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 1946.52,
    "orderDate": 1558213200000,
    "requiredDate": 1560632400000,
    "shippedDate": 1559077200000,
    "requiredDateStart": 1560684600000,
    "requiredDateEnd": 1560688200000,
    "orderID": 10541,
    "customerID": "HANAR",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 68.65,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 880.5,
    "orderDate": 1559077200000,
    "requiredDate": 1561496400000,
    "shippedDate": 1559682000000,
    "requiredDateStart": 1561543200000,
    "requiredDateEnd": 1561548600000,
    "orderID": 10552,
    "customerID": "HILAA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 83.22,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 1546.3,
    "orderDate": 1559163600000,
    "requiredDate": 1561582800000,
    "shippedDate": 1559509200000,
    "requiredDateStart": 1561627800000,
    "requiredDateEnd": 1561629600000,
    "orderID": 10553,
    "customerID": "WARTH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 149.49,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 835.2,
    "orderDate": 1559509200000,
    "requiredDate": 1563138000000,
    "shippedDate": 1560373200000,
    "requiredDateStart": 1563177600000,
    "requiredDateEnd": 1563181200000,
    "orderID": 10556,
    "customerID": "SIMOB",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 9.8,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 2844.5,
    "orderDate": 1559768400000,
    "requiredDate": 1562187600000,
    "shippedDate": 1560027600000,
    "requiredDateStart": 1562230800000,
    "requiredDateEnd": 1562232600000,
    "orderID": 10561,
    "customerID": "FOLKO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 242.21,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 965,
    "orderDate": 1560114000000,
    "requiredDate": 1563742800000,
    "shippedDate": 1561323600000,
    "requiredDateStart": 1563789600000,
    "requiredDateEnd": 1563791400000,
    "orderID": 10563,
    "customerID": "RICAR",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 60.43,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 2237.5,
    "orderDate": 1561842000000,
    "requiredDate": 1564261200000,
    "shippedDate": 1562187600000,
    "requiredDateStart": 1564315200000,
    "requiredDateEnd": 1564317000000,
    "orderID": 10583,
    "customerID": "WARTH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 7.28,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 3120,
    "orderDate": 1562101200000,
    "requiredDate": 1564520400000,
    "shippedDate": 1562706000000,
    "requiredDateStart": 1564578000000,
    "requiredDateEnd": 1564583400000,
    "orderID": 10588,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 194.67,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4725,
    "orderDate": 1562706000000,
    "requiredDate": 1565125200000,
    "shippedDate": 1563051600000,
    "requiredDateStart": 1565181000000,
    "requiredDateEnd": 1565182800000,
    "orderID": 10595,
    "customerID": "ERNSH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 96.78,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 120,
    "orderDate": 1564434000000,
    "requiredDate": 1566853200000,
    "shippedDate": 1565038800000,
    "requiredDateStart": 1566900000000,
    "requiredDateEnd": 1566901800000,
    "orderID": 10615,
    "customerID": "WILMK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 0.75,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Laughing Bacchus Wine Cellars",
    "customerContactName": "Yoshi Tannamuri",
    "orderTotal": 57.5,
    "orderDate": 1564952400000,
    "requiredDate": 1567371600000,
    "shippedDate": 1565730000000,
    "requiredDateStart": 1567422000000,
    "requiredDateEnd": 1567423800000,
    "orderID": 10620,
    "customerID": "LAUGB",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 0.94,
    "shipName": "Laughing Bacchus Wine Cellars",
    "shipAddress": "2319 Elm St.",
    "shipCity": "Vancouver",
    "shipRegion": "BC",
    "shipPostalCode": "V3F 2K1",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4371.6,
    "orderDate": 1567544400000,
    "requiredDate": 1569963600000,
    "shippedDate": 1568494800000,
    "requiredDateStart": 1570012200000,
    "requiredDateEnd": 1570015800000,
    "orderID": 10657,
    "customerID": "SAVEA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 352.69,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 1930.4,
    "orderDate": 1568062800000,
    "requiredDate": 1569272400000,
    "shippedDate": 1570050000000,
    "requiredDateStart": 1569328200000,
    "requiredDateEnd": 1569333600000,
    "orderID": 10663,
    "customerID": "BONAP",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 113.15,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Simons bistro",
    "customerContactName": "Jytte Petersen",
    "orderTotal": 570,
    "orderDate": 1568494800000,
    "requiredDate": 1570914000000,
    "shippedDate": 1569099600000,
    "requiredDateStart": 1570966200000,
    "requiredDateEnd": 1570968000000,
    "orderID": 10669,
    "customerID": "SIMOB",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 24.39,
    "shipName": "Simons bistro",
    "shipAddress": "Vinbæltet 34",
    "shipCity": "Kobenhavn",
    "shipRegion": "",
    "shipPostalCode": "1734",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 412.35,
    "orderDate": 1568754000000,
    "requiredDate": 1571173200000,
    "shippedDate": 1568840400000,
    "requiredDateStart": 1571230800000,
    "requiredDateEnd": 1571234400000,
    "orderID": 10673,
    "customerID": "WILMK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 22.76,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 534.85,
    "orderDate": 1569099600000,
    "requiredDate": 1571518800000,
    "shippedDate": 1569704400000,
    "requiredDateStart": 1571569200000,
    "requiredDateEnd": 1571574600000,
    "orderID": 10676,
    "customerID": "TORTU",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 2.01,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Du monde entier",
    "customerContactName": "Janine Labrune",
    "orderTotal": 63,
    "orderDate": 1569445200000,
    "requiredDate": 1571864400000,
    "shippedDate": 1569877200000,
    "requiredDateStart": 1571907600000,
    "requiredDateEnd": 1571909400000,
    "orderID": 10683,
    "customerID": "DUMON",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 4.4,
    "shipName": "Du monde entier",
    "shipAddress": "67, rue des Cinquante Otages",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 1404.45,
    "orderDate": 1569790800000,
    "requiredDate": 1572213600000,
    "shippedDate": 1570482000000,
    "requiredDateStart": 1572255000000,
    "requiredDateEnd": 1572256800000,
    "orderID": 10686,
    "customerID": "PICCO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 96.5,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 10164.8,
    "orderDate": 1570050000000,
    "requiredDate": 1573682400000,
    "shippedDate": 1571691600000,
    "requiredDateStart": 1573729200000,
    "requiredDateEnd": 1573731000000,
    "orderID": 10691,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 810.05,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 1624.5,
    "orderDate": 1572732000000,
    "requiredDate": 1575151200000,
    "shippedDate": 1575496800000,
    "requiredDateStart": 1575207000000,
    "requiredDateEnd": 1575212400000,
    "orderID": 10727,
    "customerID": "REGGC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 89.9,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 1498.35,
    "orderDate": 1573077600000,
    "requiredDate": 1575496800000,
    "shippedDate": 1573509600000,
    "requiredDateStart": 1575552600000,
    "requiredDateEnd": 1575556200000,
    "orderID": 10734,
    "customerID": "GOURL",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 1.63,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Vins et alcools Chevalier",
    "customerContactName": "Paul Henriot",
    "orderTotal": 139.8,
    "orderDate": 1573423200000,
    "requiredDate": 1575842400000,
    "shippedDate": 1574028000000,
    "requiredDateStart": 1575901800000,
    "requiredDateEnd": 1575905400000,
    "orderID": 10737,
    "customerID": "VINET",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 7.79,
    "shipName": "Vins et alcools Chevalier",
    "shipAddress": "59 rue de l-Abbaye",
    "shipCity": "Reims",
    "shipRegion": "",
    "shipPostalCode": "51100",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Spécialités du monde",
    "customerContactName": "Dominique Perrier",
    "orderTotal": 52.35,
    "orderDate": 1573509600000,
    "requiredDate": 1575928800000,
    "shippedDate": 1574028000000,
    "requiredDateStart": 1575972000000,
    "requiredDateEnd": 1575977400000,
    "orderID": 10738,
    "customerID": "SPECD",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 2.91,
    "shipName": "Spécialités du monde",
    "shipAddress": "25, rue Lauriston",
    "shipCity": "Paris",
    "shipRegion": "",
    "shipPostalCode": "75016",
    "shipCountry": "France"
}, {
    "customerCompanyName": "North/South",
    "customerContactName": "Simon Crowther",
    "orderTotal": 252,
    "orderDate": 1574546400000,
    "requiredDate": 1576965600000,
    "shippedDate": 1574892000000,
    "requiredDateStart": 1577019600000,
    "requiredDateEnd": 1577023200000,
    "orderID": 10752,
    "customerID": "NORTS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 1.39,
    "shipName": "North/South",
    "shipAddress": "South House 300 Queensbridge",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "SW7 1RZ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 720,
    "orderDate": 1576447200000,
    "requiredDate": 1577656800000,
    "shippedDate": 1577224800000,
    "requiredDateStart": 1577696400000,
    "requiredDateEnd": 1577700000000,
    "orderID": 10780,
    "customerID": "LILAS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 42.13,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 975.88,
    "orderDate": 1576533600000,
    "requiredDate": 1578952800000,
    "shippedDate": 1576706400000,
    "requiredDateStart": 1579006800000,
    "requiredDateEnd": 1579008600000,
    "orderID": 10781,
    "customerID": "WARTH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 73.16,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 2622.76,
    "orderDate": 1576706400000,
    "requiredDate": 1577916000000,
    "shippedDate": 1577311200000,
    "requiredDateStart": 1577962800000,
    "requiredDateEnd": 1577968200000,
    "orderID": 10787,
    "customerID": "LAMAI",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 249.93,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 446.6,
    "orderDate": 1577311200000,
    "requiredDate": 1579730400000,
    "shippedDate": 1578175200000,
    "requiredDateStart": 1579782600000,
    "requiredDateEnd": 1579786200000,
    "orderID": 10798,
    "customerID": "ISLAT",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 2.33,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "The Big Cheese",
    "customerContactName": "Liz Nixon",
    "orderTotal": 2775,
    "orderDate": 1577656800000,
    "requiredDate": 1580076000000,
    "shippedDate": 1578520800000,
    "requiredDateStart": 1580124600000,
    "requiredDateEnd": 1580128200000,
    "orderID": 10805,
    "customerID": "THEBI",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 237.34,
    "shipName": "The Big Cheese",
    "shipAddress": "89 Jefferson Way Suite 2",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97201",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 1411,
    "orderDate": 1577829600000,
    "requiredDate": 1580248800000,
    "shippedDate": 1578520800000,
    "requiredDateStart": 1580290200000,
    "requiredDateEnd": 1580295600000,
    "orderID": 10808,
    "customerID": "OLDWO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 45.53,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Laughing Bacchus Wine Cellars",
    "customerContactName": "Yoshi Tannamuri",
    "orderTotal": 187,
    "orderDate": 1577829600000,
    "requiredDate": 1580248800000,
    "shippedDate": 1578348000000,
    "requiredDateStart": 1580306400000,
    "requiredDateEnd": 1580308200000,
    "orderID": 10810,
    "customerID": "LAUGB",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 4.33,
    "shipName": "Laughing Bacchus Wine Cellars",
    "shipAddress": "2319 Elm St.",
    "shipCity": "Vancouver",
    "shipRegion": "BC",
    "shipPostalCode": "V3F 2K1",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 40,
    "orderDate": 1578175200000,
    "requiredDate": 1580594400000,
    "shippedDate": 1578952800000,
    "requiredDateStart": 1580646600000,
    "requiredDateEnd": 1580652000000,
    "orderID": 10815,
    "customerID": "SAVEA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 14.62,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Cactus Comidas para llevar",
    "customerContactName": "Patricio Simpson",
    "orderTotal": 477,
    "orderDate": 1578348000000,
    "requiredDate": 1580767200000,
    "shippedDate": 1579125600000,
    "requiredDateStart": 1580817600000,
    "requiredDateEnd": 1580819400000,
    "orderID": 10819,
    "customerID": "CACTU",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 19.76,
    "shipName": "Cactus Comidas para llevar",
    "shipAddress": "Cerrito 333",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 475.11,
    "orderDate": 1578952800000,
    "requiredDate": 1581372000000,
    "shippedDate": 1579384800000,
    "requiredDateStart": 1581413400000,
    "requiredDateEnd": 1581415200000,
    "orderID": 10832,
    "customerID": "LAMAI",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 43.26,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 1112,
    "orderDate": 1579644000000,
    "requiredDate": 1583359200000,
    "shippedDate": 1579730400000,
    "requiredDateStart": 1583411400000,
    "requiredDateEnd": 1583415000000,
    "orderID": 10846,
    "customerID": "SUPRD",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 56.46,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "La corne d'abondance",
    "customerContactName": "Daniel Tonini",
    "orderTotal": 649,
    "orderDate": 1580248800000,
    "requiredDate": 1582668000000,
    "shippedDate": 1580680800000,
    "requiredDateStart": 1582718400000,
    "requiredDateEnd": 1582722000000,
    "orderID": 10858,
    "customerID": "LACOR",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 52.51,
    "shipName": "La corne d-abondance",
    "shipAddress": "67, avenue de l-Europe",
    "shipCity": "Versailles",
    "shipRegion": "",
    "shipPostalCode": "78000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 16387.5,
    "orderDate": 1580594400000,
    "requiredDate": 1581804000000,
    "shippedDate": 1581458400000,
    "requiredDateStart": 1581847200000,
    "requiredDateEnd": 1581850800000,
    "orderID": 10865,
    "customerID": "QUICK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 348.14,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 6200.55,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1585220400000,
    "requiredDateEnd": 1585222200000,
    "orderID": 10912,
    "customerID": "HUNGO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 580.91,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 539.5,
    "orderDate": 1582754400000,
    "requiredDate": 1585260000000,
    "shippedDate": 1583100000000,
    "requiredDateStart": 1585317600000,
    "requiredDateEnd": 1585321200000,
    "orderID": 10915,
    "customerID": "TORTU",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 3.51,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 1122.8,
    "orderDate": 1583100000000,
    "requiredDate": 1585515600000,
    "shippedDate": 1583272800000,
    "requiredDateStart": 1585575000000,
    "requiredDateEnd": 1585580400000,
    "orderID": 10919,
    "customerID": "LINOD",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 19.8,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 637.5,
    "orderDate": 1583791200000,
    "requiredDate": 1586206800000,
    "shippedDate": 1584050400000,
    "requiredDateStart": 1586255400000,
    "requiredDateEnd": 1586259000000,
    "orderID": 10939,
    "customerID": "MAGAA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 76.33,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 4422,
    "orderDate": 1584050400000,
    "requiredDate": 1586466000000,
    "shippedDate": 1584396000000,
    "requiredDateStart": 1586514600000,
    "requiredDateEnd": 1586516400000,
    "orderID": 10949,
    "customerID": "BOTTM",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 74.44,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Toms Spezialitäten",
    "customerContactName": "Karin Josephs",
    "orderTotal": 910.4,
    "orderDate": 1584914400000,
    "requiredDate": 1587330000000,
    "shippedDate": 1585774800000,
    "requiredDateStart": 1587373200000,
    "requiredDateEnd": 1587376800000,
    "orderID": 10967,
    "customerID": "TOMSP",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 62.22,
    "shipName": "Toms Spezialitäten",
    "shipAddress": "Luisenstr. 48",
    "shipCity": "Münster",
    "shipRegion": "",
    "shipPostalCode": "44087",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "France restauration",
    "customerContactName": "Carine Schmitt",
    "orderTotal": 1733.06,
    "orderDate": 1585000800000,
    "requiredDate": 1587416400000,
    "shippedDate": 1585774800000,
    "requiredDateStart": 1587466800000,
    "requiredDateEnd": 1587470400000,
    "orderID": 10971,
    "customerID": "FRANR",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 121.82,
    "shipName": "France restauration",
    "shipAddress": "54, rue Royale",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 1014,
    "orderDate": 1585260000000,
    "requiredDate": 1587675600000,
    "shippedDate": 1586293200000,
    "requiredDateStart": 1587717000000,
    "requiredDateEnd": 1587720600000,
    "orderID": 10982,
    "customerID": "BOTTM",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 14.01,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 720.9,
    "orderDate": 1585260000000,
    "requiredDate": 1587675600000,
    "shippedDate": 1586120400000,
    "requiredDateStart": 1587727800000,
    "requiredDateEnd": 1587729600000,
    "orderID": 10983,
    "customerID": "SAVEA",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 657.54,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2023.38,
    "orderDate": 1585515600000,
    "requiredDate": 1587934800000,
    "shippedDate": 1585774800000,
    "requiredDateStart": 1587978000000,
    "requiredDateEnd": 1587983400000,
    "orderID": 10985,
    "customerID": "HUNGO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 91.51,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 1353.6,
    "orderDate": 1585602000000,
    "requiredDate": 1588021200000,
    "shippedDate": 1585774800000,
    "requiredDateStart": 1588062600000,
    "requiredDateEnd": 1588068000000,
    "orderID": 10989,
    "customerID": "QUEDE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 34.76,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 4288.85,
    "orderDate": 1585688400000,
    "requiredDate": 1589317200000,
    "shippedDate": 1586206800000,
    "requiredDateStart": 1589362200000,
    "requiredDateEnd": 1589364000000,
    "orderID": 10990,
    "customerID": "ERNSH",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 117.61,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 940.5,
    "orderDate": 1585774800000,
    "requiredDate": 1586984400000,
    "shippedDate": 1586379600000,
    "requiredDateStart": 1587040200000,
    "requiredDateEnd": 1587042000000,
    "orderID": 10994,
    "customerID": "VAFFE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 65.53,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 903.75,
    "orderDate": 1586120400000,
    "requiredDate": 1588539600000,
    "shippedDate": 1586811600000,
    "requiredDateStart": 1588581000000,
    "requiredDateEnd": 1588586400000,
    "orderID": 11000,
    "customerID": "RATTC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 55.12,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 2769,
    "orderDate": 1586120400000,
    "requiredDate": 1588539600000,
    "shippedDate": 1586811600000,
    "requiredDateStart": 1588597200000,
    "requiredDateEnd": 1588599000000,
    "orderID": 11001,
    "customerID": "FOLKO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 197.3,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 586,
    "orderDate": 1586206800000,
    "requiredDate": 1588626000000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588671000000,
    "requiredDateEnd": 1588672800000,
    "orderID": 11005,
    "customerID": "WILMK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 0.75,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 616.5,
    "orderDate": 1586293200000,
    "requiredDate": 1588712400000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588753800000,
    "requiredDateEnd": 1588755600000,
    "orderID": 11009,
    "customerID": "GODOS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 59.11,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 645,
    "orderDate": 1586379600000,
    "requiredDate": 1588798800000,
    "shippedDate": 1587416400000,
    "requiredDateStart": 1588851000000,
    "requiredDateEnd": 1588852800000,
    "orderID": 11010,
    "customerID": "REGGC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 28.71,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Romero y tomillo",
    "customerContactName": "Alejandra Camino",
    "orderTotal": 361,
    "orderDate": 1586379600000,
    "requiredDate": 1588798800000,
    "shippedDate": 1586466000000,
    "requiredDateStart": 1588849200000,
    "requiredDateEnd": 1588851000000,
    "orderID": 11013,
    "customerID": "ROMEY",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 32.99,
    "shipName": "Romero y tomillo",
    "shipAddress": "Gran Vía, 1",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28001",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 243.18,
    "orderDate": 1586466000000,
    "requiredDate": 1588885200000,
    "shippedDate": 1586898000000,
    "requiredDateStart": 1588932000000,
    "requiredDateEnd": 1588935600000,
    "orderID": 11014,
    "customerID": "LINOD",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 23.6,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 622.35,
    "orderDate": 1586466000000,
    "requiredDate": 1587675600000,
    "shippedDate": 1587330000000,
    "requiredDateStart": 1587731400000,
    "requiredDateEnd": 1587736800000,
    "orderID": 11015,
    "customerID": "SANTG",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 4.62,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 632.4,
    "orderDate": 1586811600000,
    "requiredDate": 1589230800000,
    "shippedDate": 1586984400000,
    "requiredDateStart": 1589281200000,
    "requiredDateEnd": 1589284800000,
    "orderID": 11020,
    "customerID": "OTTIK",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 43.3,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 2160,
    "orderDate": 1586984400000,
    "requiredDate": 1589403600000,
    "shippedDate": 1587502800000,
    "requiredDateStart": 1589445000000,
    "requiredDateEnd": 1589448600000,
    "orderID": 11028,
    "customerID": "KOENE",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 29.59,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 8902.5,
    "orderDate": 1587070800000,
    "requiredDate": 1589490000000,
    "shippedDate": 1587589200000,
    "requiredDateStart": 1589549400000,
    "requiredDateEnd": 1589553000000,
    "orderID": 11032,
    "customerID": "WHITC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 3,
    "freight": 606.19,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 1754.5,
    "orderDate": 1587330000000,
    "requiredDate": 1589749200000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1589801400000,
    "requiredDateEnd": 1589803200000,
    "orderID": 11035,
    "customerID": "SUPRD",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 0.17,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "Comércio Mineiro",
    "customerContactName": "Pedro Afonso",
    "orderTotal": 405.75,
    "orderDate": 1587502800000,
    "requiredDate": 1588712400000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1588770000000,
    "requiredDateEnd": 1588771800000,
    "orderID": 11042,
    "customerID": "COMMI",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 29.99,
    "shipName": "Comércio Mineiro",
    "shipAddress": "Av. dos Lusíadas, 23",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05432-043",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Piccolo und mehr",
    "customerContactName": "Georg Pipps",
    "orderTotal": 3055,
    "orderDate": 1587934800000,
    "requiredDate": 1590354000000,
    "shippedDate": 1588107600000,
    "requiredDateStart": 1590395400000,
    "requiredDateEnd": 1590399000000,
    "orderID": 11053,
    "customerID": "PICCO",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 53.05,
    "shipName": "Piccolo und mehr",
    "shipAddress": "Geislweg 14",
    "shipCity": "Salzburg",
    "shipRegion": "",
    "shipPostalCode": "5020",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 1838,
    "orderDate": 1588107600000,
    "requiredDate": 1591736400000,
    "shippedDate": 1588280400000,
    "requiredDateStart": 1591795800000,
    "requiredDateEnd": 1591799400000,
    "orderID": 11059,
    "customerID": "RICAR",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 85.8,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 266,
    "orderDate": 1588194000000,
    "requiredDate": 1590613200000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1590654600000,
    "requiredDateEnd": 1590656400000,
    "orderID": 11060,
    "customerID": "FRANS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 10.98,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Lehmanns Marktstand",
    "customerContactName": "Renate Messner",
    "orderTotal": 1629.975,
    "orderDate": 1588626000000,
    "requiredDate": 1591045200000,
    "shippedDate": 1535230800000,
    "requiredDateStart": 1591095600000,
    "requiredDateEnd": 1591099200000,
    "orderID": 11070,
    "customerID": "LEHMS",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 1,
    "freight": 136,
    "shipName": "Lehmanns Marktstand",
    "shipAddress": "Magazinweg 7",
    "shipCity": "Frankfurt a.M.",
    "shipRegion": "",
    "shipPostalCode": "60528",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 300,
    "orderDate": 1588626000000,
    "requiredDate": 1591045200000,
    "shippedDate": 1535230800000,
    "requiredDateStart": 1591104600000,
    "requiredDateEnd": 1591110000000,
    "orderID": 11073,
    "customerID": "PERIC",
    "employeeID": "3d2f991c-58ef-436d-912e-49496fd2065c",
    "teamID": 1,
    "shipVia": 2,
    "freight": 24.95,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1614.88,
    "orderDate": 1531774800000,
    "requiredDate": 1534194000000,
    "shippedDate": 1532293200000,
    "requiredDateStart": 1534246200000,
    "requiredDateEnd": 1534248000000,
    "orderID": 10258,
    "customerID": "ERNSH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 140.51,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Wartian Herkku",
    "customerContactName": "Pirkko Koskitalo",
    "orderTotal": 1376,
    "orderDate": 1533070800000,
    "requiredDate": 1535490000000,
    "shippedDate": 1533157200000,
    "requiredDateStart": 1535533200000,
    "requiredDateEnd": 1535538600000,
    "orderID": 10270,
    "customerID": "WARTH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 136.54,
    "shipName": "Wartian Herkku",
    "shipAddress": "Torikatu 38",
    "shipCity": "Oulu",
    "shipRegion": "",
    "shipPostalCode": "90110",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 291.84,
    "orderDate": 1533589200000,
    "requiredDate": 1536008400000,
    "shippedDate": 1533762000000,
    "requiredDateStart": 1536058800000,
    "requiredDateEnd": 1536062400000,
    "orderID": 10275,
    "customerID": "MAGAA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 26.93,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 1743.36,
    "orderDate": 1534712400000,
    "requiredDate": 1537131600000,
    "shippedDate": 1535230800000,
    "requiredDateStart": 1537187400000,
    "requiredDateEnd": 1537192800000,
    "orderID": 10285,
    "customerID": "QUICK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 76.83,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 1296,
    "orderDate": 1535403600000,
    "requiredDate": 1537822800000,
    "shippedDate": 1535835600000,
    "requiredDateStart": 1537878600000,
    "requiredDateEnd": 1537884000000,
    "orderID": 10292,
    "customerID": "TRADH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 1.35,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 848.7,
    "orderDate": 1535490000000,
    "requiredDate": 1537909200000,
    "shippedDate": 1536613200000,
    "requiredDateStart": 1537961400000,
    "requiredDateEnd": 1537966800000,
    "orderID": 10293,
    "customerID": "TORTU",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 21.18,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 954.4,
    "orderDate": 1536699600000,
    "requiredDate": 1539118800000,
    "shippedDate": 1537131600000,
    "requiredDateStart": 1539167400000,
    "requiredDateEnd": 1539172800000,
    "orderID": 10304,
    "customerID": "TORTU",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 63.79,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Romero y tomillo",
    "customerContactName": "Alejandra Camino",
    "orderTotal": 498.5,
    "orderDate": 1537045200000,
    "requiredDate": 1539464400000,
    "shippedDate": 1537650000000,
    "requiredDateStart": 1539518400000,
    "requiredDateEnd": 1539522000000,
    "orderID": 10306,
    "customerID": "ROMEY",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 7.56,
    "shipName": "Romero y tomillo",
    "shipAddress": "Gran Vía, 1",
    "shipCity": "Madrid",
    "shipRegion": "",
    "shipPostalCode": "28001",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Du monde entier",
    "customerContactName": "Janine Labrune",
    "orderTotal": 268.8,
    "orderDate": 1537390800000,
    "requiredDate": 1538600400000,
    "shippedDate": 1537909200000,
    "requiredDateStart": 1538652600000,
    "requiredDateEnd": 1538658000000,
    "orderID": 10311,
    "customerID": "DUMON",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 24.69,
    "shipName": "Du monde entier",
    "shipAddress": "67, rue des Cinquante Otages",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 2094.3,
    "orderDate": 1537822800000,
    "requiredDate": 1540242000000,
    "shippedDate": 1538600400000,
    "requiredDateStart": 1540285200000,
    "requiredDateEnd": 1540290600000,
    "orderID": 10314,
    "customerID": "RATTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 74.16,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 2835,
    "orderDate": 1537995600000,
    "requiredDate": 1540414800000,
    "shippedDate": 1538946000000,
    "requiredDateStart": 1540468800000,
    "requiredDateEnd": 1540470600000,
    "orderID": 10316,
    "customerID": "RATTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 150.15,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 1497,
    "orderDate": 1539032400000,
    "requiredDate": 1540242000000,
    "shippedDate": 1539464400000,
    "requiredDateStart": 1540290600000,
    "requiredDateEnd": 1540296000000,
    "orderID": 10325,
    "customerID": "KOENE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 64.86,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 2436.18,
    "orderDate": 1540764000000,
    "requiredDate": 1543183200000,
    "shippedDate": 1541628000000,
    "requiredDateStart": 1543235400000,
    "requiredDateEnd": 1543240800000,
    "orderID": 10340,
    "customerID": "BONAP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 166.31,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 5398.725,
    "orderDate": 1541887200000,
    "requiredDate": 1544306400000,
    "shippedDate": 1542664800000,
    "requiredDateStart": 1544347800000,
    "requiredDateEnd": 1544349600000,
    "orderID": 10351,
    "customerID": "ERNSH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 162.33,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1167.68,
    "orderDate": 1542578400000,
    "requiredDate": 1544997600000,
    "shippedDate": 1543701600000,
    "requiredDateStart": 1545046200000,
    "requiredDateEnd": 1545051600000,
    "orderID": 10357,
    "customerID": "LILAS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 34.88,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 2046.24,
    "orderDate": 1542837600000,
    "requiredDate": 1545256800000,
    "shippedDate": 1543788000000,
    "requiredDateStart": 1545316200000,
    "requiredDateEnd": 1545319800000,
    "orderID": 10361,
    "customerID": "QUICK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 183.17,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 950,
    "orderDate": 1543183200000,
    "requiredDate": 1546812000000,
    "shippedDate": 1543874400000,
    "requiredDateStart": 1546851600000,
    "requiredDateEnd": 1546853400000,
    "orderID": 10364,
    "customerID": "EASTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 71.97,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "La maison d'Asie",
    "customerContactName": "Annette Roulet",
    "orderTotal": 72.96,
    "orderDate": 1543788000000,
    "requiredDate": 1546207200000,
    "shippedDate": 1545602400000,
    "requiredDateStart": 1546261200000,
    "requiredDateEnd": 1546264800000,
    "orderID": 10371,
    "customerID": "LAMAI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 0.45,
    "shipName": "La maison d-Asie",
    "shipAddress": "1 rue Alsace-Lorraine",
    "shipCity": "Toulouse",
    "shipRegion": "",
    "shipPostalCode": "31000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 459,
    "orderDate": 1543960800000,
    "requiredDate": 1546380000000,
    "shippedDate": 1544306400000,
    "requiredDateStart": 1546419600000,
    "requiredDateEnd": 1546423200000,
    "orderID": 10374,
    "customerID": "WOLZA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 3.94,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 399,
    "orderDate": 1544306400000,
    "requiredDate": 1546725600000,
    "shippedDate": 1544652000000,
    "requiredDateStart": 1546768800000,
    "requiredDateEnd": 1546770600000,
    "orderID": 10376,
    "customerID": "MEREP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 20.39,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 863.6,
    "orderDate": 1544306400000,
    "requiredDate": 1546725600000,
    "shippedDate": 1544652000000,
    "requiredDateStart": 1546783200000,
    "requiredDateEnd": 1546788600000,
    "orderID": 10377,
    "customerID": "SEVES",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 22.21,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 691.2,
    "orderDate": 1544997600000,
    "requiredDate": 1547416800000,
    "shippedDate": 1545516000000,
    "requiredDateStart": 1547458200000,
    "requiredDateEnd": 1547463600000,
    "orderID": 10385,
    "customerID": "SPLIR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 30.96,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 1058.4,
    "orderDate": 1545084000000,
    "requiredDate": 1547503200000,
    "shippedDate": 1545256800000,
    "requiredDateStart": 1547559000000,
    "requiredDateEnd": 1547562600000,
    "orderID": 10387,
    "customerID": "SANTG",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 93.63,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2556.95,
    "orderDate": 1545688800000,
    "requiredDate": 1548108000000,
    "shippedDate": 1546466400000,
    "requiredDateStart": 1548162000000,
    "requiredDateEnd": 1548165600000,
    "orderID": 10393,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 126.56,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Hungry Coyote Import Store",
    "customerContactName": "Yoshi Latimer",
    "orderTotal": 442,
    "orderDate": 1545688800000,
    "requiredDate": 1548108000000,
    "shippedDate": 1546466400000,
    "requiredDateStart": 1548158400000,
    "requiredDateEnd": 1548160200000,
    "orderID": 10394,
    "customerID": "HUNGC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 30.34,
    "shipName": "Hungry Coyote Import Store",
    "shipAddress": "City Center Plaza 516 Main St.",
    "shipCity": "Elgin",
    "shipRegion": "OR",
    "shipPostalCode": "97827",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1903.8,
    "orderDate": 1545861600000,
    "requiredDate": 1547071200000,
    "shippedDate": 1546725600000,
    "requiredDateStart": 1547118000000,
    "requiredDateEnd": 1547121600000,
    "orderID": 10396,
    "customerID": "FRANK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 135.35,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Eastern Connection",
    "customerContactName": "Ann Devon",
    "orderTotal": 3063,
    "orderDate": 1546293600000,
    "requiredDate": 1548712800000,
    "shippedDate": 1547589600000,
    "requiredDateStart": 1548761400000,
    "requiredDateEnd": 1548765000000,
    "orderID": 10400,
    "customerID": "EASTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 83.93,
    "shipName": "Eastern Connection",
    "shipAddress": "35 King George",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "WX3 6FW",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 3868.6,
    "orderDate": 1546293600000,
    "requiredDate": 1548712800000,
    "shippedDate": 1547071200000,
    "requiredDateStart": 1548772200000,
    "requiredDateEnd": 1548775800000,
    "orderID": 10401,
    "customerID": "RATTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 12.51,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 400,
    "orderDate": 1546725600000,
    "requiredDate": 1549144800000,
    "shippedDate": 1548108000000,
    "requiredDateStart": 1549200600000,
    "requiredDateEnd": 1549202400000,
    "orderID": 10405,
    "customerID": "LINOD",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 34.82,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 407.7,
    "orderDate": 1550700000000,
    "requiredDate": 1553119200000,
    "shippedDate": 1551132000000,
    "requiredDateStart": 1553160600000,
    "requiredDateEnd": 1553162400000,
    "orderID": 10453,
    "customerID": "AROUT",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 25.36,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 1538.7,
    "orderDate": 1551304800000,
    "requiredDate": 1553724000000,
    "shippedDate": 1551736800000,
    "requiredDateStart": 1553769000000,
    "requiredDateEnd": 1553774400000,
    "orderID": 10461,
    "customerID": "LILAS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 148.61,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 2518,
    "orderDate": 1551736800000,
    "requiredDate": 1554152400000,
    "shippedDate": 1552514400000,
    "requiredDateStart": 1554201000000,
    "requiredDateEnd": 1554206400000,
    "orderID": 10465,
    "customerID": "VAFFE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 145.04,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "White Clover Markets",
    "customerContactName": "Karl Jablonski",
    "orderTotal": 956.675,
    "orderDate": 1552168800000,
    "requiredDate": 1554584400000,
    "shippedDate": 1552514400000,
    "requiredDateStart": 1554627600000,
    "requiredDateEnd": 1554629400000,
    "orderID": 10469,
    "customerID": "WHITC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 60.18,
    "shipName": "White Clover Markets",
    "shipAddress": "1029 - 12th Ave. S.",
    "shipCity": "Seattle",
    "shipRegion": "WA",
    "shipPostalCode": "98124",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Island Trading",
    "customerContactName": "Helen Bennett",
    "orderTotal": 230.4,
    "orderDate": 1552428000000,
    "requiredDate": 1553637600000,
    "shippedDate": 1553119200000,
    "requiredDateStart": 1553697000000,
    "requiredDateEnd": 1553700600000,
    "orderID": 10473,
    "customerID": "ISLAT",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 16.37,
    "shipName": "Island Trading",
    "shipAddress": "Garden House Crowther Way",
    "shipCity": "Cowes",
    "shipRegion": "Isle of Wight",
    "shipPostalCode": "PO31 7PJ",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Lazy K Kountry Store",
    "customerContactName": "John Steel",
    "orderTotal": 147,
    "orderDate": 1553119200000,
    "requiredDate": 1555534800000,
    "shippedDate": 1554843600000,
    "requiredDateStart": 1555592400000,
    "requiredDateEnd": 1555596000000,
    "orderID": 10482,
    "customerID": "LAZYK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 7.48,
    "shipName": "Lazy K Kountry Store",
    "shipAddress": "12 Orchestra Terrace",
    "shipCity": "Walla Walla",
    "shipRegion": "WA",
    "shipPostalCode": "99362",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 1272,
    "orderDate": 1553551200000,
    "requiredDate": 1555966800000,
    "shippedDate": 1554152400000,
    "requiredDateStart": 1556010000000,
    "requiredDateEnd": 1556013600000,
    "orderID": 10486,
    "customerID": "HILAA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 30.53,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Ottilies Käseladen",
    "customerContactName": "Henriette Pfalzheim",
    "orderTotal": 240,
    "orderDate": 1555362000000,
    "requiredDate": 1557781200000,
    "shippedDate": 1557694800000,
    "requiredDateStart": 1557824400000,
    "requiredDateEnd": 1557829800000,
    "orderID": 10508,
    "customerID": "OTTIK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 4.99,
    "shipName": "Ottilies Käseladen",
    "shipAddress": "Mehrheimerstr. 369",
    "shipCity": "Köln",
    "shipRegion": "",
    "shipPostalCode": "50739",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 3192.65,
    "orderDate": 1556658000000,
    "requiredDate": 1559077200000,
    "shippedDate": 1557176400000,
    "requiredDateStart": 1559131200000,
    "requiredDateEnd": 1559133000000,
    "orderID": 10524,
    "customerID": "BERGS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 244.79,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 818.4,
    "orderDate": 1556744400000,
    "requiredDate": 1559163600000,
    "shippedDate": 1558558800000,
    "requiredDateStart": 1559208600000,
    "requiredDateEnd": 1559210400000,
    "orderID": 10525,
    "customerID": "BONAP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 11.06,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Richter Supermarkt",
    "customerContactName": "Michael Holz",
    "orderTotal": 1823.8,
    "orderDate": 1557781200000,
    "requiredDate": 1558990800000,
    "shippedDate": 1558213200000,
    "requiredDateStart": 1559050200000,
    "requiredDateEnd": 1559052000000,
    "orderID": 10537,
    "customerID": "RICSU",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 78.85,
    "shipName": "Richter Supermarkt",
    "shipAddress": "Starenweg 5",
    "shipCity": "Genève",
    "shipRegion": "",
    "shipPostalCode": "1204",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 469.11,
    "orderDate": 1558299600000,
    "requiredDate": 1560718800000,
    "shippedDate": 1558818000000,
    "requiredDateStart": 1560765600000,
    "requiredDateEnd": 1560771000000,
    "orderID": 10542,
    "customerID": "KOENE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 10.95,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 2812,
    "orderDate": 1558558800000,
    "requiredDate": 1560978000000,
    "shippedDate": 1558904400000,
    "requiredDateStart": 1561028400000,
    "requiredDateEnd": 1561033800000,
    "orderID": 10546,
    "customerID": "VICTE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 194.72,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 2142.9,
    "orderDate": 1559595600000,
    "requiredDate": 1562014800000,
    "shippedDate": 1560114000000,
    "requiredDateStart": 1562063400000,
    "requiredDateEnd": 1562067000000,
    "orderID": 10558,
    "customerID": "AROUT",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 72.97,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 488.7,
    "orderDate": 1560027600000,
    "requiredDate": 1562446800000,
    "shippedDate": 1560286800000,
    "requiredDateStart": 1562493600000,
    "requiredDateEnd": 1562497200000,
    "orderID": 10562,
    "customerID": "REGGC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 22.95,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Hungry Owl All-Night Grocers",
    "customerContactName": "Patricia McKenna",
    "orderTotal": 2519,
    "orderDate": 1560286800000,
    "requiredDate": 1562706000000,
    "shippedDate": 1560718800000,
    "requiredDateStart": 1562763600000,
    "requiredDateEnd": 1562765400000,
    "orderID": 10567,
    "customerID": "HUNGO",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 33.97,
    "shipName": "Hungry Owl All-Night Grocers",
    "shipAddress": "8 Johnstown Road",
    "shipCity": "Cork",
    "shipRegion": "Co. Cork",
    "shipPostalCode": "",
    "shipCountry": "Ireland"
}, {
    "customerCompanyName": "Let's Stop N Shop",
    "customerContactName": "Jaime Yorres",
    "orderTotal": 317.75,
    "orderDate": 1561410000000,
    "requiredDate": 1563829200000,
    "shippedDate": 1562187600000,
    "requiredDateStart": 1563885000000,
    "requiredDateEnd": 1563886800000,
    "orderID": 10579,
    "customerID": "LETSS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 13.73,
    "shipName": "Let-s Stop N Shop",
    "shipAddress": "87 Polk St. Suite 5",
    "shipCity": "San Francisco",
    "shipRegion": "CA",
    "shipPostalCode": "94117",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Que Delícia",
    "customerContactName": "Bernardo Batista",
    "orderTotal": 807.38,
    "orderDate": 1562014800000,
    "requiredDate": 1564434000000,
    "shippedDate": 1562619600000,
    "requiredDateStart": 1564473600000,
    "requiredDateEnd": 1564475400000,
    "orderID": 10587,
    "customerID": "QUEDE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 62.52,
    "shipName": "Que Delícia",
    "shipAddress": "Rua da Panificadora, 12",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-673",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 812.5,
    "orderDate": 1562446800000,
    "requiredDate": 1563656400000,
    "shippedDate": 1563224400000,
    "requiredDateStart": 1563710400000,
    "requiredDateEnd": 1563715800000,
    "orderID": 10591,
    "customerID": "VAFFE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 55.92,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 2388.5,
    "orderDate": 1563051600000,
    "requiredDate": 1565470800000,
    "shippedDate": 1563397200000,
    "requiredDateStart": 1565514000000,
    "requiredDateEnd": 1565517600000,
    "orderID": 10598,
    "customerID": "RATTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 44.42,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 230.85,
    "orderDate": 1563397200000,
    "requiredDate": 1565816400000,
    "shippedDate": 1564347600000,
    "requiredDateStart": 1565857800000,
    "requiredDateEnd": 1565863200000,
    "orderID": 10604,
    "customerID": "FURIB",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 7.46,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 4109.7,
    "orderDate": 1563656400000,
    "requiredDate": 1566075600000,
    "shippedDate": 1564347600000,
    "requiredDateStart": 1566118800000,
    "requiredDateEnd": 1566120600000,
    "orderID": 10605,
    "customerID": "MEREP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 379.13,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 6375,
    "orderDate": 1564261200000,
    "requiredDate": 1566680400000,
    "shippedDate": 1564606800000,
    "requiredDateStart": 1566732600000,
    "requiredDateEnd": 1566738000000,
    "orderID": 10612,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 544.08,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Great Lakes Food Market",
    "customerContactName": "Howard Snyder",
    "orderTotal": 4807,
    "orderDate": 1564520400000,
    "requiredDate": 1566939600000,
    "shippedDate": 1564952400000,
    "requiredDateStart": 1566991800000,
    "requiredDateEnd": 1566997200000,
    "orderID": 10616,
    "customerID": "GREAL",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 116.53,
    "shipName": "Great Lakes Food Market",
    "shipAddress": "2732 Baker Blvd.",
    "shipCity": "Eugene",
    "shipRegion": "OR",
    "shipPostalCode": "97403",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Mère Paillarde",
    "customerContactName": "Jean Fresnière",
    "orderTotal": 2697.5,
    "orderDate": 1564606800000,
    "requiredDate": 1568235600000,
    "shippedDate": 1565211600000,
    "requiredDateStart": 1568293200000,
    "requiredDateEnd": 1568295000000,
    "orderID": 10618,
    "customerID": "MEREP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 154.68,
    "shipName": "Mère Paillarde",
    "shipAddress": "43 rue St. Laurent",
    "shipCity": "Montréal",
    "shipRegion": "Québec",
    "shipPostalCode": "H1J 1C3",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1503.6,
    "orderDate": 1565470800000,
    "requiredDate": 1567890000000,
    "shippedDate": 1566248400000,
    "requiredDateStart": 1567949400000,
    "requiredDateEnd": 1567954800000,
    "orderID": 10626,
    "customerID": "BERGS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 138.69,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 903.6,
    "orderDate": 1565643600000,
    "requiredDate": 1568062800000,
    "shippedDate": 1566162000000,
    "requiredDateStart": 1568118600000,
    "requiredDateEnd": 1568124000000,
    "orderID": 10630,
    "customerID": "KOENE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 32.35,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1083.15,
    "orderDate": 1567371600000,
    "requiredDate": 1569790800000,
    "shippedDate": 1568840400000,
    "requiredDateStart": 1569834000000,
    "requiredDateEnd": 1569835800000,
    "orderID": 10653,
    "customerID": "FRANK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 93.25,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Reggiani Caseifici",
    "customerContactName": "Maurizio Moroni",
    "orderTotal": 154.4,
    "orderDate": 1567458000000,
    "requiredDate": 1569877200000,
    "shippedDate": 1568149200000,
    "requiredDateStart": 1569916800000,
    "requiredDateEnd": 1569918600000,
    "orderID": 10655,
    "customerID": "REGGC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 4.41,
    "shipName": "Reggiani Caseifici",
    "shipAddress": "Strada Provinciale 124",
    "shipCity": "Reggio Emilia",
    "shipRegion": "",
    "shipPostalCode": "42100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Furia Bacalhau e Frutos do Mar",
    "customerContactName": "Lino Rodriguez",
    "orderTotal": 1288.3875,
    "orderDate": 1568062800000,
    "requiredDate": 1570482000000,
    "shippedDate": 1568840400000,
    "requiredDateStart": 1570532400000,
    "requiredDateEnd": 1570537800000,
    "orderID": 10664,
    "customerID": "FURIB",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 1.27,
    "shipName": "Furia Bacalhau e Frutos do Mar",
    "shipAddress": "Jardim das rosas n. 32",
    "shipCity": "Lisboa",
    "shipRegion": "",
    "shipPostalCode": "1675",
    "shipCountry": "Portugal"
}, {
    "customerCompanyName": "Lonesome Pine Restaurant",
    "customerContactName": "Fran Wilson",
    "orderTotal": 1295,
    "orderDate": 1568149200000,
    "requiredDate": 1570568400000,
    "shippedDate": 1568667600000,
    "requiredDateStart": 1570609800000,
    "requiredDateEnd": 1570615200000,
    "orderID": 10665,
    "customerID": "LONEP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 26.31,
    "shipName": "Lonesome Pine Restaurant",
    "shipAddress": "89 Chiaroscuro Rd.",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97219",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Die Wandernde Kuh",
    "customerContactName": "Rita Müller",
    "orderTotal": 625.275,
    "orderDate": 1568494800000,
    "requiredDate": 1570914000000,
    "shippedDate": 1569186000000,
    "requiredDateStart": 1570968000000,
    "requiredDateEnd": 1570969800000,
    "orderID": 10668,
    "customerID": "WANDK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 47.22,
    "shipName": "Die Wandernde Kuh",
    "shipAddress": "Adenauerallee 900",
    "shipCity": "Stuttgart",
    "shipRegion": "",
    "shipPostalCode": "70563",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "France restauration",
    "customerContactName": "Carine Schmitt",
    "orderTotal": 920.1,
    "orderDate": 1568667600000,
    "requiredDate": 1571086800000,
    "shippedDate": 1569272400000,
    "requiredDateStart": 1571144400000,
    "requiredDateEnd": 1571146200000,
    "orderID": 10671,
    "customerID": "FRANR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 30.34,
    "shipName": "France restauration",
    "shipAddress": "54, rue Royale",
    "shipCity": "Nantes",
    "shipRegion": "",
    "shipPostalCode": "44000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Antonio Moreno Taquería",
    "customerContactName": "Antonio Moreno",
    "orderTotal": 813.365,
    "orderDate": 1569099600000,
    "requiredDate": 1571518800000,
    "shippedDate": 1569445200000,
    "requiredDateStart": 1571576400000,
    "requiredDateEnd": 1571581800000,
    "orderID": 10677,
    "customerID": "ANTON",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 4.03,
    "shipName": "Antonio Moreno Taquería",
    "shipAddress": "Mataderos  2312",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5023",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Old World Delicatessen",
    "customerContactName": "Rene Phillips",
    "orderTotal": 1261.875,
    "orderDate": 1569272400000,
    "requiredDate": 1571691600000,
    "shippedDate": 1569445200000,
    "requiredDateStart": 1571742000000,
    "requiredDateEnd": 1571743800000,
    "orderID": 10680,
    "customerID": "OLDWO",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 26.61,
    "shipName": "Old World Delicatessen",
    "shipAddress": "2743 Bering St.",
    "shipCity": "Anchorage",
    "shipRegion": "AK",
    "shipPostalCode": "99508",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 472.5,
    "orderDate": 1569877200000,
    "requiredDate": 1572300000000,
    "shippedDate": 1570395600000,
    "requiredDateStart": 1572339600000,
    "requiredDateEnd": 1572343200000,
    "orderID": 10689,
    "customerID": "BERGS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 13.42,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 862.5,
    "orderDate": 1569963600000,
    "requiredDate": 1572386400000,
    "shippedDate": 1570050000000,
    "requiredDateStart": 1572436800000,
    "requiredDateEnd": 1572440400000,
    "orderID": 10690,
    "customerID": "HANAR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 15.8,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Gourmet Lanchonetes",
    "customerContactName": "André Fonseca",
    "orderTotal": 3424,
    "orderDate": 1571259600000,
    "requiredDate": 1573682400000,
    "shippedDate": 1574200800000,
    "requiredDateStart": 1573723800000,
    "requiredDateEnd": 1573727400000,
    "orderID": 10709,
    "customerID": "GOURL",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 210.8,
    "shipName": "Gourmet Lanchonetes",
    "shipAddress": "Av. Brasil, 442",
    "shipCity": "Campinas",
    "shipRegion": "SP",
    "shipPostalCode": "04876-786",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Franchi S.p.A.",
    "customerContactName": "Paolo Accorti",
    "orderTotal": 93.5,
    "orderDate": 1571518800000,
    "requiredDate": 1573941600000,
    "shippedDate": 1571778000000,
    "requiredDateStart": 1573986600000,
    "requiredDateEnd": 1573990200000,
    "orderID": 10710,
    "customerID": "FRANS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 4.98,
    "shipName": "Franchi S.p.A.",
    "shipAddress": "Via Monte Bianco 34",
    "shipCity": "Torino",
    "shipRegion": "",
    "shipPostalCode": "10100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2827.9,
    "orderDate": 1571691600000,
    "requiredDate": 1574114400000,
    "shippedDate": 1571864400000,
    "requiredDateStart": 1574164800000,
    "requiredDateEnd": 1574170200000,
    "orderID": 10713,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 167.05,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1270.75,
    "orderDate": 1571864400000,
    "requiredDate": 1574287200000,
    "shippedDate": 1572300000000,
    "requiredDateStart": 1574328600000,
    "requiredDateEnd": 1574330400000,
    "orderID": 10717,
    "customerID": "FRANK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 59.25,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Königlich Essen",
    "customerContactName": "Philip Cramer",
    "orderTotal": 3463,
    "orderDate": 1572123600000,
    "requiredDate": 1574546400000,
    "shippedDate": 1572300000000,
    "requiredDateStart": 1574587800000,
    "requiredDateEnd": 1574591400000,
    "orderID": 10718,
    "customerID": "KOENE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 170.88,
    "shipName": "Königlich Essen",
    "shipAddress": "Maubelstr. 90",
    "shipCity": "Brandenburg",
    "shipRegion": "",
    "shipPostalCode": "14776",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Berglunds snabbköp",
    "customerContactName": "Christina Berglund",
    "orderTotal": 1459,
    "orderDate": 1573077600000,
    "requiredDate": 1575496800000,
    "shippedDate": 1573336800000,
    "requiredDateStart": 1575552600000,
    "requiredDateEnd": 1575558000000,
    "orderID": 10733,
    "customerID": "BERGS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 110.11,
    "shipName": "Berglunds snabbköp",
    "shipAddress": "Berguvsvägen  8",
    "shipCity": "Luleå",
    "shipRegion": "",
    "shipPostalCode": "S-958 22",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Around the Horn",
    "customerContactName": "Thomas Hardy",
    "orderTotal": 319.2,
    "orderDate": 1573941600000,
    "requiredDate": 1576360800000,
    "shippedDate": 1574287200000,
    "requiredDateStart": 1576400400000,
    "requiredDateEnd": 1576404000000,
    "orderID": 10743,
    "customerID": "AROUT",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 23.72,
    "shipName": "Around the Horn",
    "shipAddress": "Brook Farm Stratford St. Mary",
    "shipCity": "Colchester",
    "shipRegion": "Essex",
    "shipPostalCode": "CO7 6JX",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Chop-suey Chinese",
    "customerContactName": "Yang Wang",
    "orderTotal": 2311.7,
    "orderDate": 1574114400000,
    "requiredDate": 1576533600000,
    "shippedDate": 1574287200000,
    "requiredDateStart": 1576580400000,
    "requiredDateEnd": 1576584000000,
    "orderID": 10746,
    "customerID": "CHOPS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 31.43,
    "shipName": "Chop-suey Chinese",
    "shipAddress": "Hauptstr. 31",
    "shipCity": "Bern",
    "shipRegion": "",
    "shipPostalCode": "3012",
    "shipCountry": "Switzerland"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 2030.4,
    "orderDate": 1576015200000,
    "requiredDate": 1578434400000,
    "shippedDate": 1576447200000,
    "requiredDateStart": 1578479400000,
    "requiredDateEnd": 1578483000000,
    "orderID": 10773,
    "customerID": "ERNSH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 96.43,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 6635.275,
    "orderDate": 1576360800000,
    "requiredDate": 1578780000000,
    "shippedDate": 1576620000000,
    "requiredDateStart": 1578830400000,
    "requiredDateEnd": 1578834000000,
    "orderID": 10776,
    "customerID": "ERNSH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 351.53,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "GROSELLA-Restaurante",
    "customerContactName": "Manuel Pereira",
    "orderTotal": 387.5,
    "orderDate": 1576620000000,
    "requiredDate": 1579039200000,
    "shippedDate": 1577138400000,
    "requiredDateStart": 1579095000000,
    "requiredDateEnd": 1579100400000,
    "orderID": 10785,
    "customerID": "GROSR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 1.51,
    "shipName": "GROSELLA-Restaurante",
    "shipAddress": "5ª Ave. Los Palos Grandes",
    "shipCity": "Caracas",
    "shipRegion": "DF",
    "shipPostalCode": "1081",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 731.5,
    "orderDate": 1576965600000,
    "requiredDate": 1579384800000,
    "shippedDate": 1579384800000,
    "requiredDateStart": 1579444200000,
    "requiredDateEnd": 1579446000000,
    "orderID": 10788,
    "customerID": "QUICK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 42.7,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Folies gourmandes",
    "customerContactName": "Martine Rancé",
    "orderTotal": 3687,
    "orderDate": 1576965600000,
    "requiredDate": 1579384800000,
    "shippedDate": 1577743200000,
    "requiredDateStart": 1579426200000,
    "requiredDateEnd": 1579429800000,
    "orderID": 10789,
    "customerID": "FOLIG",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 100.6,
    "shipName": "Folies gourmandes",
    "shipAddress": "184, chaussée de Tournai",
    "shipCity": "Lille",
    "shipRegion": "",
    "shipPostalCode": "59000",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Wolski  Zajazd",
    "customerContactName": "Zbyszek Piestrzeniewicz",
    "orderTotal": 399.85,
    "orderDate": 1577052000000,
    "requiredDate": 1579471200000,
    "shippedDate": 1577743200000,
    "requiredDateStart": 1579528800000,
    "requiredDateEnd": 1579532400000,
    "orderID": 10792,
    "customerID": "WOLZA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 23.79,
    "shipName": "Wolski Zajazd",
    "shipAddress": "ul. Filtrowa 68",
    "shipCity": "Warszawa",
    "shipRegion": "",
    "shipPostalCode": "01-012",
    "shipCountry": "Poland"
}, {
    "customerCompanyName": "Seven Seas Imports",
    "customerContactName": "Hari Kumar",
    "orderTotal": 1468.935,
    "orderDate": 1577311200000,
    "requiredDate": 1579730400000,
    "shippedDate": 1578175200000,
    "requiredDateStart": 1579780800000,
    "requiredDateEnd": 1579786200000,
    "orderID": 10800,
    "customerID": "SEVES",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 137.44,
    "shipName": "Seven Seas Imports",
    "shipAddress": "90 Wadhurst Rd.",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "OX15 4NB",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 602.4,
    "orderDate": 1578175200000,
    "requiredDate": 1580594400000,
    "shippedDate": 1578520800000,
    "requiredDateStart": 1580652000000,
    "requiredDateEnd": 1580657400000,
    "orderID": 10813,
    "customerID": "RICAR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 47.38,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Split Rail Beer & Ale",
    "customerContactName": "Art Braunschweiger",
    "orderTotal": 678,
    "orderDate": 1578434400000,
    "requiredDate": 1580853600000,
    "shippedDate": 1579039200000,
    "requiredDateStart": 1580900400000,
    "requiredDateEnd": 1580905800000,
    "orderID": 10821,
    "customerID": "SPLIR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 36.68,
    "shipName": "Split Rail Beer & Ale",
    "shipAddress": "P.O. Box 555",
    "shipCity": "Lander",
    "shipRegion": "WY",
    "shipPostalCode": "82520",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 1030.76,
    "orderDate": 1578520800000,
    "requiredDate": 1580940000000,
    "shippedDate": 1578952800000,
    "requiredDateStart": 1580992200000,
    "requiredDateEnd": 1580994000000,
    "orderID": 10825,
    "customerID": "DRACD",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 79.25,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Bon app'",
    "customerContactName": "Laurence Lebihan",
    "orderTotal": 843,
    "orderDate": 1578780000000,
    "requiredDate": 1579989600000,
    "shippedDate": 1580940000000,
    "requiredDateStart": 1580036400000,
    "requiredDateEnd": 1580041800000,
    "orderID": 10827,
    "customerID": "BONAP",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 63.54,
    "shipName": "Bon app-",
    "shipAddress": "12, rue des Bouchers",
    "shipCity": "Marseille",
    "shipRegion": "",
    "shipPostalCode": "13008",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Tradição Hipermercados",
    "customerContactName": "Anabela Domingues",
    "orderTotal": 1432.714,
    "orderDate": 1579039200000,
    "requiredDate": 1581458400000,
    "shippedDate": 1579384800000,
    "requiredDateStart": 1581514200000,
    "requiredDateEnd": 1581519600000,
    "orderID": 10834,
    "customerID": "TRADH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 29.78,
    "shipName": "Tradiçao Hipermercados",
    "shipAddress": "Av. Inês de Castro, 414",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05634-030",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 845.8,
    "orderDate": 1579039200000,
    "requiredDate": 1581458400000,
    "shippedDate": 1579557600000,
    "requiredDateStart": 1581517800000,
    "requiredDateEnd": 1581521400000,
    "orderID": 10835,
    "customerID": "ALFKI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 69.53,
    "shipName": "Alfred-s Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 975,
    "orderDate": 1579471200000,
    "requiredDate": 1581890400000,
    "shippedDate": 1580248800000,
    "requiredDateStart": 1581942600000,
    "requiredDateEnd": 1581946200000,
    "orderID": 10842,
    "customerID": "TORTU",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 54.42,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Victuailles en stock",
    "customerContactName": "Mary Saveley",
    "orderTotal": 629,
    "orderDate": 1579730400000,
    "requiredDate": 1583445600000,
    "shippedDate": 1580335200000,
    "requiredDateStart": 1583488800000,
    "requiredDateEnd": 1583494200000,
    "orderID": 10850,
    "customerID": "VICTE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 49.19,
    "shipName": "Victuailles en stock",
    "shipAddress": "2, rue du Commerce",
    "shipCity": "Lyon",
    "shipRegion": "",
    "shipPostalCode": "69004",
    "shipCountry": "France"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 1078.6875,
    "orderDate": 1580248800000,
    "requiredDate": 1582668000000,
    "shippedDate": 1580594400000,
    "requiredDateStart": 1582722000000,
    "requiredDateEnd": 1582725600000,
    "orderID": 10859,
    "customerID": "FRANK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 76.1,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ricardo Adocicados",
    "customerContactName": "Janete Limeira",
    "orderTotal": 1955.125,
    "orderDate": 1581199200000,
    "requiredDate": 1583704800000,
    "shippedDate": 1582063200000,
    "requiredDateStart": 1583744400000,
    "requiredDateEnd": 1583748000000,
    "orderID": 10877,
    "customerID": "RICAR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 38.06,
    "shipName": "Ricardo Adocicados",
    "shipAddress": "Av. Copacabana, 267",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "02389-890",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 3127.5,
    "orderDate": 1581544800000,
    "requiredDate": 1584050400000,
    "shippedDate": 1583100000000,
    "requiredDateStart": 1584106200000,
    "requiredDateEnd": 1584111600000,
    "orderID": 10886,
    "customerID": "HANAR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 4.99,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Godos Cocina Típica",
    "customerContactName": "José Pedro Freyre",
    "orderTotal": 605,
    "orderDate": 1581804000000,
    "requiredDate": 1584309600000,
    "shippedDate": 1582408800000,
    "requiredDateStart": 1584363600000,
    "requiredDateEnd": 1584365400000,
    "orderID": 10888,
    "customerID": "GODOS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 51.87,
    "shipName": "Godos Cocina Típica",
    "shipAddress": "C/ Romero, 33",
    "shipCity": "Sevilla",
    "shipRegion": "",
    "shipPostalCode": "41101",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 2753.1,
    "orderDate": 1581976800000,
    "requiredDate": 1584482400000,
    "shippedDate": 1582149600000,
    "requiredDateStart": 1584529200000,
    "requiredDateEnd": 1584534600000,
    "orderID": 10894,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 116.13,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Wellington Importadora",
    "customerContactName": "Paula Parente",
    "orderTotal": 33.75,
    "orderDate": 1582149600000,
    "requiredDate": 1584655200000,
    "shippedDate": 1583272800000,
    "requiredDateStart": 1584714600000,
    "requiredDateEnd": 1584720000000,
    "orderID": 10900,
    "customerID": "WELLI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 1.66,
    "shipName": "Wellington Importadora",
    "shipAddress": "Rua do Mercado, 12",
    "shipCity": "Resende",
    "shipRegion": "SP",
    "shipPostalCode": "08737-363",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Folk och fä HB",
    "customerContactName": "Maria Larsson",
    "orderTotal": 863.43,
    "orderDate": 1582408800000,
    "requiredDate": 1584914400000,
    "shippedDate": 1583186400000,
    "requiredDateStart": 1584957600000,
    "requiredDateEnd": 1584961200000,
    "orderID": 10902,
    "customerID": "FOLKO",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 44.15,
    "shipName": "Folk och fä HB",
    "shipAddress": "Åkergatan 24",
    "shipCity": "Bräcke",
    "shipRegion": "",
    "shipPostalCode": "S-844 67",
    "shipCountry": "Sweden"
}, {
    "customerCompanyName": "Santé Gourmet",
    "customerContactName": "Jonas Bergulfsen",
    "orderTotal": 670,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1583791200000,
    "requiredDateStart": 1585229400000,
    "requiredDateEnd": 1585231200000,
    "orderID": 10909,
    "customerID": "SANTG",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 53.05,
    "shipName": "Santé Gourmet",
    "shipAddress": "Erling Skakkes gate 78",
    "shipCity": "Stavern",
    "shipRegion": "",
    "shipPostalCode": "4110",
    "shipCountry": "Norway"
}, {
    "customerCompanyName": "Wilman Kala",
    "customerContactName": "Matti Karttunen",
    "orderTotal": 452.9,
    "orderDate": 1582668000000,
    "requiredDate": 1585173600000,
    "shippedDate": 1583272800000,
    "requiredDateStart": 1585220400000,
    "requiredDateEnd": 1585222200000,
    "orderID": 10910,
    "customerID": "WILMK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 38.11,
    "shipName": "Wilman Kala",
    "shipAddress": "Keskuskatu 45",
    "shipCity": "Helsinki",
    "shipRegion": "",
    "shipPostalCode": "21240",
    "shipCountry": "Finland"
}, {
    "customerCompanyName": "Rancho grande",
    "customerContactName": "Sergio Gutiérrez",
    "orderTotal": 686.7,
    "orderDate": 1582754400000,
    "requiredDate": 1585260000000,
    "shippedDate": 1583704800000,
    "requiredDateStart": 1585319400000,
    "requiredDateEnd": 1585323000000,
    "orderID": 10916,
    "customerID": "RANCH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 63.77,
    "shipName": "Rancho grande",
    "shipAddress": "Av. del Libertador 900",
    "shipCity": "Buenos Aires",
    "shipRegion": "",
    "shipPostalCode": "1010",
    "shipCountry": "Argentina"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 1936,
    "orderDate": 1583186400000,
    "requiredDate": 1586811600000,
    "shippedDate": 1583704800000,
    "requiredDateStart": 1586853000000,
    "requiredDateEnd": 1586858400000,
    "orderID": 10921,
    "customerID": "VAFFE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 176.48,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Galería del gastrónomo",
    "customerContactName": "Eduardo Saavedra",
    "orderTotal": 137.5,
    "orderDate": 1583359200000,
    "requiredDate": 1585774800000,
    "shippedDate": 1584482400000,
    "requiredDateStart": 1585823400000,
    "requiredDateEnd": 1585825200000,
    "orderID": 10928,
    "customerID": "GALED",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 1.36,
    "shipName": "Galería del gastronómo",
    "shipAddress": "Rambla de Cataluña, 23",
    "shipCity": "Barcelona",
    "shipRegion": "",
    "shipPostalCode": "8022",
    "shipCountry": "Spain"
}, {
    "customerCompanyName": "Vaffeljernet",
    "customerContactName": "Palle Ibsen",
    "orderTotal": 1407.5,
    "orderDate": 1583964000000,
    "requiredDate": 1586379600000,
    "shippedDate": 1584568800000,
    "requiredDateStart": 1586422800000,
    "requiredDateEnd": 1586426400000,
    "orderID": 10946,
    "customerID": "VAFFE",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 27.2,
    "shipName": "Vaffeljernet",
    "shipAddress": "Smagsloget 45",
    "shipCity": "Århus",
    "shipRegion": "",
    "shipPostalCode": "8200",
    "shipCountry": "Denmark"
}, {
    "customerCompanyName": "Magazzini Alimentari Riuniti",
    "customerContactName": "Giovanni Rovelli",
    "orderTotal": 110,
    "orderDate": 1584309600000,
    "requiredDate": 1586725200000,
    "shippedDate": 1584914400000,
    "requiredDateStart": 1586782800000,
    "requiredDateEnd": 1586788200000,
    "orderID": 10950,
    "customerID": "MAGAA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 2.5,
    "shipName": "Magazzini Alimentari Riuniti",
    "shipAddress": "Via Ludovico il Moro 22",
    "shipCity": "Bergamo",
    "shipRegion": "",
    "shipPostalCode": "24100",
    "shipCountry": "Italy"
}, {
    "customerCompanyName": "Alfreds Futterkiste",
    "customerContactName": "Maria Anders",
    "orderTotal": 471.2,
    "orderDate": 1584309600000,
    "requiredDate": 1587934800000,
    "shippedDate": 1585000800000,
    "requiredDateStart": 1587985200000,
    "requiredDateEnd": 1587987000000,
    "orderID": 10952,
    "customerID": "ALFKI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 40.42,
    "shipName": "Alfred-s Futterkiste",
    "shipAddress": "Obere Str. 57",
    "shipCity": "Berlin",
    "shipRegion": "",
    "shipPostalCode": "12209",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Ernst Handel",
    "customerContactName": "Roland Mendel",
    "orderTotal": 1408,
    "orderDate": 1584914400000,
    "requiredDate": 1587330000000,
    "shippedDate": 1585688400000,
    "requiredDateStart": 1587375000000,
    "requiredDateEnd": 1587380400000,
    "orderID": 10968,
    "customerID": "ERNSH",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 74.6,
    "shipName": "Ernst Handel",
    "shipAddress": "Kirchgasse 6",
    "shipCity": "Graz",
    "shipRegion": "",
    "shipPostalCode": "8010",
    "shipCountry": "Austria"
}, {
    "customerCompanyName": "Comércio Mineiro",
    "customerContactName": "Pedro Afonso",
    "orderTotal": 108,
    "orderDate": 1584914400000,
    "requiredDate": 1587330000000,
    "shippedDate": 1585515600000,
    "requiredDateStart": 1587375000000,
    "requiredDateEnd": 1587376800000,
    "orderID": 10969,
    "customerID": "COMMI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 0.21,
    "shipName": "Comércio Mineiro",
    "shipAddress": "Av. dos Lusíadas, 23",
    "shipCity": "Sao Paulo",
    "shipRegion": "SP",
    "shipPostalCode": "05432-043",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 717.5,
    "orderDate": 1585087200000,
    "requiredDate": 1587502800000,
    "shippedDate": 1585260000000,
    "requiredDateStart": 1587560400000,
    "requiredDateEnd": 1587564000000,
    "orderID": 10975,
    "customerID": "BOTTM",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 32.27,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "HILARION-Abastos",
    "customerContactName": "Carlos Hernández",
    "orderTotal": 912,
    "orderDate": 1585087200000,
    "requiredDate": 1588712400000,
    "shippedDate": 1585861200000,
    "requiredDateStart": 1588757400000,
    "requiredDateEnd": 1588759200000,
    "orderID": 10976,
    "customerID": "HILAA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 37.97,
    "shipName": "HILARION-Abastos",
    "shipAddress": "Carrera 22 con Ave. Carlos Soublette #8-35",
    "shipCity": "San Cristóbal",
    "shipRegion": "Táchira",
    "shipPostalCode": "5022",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Hanari Carnes",
    "customerContactName": "Mario Pontes",
    "orderTotal": 15810,
    "orderDate": 1585260000000,
    "requiredDate": 1587675600000,
    "shippedDate": 1585774800000,
    "requiredDateStart": 1587735000000,
    "requiredDateEnd": 1587736800000,
    "orderID": 10981,
    "customerID": "HANAR",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 193.37,
    "shipName": "Hanari Carnes",
    "shipAddress": "Rua do Paço, 67",
    "shipCity": "Rio de Janeiro",
    "shipRegion": "RJ",
    "shipPostalCode": "05454-876",
    "shipCountry": "Brazil"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 1809.75,
    "orderDate": 1585515600000,
    "requiredDate": 1587934800000,
    "shippedDate": 1585861200000,
    "requiredDateStart": 1587974400000,
    "requiredDateEnd": 1587976200000,
    "orderID": 10984,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 211.22,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "QUICK-Stop",
    "customerContactName": "Horst Kloss",
    "orderTotal": 2296,
    "orderDate": 1585688400000,
    "requiredDate": 1588107600000,
    "shippedDate": 1586206800000,
    "requiredDateStart": 1588147200000,
    "requiredDateEnd": 1588150800000,
    "orderID": 10991,
    "customerID": "QUICK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 38.51,
    "shipName": "QUICK-Stop",
    "shipAddress": "Taucherstraße 10",
    "shipCity": "Cunewalde",
    "shipRegion": "",
    "shipPostalCode": "1307",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "The Big Cheese",
    "customerContactName": "Liz Nixon",
    "orderTotal": 69.6,
    "orderDate": 1585688400000,
    "requiredDate": 1588107600000,
    "shippedDate": 1585861200000,
    "requiredDateStart": 1588163400000,
    "requiredDateEnd": 1588165200000,
    "orderID": 10992,
    "customerID": "THEBI",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 4.27,
    "shipName": "The Big Cheese",
    "shipAddress": "89 Jefferson Way Suite 2",
    "shipCity": "Portland",
    "shipRegion": "OR",
    "shipPostalCode": "97201",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Pericles Comidas clásicas",
    "customerContactName": "Guillermo Fernández",
    "orderTotal": 1196,
    "orderDate": 1585774800000,
    "requiredDate": 1588194000000,
    "shippedDate": 1586120400000,
    "requiredDateStart": 1588248000000,
    "requiredDateEnd": 1588249800000,
    "orderID": 10995,
    "customerID": "PERIC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 46,
    "shipName": "Pericles Comidas clásicas",
    "shipAddress": "Calle Dr. Jorge Cash 321",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "Frankenversand",
    "customerContactName": "Peter Franken",
    "orderTotal": 2825.3,
    "orderDate": 1586379600000,
    "requiredDate": 1587589200000,
    "shippedDate": 1587070800000,
    "requiredDateStart": 1587646800000,
    "requiredDateEnd": 1587652200000,
    "orderID": 11012,
    "customerID": "FRANK",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 3,
    "freight": 242.95,
    "shipName": "Frankenversand",
    "shipAddress": "Berliner Platz 43",
    "shipCity": "München",
    "shipRegion": "",
    "shipPostalCode": "80805",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "B's Beverages",
    "customerContactName": "Victoria Ashworth",
    "orderTotal": 1500,
    "orderDate": 1586811600000,
    "requiredDate": 1588021200000,
    "shippedDate": 1587675600000,
    "requiredDateStart": 1588073400000,
    "requiredDateEnd": 1588075200000,
    "orderID": 11023,
    "customerID": "BSBEV",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 123.83,
    "shipName": "B-s Beverages",
    "shipAddress": "Fauntleroy Circus",
    "shipCity": "London",
    "shipRegion": "",
    "shipPostalCode": "EC2 5NT",
    "shipCountry": "UK"
}, {
    "customerCompanyName": "Bottom-Dollar Markets",
    "customerContactName": "Elizabeth Lincoln",
    "orderTotal": 877.725,
    "orderDate": 1586984400000,
    "requiredDate": 1589403600000,
    "shippedDate": 1587330000000,
    "requiredDateStart": 1589455800000,
    "requiredDateEnd": 1589461200000,
    "orderID": 11027,
    "customerID": "BOTTM",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 52.52,
    "shipName": "Bottom-Dollar Markets",
    "shipAddress": "23 Tsawassen Blvd.",
    "shipCity": "Tsawassen",
    "shipRegion": "BC",
    "shipPostalCode": "T2F 8M4",
    "shipCountry": "Canada"
}, {
    "customerCompanyName": "Suprêmes délices",
    "customerContactName": "Pascale Cartrain",
    "orderTotal": 732.6,
    "orderDate": 1587416400000,
    "requiredDate": 1589835600000,
    "shippedDate": 1588194000000,
    "requiredDateStart": 1589893200000,
    "requiredDateEnd": 1589896800000,
    "orderID": 11038,
    "customerID": "SUPRD",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 29.59,
    "shipName": "Suprêmes délices",
    "shipAddress": "Boulevard Tirou, 255",
    "shipCity": "Charleroi",
    "shipRegion": "",
    "shipPostalCode": "B-6000",
    "shipCountry": "Belgium"
}, {
    "customerCompanyName": "LINO-Delicateses",
    "customerContactName": "Felipe Izquierdo",
    "orderTotal": 3090,
    "orderDate": 1587416400000,
    "requiredDate": 1589835600000,
    "shippedDate": 1588194000000,
    "requiredDateStart": 1589880600000,
    "requiredDateEnd": 1589882400000,
    "orderID": 11039,
    "customerID": "LINOD",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 65,
    "shipName": "LINO-Delicateses",
    "shipAddress": "Ave. 5 de Mayo Porlamar",
    "shipCity": "I. de Margarita",
    "shipRegion": "Nueva Esparta",
    "shipPostalCode": "4980",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Save-a-lot Markets",
    "customerContactName": "Jose Pavarotti",
    "orderTotal": 4330.4,
    "orderDate": 1588280400000,
    "requiredDate": 1590699600000,
    "shippedDate": 1588539600000,
    "requiredDateStart": 1590750000000,
    "requiredDateEnd": 1590751800000,
    "orderID": 11064,
    "customerID": "SAVEA",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 30.09,
    "shipName": "Save-a-lot Markets",
    "shipAddress": "187 Suffolk Ln.",
    "shipCity": "Boise",
    "shipRegion": "ID",
    "shipPostalCode": "83720",
    "shipCountry": "USA"
}, {
    "customerCompanyName": "Drachenblut Delikatessen",
    "customerContactName": "Sven Ottlieb",
    "orderTotal": 86.85,
    "orderDate": 1588539600000,
    "requiredDate": 1589749200000,
    "shippedDate": 1588712400000,
    "requiredDateStart": 1589797800000,
    "requiredDateEnd": 1589799600000,
    "orderID": 11067,
    "customerID": "DRACD",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 7.98,
    "shipName": "Drachenblut Delikatessen",
    "shipAddress": "Walserweg 21",
    "shipCity": "Aachen",
    "shipRegion": "",
    "shipPostalCode": "52066",
    "shipCountry": "Germany"
}, {
    "customerCompanyName": "Tortuga Restaurante",
    "customerContactName": "Miguel Angel Paolino",
    "orderTotal": 360,
    "orderDate": 1588539600000,
    "requiredDate": 1590958800000,
    "shippedDate": 1588712400000,
    "requiredDateStart": 1591005600000,
    "requiredDateEnd": 1591009200000,
    "orderID": 11069,
    "customerID": "TORTU",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 15.67,
    "shipName": "Tortuga Restaurante",
    "shipAddress": "Avda. Azteca 123",
    "shipCity": "México D.F.",
    "shipRegion": "",
    "shipPostalCode": "5033",
    "shipCountry": "Mexico"
}, {
    "customerCompanyName": "LILA-Supermercado",
    "customerContactName": "Carlos González",
    "orderTotal": 484.5,
    "orderDate": 1588626000000,
    "requiredDate": 1591045200000,
    "shippedDate": 1588712400000,
    "requiredDateStart": 1591095600000,
    "requiredDateEnd": 1591097400000,
    "orderID": 11071,
    "customerID": "LILAS",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 1,
    "freight": 0.93,
    "shipName": "LILA-Supermercado",
    "shipAddress": "Carrera 52 con Ave. Bolívar #65-98 Llano Largo",
    "shipCity": "Barquisimeto",
    "shipRegion": "Lara",
    "shipPostalCode": "3508",
    "shipCountry": "Venezuela"
}, {
    "customerCompanyName": "Rattlesnake Canyon Grocery",
    "customerContactName": "Paula Wilson",
    "orderTotal": 1255.7205,
    "orderDate": 1588712400000,
    "requiredDate": 1591131600000,
    "shippedDate": 1588712400000,
    "requiredDateStart": 1591189200000,
    "requiredDateEnd": 1591194600000,
    "orderID": 11077,
    "customerID": "RATTC",
    "employeeID": "d26bcf84-a8b4-471c-a582-2de64d7e7715",
    "teamID": 1,
    "shipVia": 2,
    "freight": 8.53,
    "shipName": "Rattlesnake Canyon Grocery",
    "shipAddress": "2817 Milton Dr.",
    "shipCity": "Albuquerque",
    "shipRegion": "NM",
    "shipPostalCode": "87110",
    "shipCountry": "USA"
}].map(order => ({
    ...order,
    // parse dates
    orderDate: new Date(order.orderDate),
    requiredDate: new Date(order.requiredDate),
    shippedDate: new Date(order.shippedDate),
    requiredDateStart: new Date(order.requiredDateStart),
    requiredDateEnd: new Date(order.requiredDateEnd),
    isAllDay: false
}));

export const ordersModelFields = {
    id: 'orderID',
    title: 'customerContactName',
    description: 'shipAddress',
    start: 'requiredDateStart',
    end: 'requiredDateEnd',
    recurrenceRule: 'recurrenceRule',
    recurrenceId: 'recurrenceID',
    recurrenceExceptions: 'recurrenceException'
};