import React from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";

const Guidelinescreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Login" className="guidelines-page main-content">
            <div className="content">
                <div className="card-container" style={{ maxWidth: 900 }}>

                    <div className="card-component">
                        <h1>Organization Guidelines</h1>
                        <p>Lincoln wants to ensure we are working with organizations that align with our brand values. Organizations from the pre-approved list will be granted immediate approval, and all other organizations will need to be submitted for approval during enrollment.</p>

                        <h1>Requirements and Approval</h1>
                        <p>Suggested areas of outreach are civic, medical, cultural, educational and professional. The organization must be OFAC (Office of Foreign Assets Controls)-compliant according to sanctions and regulations prohibiting U.S. citizens, businesses and financial institutions from engaging in business or financial transactions with persons or entities on OFAC’s Specially Designated Nationals (SDN) List. Organizations and initiatives should be a 501(c)(3) in good standing and operate with an 85% cause donation to 15% overhead cost ratio.</p>

                        <p>
                            Please review the list of pre-approved and not approved organizations below. If there are any questions or concerns about an organization’s eligibility for the program, please contact Program HQ prior to contacting the organization for prescreening information. Approval will be determined by Program HQ and Lincoln, which will notify Lincoln Dealers of approval status within five to seven business days.
                        </p>
                    </div>
                </div>
                <div className="card-container" style={{ maxWidth: 900 }}>
                    <div className="card-component">
                        <h1>Not Approved Organizations</h1>
                        <p>Charitable and/or community organizations not contained on the approved list but of interest to a dealer should be screened by the dealer using the following guidelines:</p>
                        <ul>
                            <li>
                                The organization must be a 501(c)(3) in good standing with charity verification sources such as <a className="link" href="https://www.guidestar.org/" target="_blank" rel="noreferrer">Guidestar</a> or <a className="link" href="https://www.charitynavigator.org/" target="_blank" rel="noreferrer">Charity Navigator</a>.
                            </li>
                            <li>
                                Organizations and initiatives should operate with an 85/15 cause donation to overhead cost ratio.
                            </li>
                            <li>
                                The organization must be OFAC (Office of Foreign Assets Controls)-compliant according to sanctions and regulations prohibiting U.S. citizens, businesses and financial institutions from engaging in business or financial transactions with persons or entities on OFAC’s Specially Designated Nationals (SDN) List.
                            </li>
                            <li>
                                Suggested areas of outreach are civic, medical, cultural, educational and professional. Examples include:
                                <ul>
                                    <li>
                                        Civic organizations and services (community food banks, United Way, etc.)
                                    </li>
                                    <li>
                                        Medical research, treatment and prevention (hospitals, treatment and/or disease awareness organizations, etc.)
                                    </li>
                                    <li>
                                        Business and professional organizations (Business Women’s Network, Entrepreneurs Organization Network, etc.)
                                    </li>
                                    <li>
                                        Culture and the arts (art, symphony, opera, Smithsonian, etc.)
                                    </li>
                                    <li>
                                        Education and youth development (Communities In School, school booster club, etc.)
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            Organizations or initiatives that will not be approved for the program include:
                        </p>
                        <ul>
                            <li>
                                Religious programs or sectarian programs for religious purposes, such as religious education, religious facility improvements and mission trips. Programs operated through a church (e.g., soup kitchens, homeless assistance programs, etc.) will be vetted on a case-by-case basis.
                            </li>
                            <li>
                                Chambers of commerce or local organizations with a main goal of improving retailer and/or small-business profits. Philanthropic outreach of a chamber may be considered, such as scholarship funds or community improvement.
                            </li>
                            <li>
                                Political organizations or efforts to influence legislation, or the outcome of any elections or any specific election of candidates to public office, or to carry on any voter registration drive.
                            </li>
                            <li>
                                Labor groups.
                            </li>
                            <li>
                                Loans and development for small businesses.
                            </li>
                            <li>
                                Organizations that promote discrimination in their charters.
                            </li>
                            <li>
                                Organizations that unlawfully discriminate in their provision of goods and services based on race, color, religion, gender identity or expression, ethnicity, sexual orientation, national origin, physical challenge, age or status.
                            </li>
                            <li>
                                Profit-making enterprises.
                            </li>
                            <li>
                                Advocacy-directed groups and programs.
                            </li>
                            <li>
                                Golf outings or non-organized teams/leagues that are not recognized by the city or the municipality (e.g., beer leagues).
                            </li>
                            <li>
                                Direct payments to colleges are not eligible; however, club sports, scholarship funds and community outreach by college-based organizations are permitted.
                            </li>
                            <li>
                                Direct payments to fraternal organizations, including fraternities and sororities on college campuses, are not permitted. However, an event can be hosted by a fraternity or sorority to benefit their national organizations. In this instance, the check would need to be written directly to the charitable organization.
                            </li>
                            <li>
                                Organizations that provide services or outreach that may be considered sensitive or controversial.
                            </li>
                            <li>
                                Individual sponsorships related to fundraising activities.
                            </li>
                            <li>
                                Designated funding purpose toward building, updates and/or renovations.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-container" style={{ maxWidth: 900 }}>
                    <div className="card-component">
                        <h1>Have a question or need help?</h1>
                        <p>
                            If you have any questions, please contact us at:
                        </p>
                        <p>
                            <b>Phone: </b>
                            <a href="tel:2487995072">
                                248.799.5072
                            </a>
                        </p>
                        <p>
                            <b>Email: </b>
                            <a href="mailto:lincolnevents@hudsonrouge.com">lincolnevents@hudsonrouge.com</a>
                        </p>

                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </div>
    );
};

export default Guidelinescreen;
