export const frMessages = {
    "custom": {
        "teamEfficiency": "Efficacité de l'équipe",
        "teamMembers": "Membres de l'équipe",
        "dashboard": "Tableau de bord",
        "planning": "Planification",
        "info": "Info",
        "profile": "Profil",
        "language": "Langue",
        "trend": "Tendance",
        "volume": "Le volume",
        "myTeam": "Mon équipe",
        "allTeams": "Toutes les équipes",
        "teamCalendar": "Calendrier d'équipe",
        "saveChanges": "Sauvegarder les modifications",
        "cancel": "Annuler",
        "warehouse": "Entrepôt De Café",
        "startFreeTrial": "Démarrer l'essai gratuit",
        "buyNow": "Acheter maintenant",
        "getSource": "Récupérer le code source sur GitHub",
        "demoInfo": "Cette application de démonstration est construite à l'aide des composants KendoReact suivants et du thème par défaut KendoReact.",
        "firstName": "Prénom",
        "middleName": "Deuxième nom",
        "lastName": "Nom de famille",
        "email": "Adresse e-mail",
        "phoneNumber": "Numéro de téléphone",
        "country": "Pays",
        "biography": "Biographie courte",
        "public": "Inclure dans le répertoire public",
        "team": "équipe",
        "employee": "Employé",
        "contactName": "Nom du contact",
        "jobTitle": "Titre du poste",
        "status": "Statut",
        "performance": "Performance",
        "rating":"Évaluation",
        "engagement": "Engagement",
        "budget": "Budget",
        "contacts": "Contacts",
        "address": "Adresse",
        "phone": "Téléphone",
        "gridSearch": "Rechercher dans toutes les colonnes ...",
        "exportExcel": "Exporter vers Excel",
        "exportPdf": "Exporter au format PDF"
    },
    "grid":{
        "groupPanelEmpty": "Faites glisser un en-tête de colonne et déposez-le ici pour grouper par cette colonne",
        "pagerItemsPerPage": "éléments par page",
        "pagerInfo": "{0} - {1} sur {2} éléments",
        "pagerFirstPage": "Aller à la première page",
        "pagerPreviousPage": "Aller à la page précédente",
        "pagerNextPage": "Aller à la page suivante",
        "pagerLastPage": "Aller à la dernière page",
        "pagerPage": "Page",
        "pagerOf": "de",
        "filterEqOperator": "Est égal à",
        "filterNotEqOperator": "N'est pas égal à",
        "filterIsNullOperator": "Est nulle",
        "filterIsNotNullOperator": "Est non nulle",
        "filterIsEmptyOperator": "Est vide",
        "filterIsNotEmptyOperator": "N'est pas vide",
        "filterStartsWithOperator": "Commence par",
        "filterContainsOperator": "Contient",
        "filterNotContainsOperator": "Ne contient pas",
        "filterEndsWithOperator": "Se termine par",
        "filterGteOperator": "Est supérieur ou égal à",
        "filterGtOperator": "Est supérieur à",
        "filterLteOperator": "Est inférieur ou égal à",
        "filterLtOperator": "Est inférieur à",
        "filterIsTrue":"Est vrai",
        "filterIsFalse": "Est faux",
        "filterBooleanAll": "(Tous)",
        "filterAfterOrEqualOperator": "Est postérieur ou égal à",
        "filterAfterOperator": "Est postérieur",
        "filterBeforeOperator": "Est antérieur",
        "filterBeforeOrEqualOperator": "Est antérieur ou égal à",
        "noRecords": "Aucun enregistrement disponible.",
        "filterSubmitButton": "Filtre",
        "filterClearButton": "Effacer",
        "filterAndLogic": "Et",
        "filterOrLogic": "Ou alors",
        "filterTitle": "Filtre",
        "sortAscending": "Trier par ordre croissant",
        "sortDescending": "Trier par ordre décroissant"
    },
    "dateinput":{
        "increment": "Augmenter la valeur",
        "decrement": "Diminuer la valeur"
    },
    "calendar":{
        "today":"AUJOURD'HUI"
    },
    "datepicker":{
        "toggleCalendar": "Basculer le calendrier"
    },
    "dropdowns":{
        "nodata": "AUCUNE DONNÉE DISPONIBLE.",
        "clear": "Effacer"
    },
    "numerictextbox":{
        "increment": "Augmenter la valeur",
        "decrement": "Diminuer la valeur"
    },
    "upload": {
        "cancel": "Cancel",
        "clearSelectedFiles": "Effacer",
        "dropFilesHere": "Déposer des fichiers ici pour télécharger",
        "headerStatusUploaded": "Terminé",
        "headerStatusUploading": "Téléchargement...",
        "invalidFileExtension": "Type de fichier non autorisé.",
        "invalidFiles": "Fichier(s) invalide(s). Veuillez vérifier les exigences de téléchargement de fichier.",
        "invalidMaxFileSize": "La taille du fichier est trop grande.",
        "invalidMinFileSize": "La taille du fichier est trop petite.",
        "remove": "Retirer",
        "retry": "Réessayez",
        "select": "Sélectionnez les fichiers...",
        "uploadSelectedFiles": "Télécharger",
        "total": "Total",
        "files": "fichiers"
    },
    "sortable": {
        "noData": "Aucune donnée"
    },
    "scheduler": {
        "editorValidationRequired": "Champ requis.",
        "editorValidationStart": "L'heure de début doit être antérieure à l'heure de fin.",
        "editorValidationEnd": "L'heure de fin doit être postérieure à l'heure de début.",
        "allEvents": "Tous les évènements",
        "allDay": "toute la journée",
        "today": "Aujourd'hui",
        "dayViewTitle": "Journée",
        "monthViewTitle": "Mois",
        "weekViewTitle": "La semaine",
        "workWeekViewTitle": "Semaine de travail",
        "timelineViewTitle": "Chronologie",
        "agendaViewTitle": "Ordre du jour",
        "deleteTitle": "Supprimer",
        "previousTitle": "Précédent",
        "nextTitle": "Suivant",
        "showFullDay": "Afficher toute la journée",
        "showWorkDay": "Afficher les heures d'ouverture",
        "editorOccurrence": "Modifier l'occurrence actuelle",
        "editorSeries": "Modifier la série",
        "editorRecurringConfirmation": "Voulez-vous modifier uniquement cette occurrence d'événement ou toute la série?",
        "editorRecurringDialogTitle": "Modifier l'élément récurrent",
        "editorSave": "Enregistrer",
        "editorCancel": "Annuler",
        "editorDelete": "Supprimer",
        "editorTitle": "Event",
        "editorEventTitle": "Titre",
        "editorEventStart": "Démarrer",
        "editorEventStartTimeZone": "Start Time Zone",
        "editorEventEnd": "End",
        "editorEventEndTimeZone": "End Time Zone",
        "editorEventAllDay": "All Day Event",
        "editorEventDescription": "Description",
        "editorEventSeparateTimeZones": "Terminer dans un fuseau horaire différent",
        "editorEventTimeZone": "Spécifier le fuseau horaire",
        "recurrenceEditorRepeat": "Répéter",
        "recurrenceEditorDailyInterval": "jour (s)",
        "recurrenceEditorDailyRepeatEvery": "Répéter chaque",
        "recurrenceEditorWeeklyInterval": "semaine (s)",
        "recurrenceEditorWeeklyRepeatEvery": "Répéter chaque",
        "recurrenceEditorWeeklyRepeatOn": "Répéter",
        "recurrenceEditorMonthlyDay": "Jour",
        "recurrenceEditorMonthlyInterval": "mois (s)",
        "recurrenceEditorMonthlyRepeatEvery": "Répéter chaque",
        "recurrenceEditorMonthlyRepeatOn": "Répéter",
        "recurrenceEditorYearlyOf": "of",
        "recurrenceEditorYearlyRepeatEvery": "Répéter chaque",
        "recurrenceEditorYearlyRepeatOn": "Répéter",
        "recurrenceEditorYearlyInterval": "année (s)",
        "recurrenceEditorFrequenciesDaily": "Du quotidien",
        "recurrenceEditorFrequenciesMonthly": "Mensuel",
        "recurrenceEditorFrequenciesNever": "Jamais",
        "recurrenceEditorFrequenciesWeekly": "Hebdomadaire",
        "recurrenceEditorFrequenciesYearly": "Annuel",
        "recurrenceEditorOffsetPositionsFirst": "Premier",
        "recurrenceEditorOffsetPositionsSecond": "Second",
        "recurrenceEditorOffsetPositionsThird": "Troisième",
        "recurrenceEditorOffsetPositionsFourth": "Quatrième",
        "recurrenceEditorOffsetPositionsLast": "Dernier",
        "recurrenceEditorWeekdaysDay": "Journée",
        "recurrenceEditorWeekdaysWeekday": "Jour de la semaine",
        "recurrenceEditorWeekdaysWeekendday": "Jour de weekend",
        "recurrenceEditorEndAfter": "Après",
        "recurrenceEditorEndOccurrence": "occurrence (s)",
        "recurrenceEditorEndLabel": "Fin",
        "recurrenceEditorEndNever": "Jamais",
        "recurrenceEditorEndOn": "On",
        "deleteConfirmation": "Voulez-vous vraiment supprimer cet événement?",
        "deleteRecurringConfirmation": "Souhaitez-vous supprimer uniquement cette occurrence d'événement ou toute la série?",
        "deleteOccurrence": "Supprimer l'occurrence actuelle",
        "deleteSeries": "Supprimer la série",
        "deleteDialogTitle": "Supprimer l'événement",
        "deleteRecurringDialogTitle": "Supprimer l'élément récurrent",
        "dateTitle": "Date",
        "timeTitle": "Temps",
        "eventTitle": "Un événement",
        "noEvents": "Aucun événement",
    }
};