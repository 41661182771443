import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    passwordValidator,
    passwordMatchValidator,
    partsCodeValidator,
    address1Validator,
    cityValidator,
    zipValidator,
    phoneValidator,
} from '../validators';
import { states } from '../resources/states';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import logo from "../assets/images/LINC_Hz_Black.png";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { DropDownList } from 'src/components/form/DropDownList';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';

const SignUpScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const history = useHistory();
    const { language } = localizationService;
    console.log('localizationService!!!!', localizationService);

    const [loginError, setLoginError] = useState<string>();
    const { login } = useStores();
    const { dtg } = useServices();

    useEffect(() => {
        if (login.dealer) {
            console.log('[SignUpScreen]', 'I think we are already logged in');
            dtg.getAccount().then(() => {
                history.push('/events');
            });
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = React.useCallback(
        async (dataItem) => {
            const dealerSignup: Dealer = dataItem;

            login.setLoginEmail(dealerSignup.contact_email);
            const call: any = await dtg.doSignup(dealerSignup, dataItem.password);
            if (call.result.errors) {
                console.log('[SignUpScreen]', 'Signup Error');
                setLoginError(call.result.errors.join('\n'));
            }
            if (call.result.dealer && call.result.token) {
                console.log('[SignUpScreen]', 'created & logged in');
                login.setDealerToken(call.result.token)
                login.setDealer(call.result.dealer as Dealer);
                history.push('/events');
            }
        },
        [login, history, dtg]
    );

    return (
        <div id="Login" className="home-page main-content">
            <div className="content">
                <div className={'logo_container'}>
                    <img src={logo} alt={'Lincoln logo'} className={'logo small'} />
                </div>
                <div className="card-container" style={{ maxWidth: 700 }}>
                    <div className="card-component">
                        <h1 className="card-title">{localizationService.toLanguageString('custom.signUp', 'Sign Up')}</h1>
                        <p className="card-text">{localizationService.toLanguageString('custom.signUpText', 'Sign Up Text')}</p>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                contact_email: login.loginEmail,
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    {loginError && <Error>{loginError}</Error>}
                                    <Field
                                        id={'dealer_name'}
                                        name={'dealer_name'}
                                        maxLength={100}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.dealer_name', 'dealer_name')}
                                        validator={requiredValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'parts_code'}
                                        name={'parts_code'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.sales_code', 'sales_code')}
                                        validator={partsCodeValidator}
                                        required={true}
                                        mask="00000"
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'address'}
                                        name={'address'}
                                        maxLength={45}
                                        // type={'input'}
                                        hint={'This address is where the donation check will be sent. The retailer will then present the check to the organization.'}
                                        label={localizationService.toLanguageString('custom.dealer_address', 'address')}
                                        validator={address1Validator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'address2'}
                                        name={'address2'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.address2', 'address2')}
                                        component={Input}
                                    />
                                    <Field
                                        id={'city'}
                                        name={'city'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.city', 'city')}
                                        validator={cityValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        component={DropDownList}
                                        id={'state'}
                                        name={'state'}
                                        label={localizationService.toLanguageString('custom.state', 'state')}
                                        data={states}
                                        validator={requiredValidator}
                                        required={true}
                                    />
                                    <Field
                                        id={'zip'}
                                        name={'zip'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.zip', 'zip')}
                                        validator={zipValidator}
                                        required={true}
                                        mask="00000"
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'phone'}
                                        name={'phone'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.dealershipPhone', 'dealershipPhone')}
                                        validator={phoneValidator}
                                        required={true}
                                        mask="000-000-0000"
                                        placeholder={'e.g.: 000-000-0000'}
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'primary_contact'}
                                        name={'primary_contact'}
                                        maxLength={45}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.primary_contact', 'primary_contact')}
                                        validator={requiredValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'contact_phone'}
                                        name={'contact_phone'}
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                        validator={phoneValidator}
                                        required={true}
                                        mask="000-000-0000"
                                        placeholder={'e.g.: 000-000-0000'}
                                        component={MaskedTextBox}
                                    />
                                    <Field
                                        id={'contact_email'}
                                        name={'contact_email'}
                                        type={'email'}
                                        autoComplete="username"
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        autoComplete="new-password"
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.password', 'Password')}
                                        validator={passwordValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'confirm_password'}
                                        name={'confirm_password'}
                                        type={'password'}
                                        autoComplete="new-password"
                                        // type={'input'}
                                        label={localizationService.toLanguageString('custom.confirmPassword', 'Confirm Password')}
                                        validator={passwordMatchValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <hr />
                                    <div className={'card-buttons'}>
                                        <Button
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            {localizationService.toLanguageString('custom.signUp', 'Sign Up')}
                                        </Button>
                                    </div>
                                </FormElement>
                            )}
                        />
                        <div className="card-links">
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/login");
                                }}
                            >
                                {localizationService.toLanguageString("custom.logIn", 'Log In')}
                                <span className={"k-icon k-i-arrow-chevron-right"} />
                            </Button>
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/password");
                                }}
                            >
                                {localizationService.toLanguageString("custom.forgotPassword", 'Forgot Password')}
                                <span className={"k-icon k-i-arrow-chevron-right"} />
                            </Button>
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/");
                                }}
                            >
                                <span className={"k-icon k-i-arrow-chevron-left"} />
                                {localizationService.toLanguageString("custom.goBack", 'Go Back')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0', }}
                privacyContainerStyle={{ padding: '10px 0' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </div>
    );
}

export default SignUpScreen;
