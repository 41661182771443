import * as React from "react";

import { useLocalization } from "@progress/kendo-react-intl";
// import { useHistory } from "react-router";
// import { Button } from "@progress/kendo-react-buttons";
import logo from "../assets/images/LINC_Hz_Black.png";
import PrivacyBanner from "react-ford-ccpa-banner";

const HomeScreen = () => {
  const localizationService = useLocalization();
  const { language } = localizationService;
  // const history = useHistory();
  return (
    <>
      <div id="Home" className="home-page main-content">
        <div className="content">
          <img src={logo} alt={"Lincoln logo"} className={"logo"} />
          <h1 className="home-title">Driven to Give</h1>
          <p>
            The Driven to Give program is not running in 2024.
          </p>
          {/* <p>
            Take the wheel. Share the wealth.
          </p>
          <p>
            From July 15 to November 19, Lincoln Brand Exclusive Advertising Program (LBEAP) eligible retailers will host one day test-drive events benefitting worthy organizations within their communities.
          </p>
          <p>
            This website will be your hub for all things related to your DTG event.
          </p> */}
          {/* <p>
            Enrollment for the program has now closed. 
          </p> */}
          <div className="button-group">
            {/* <Button
              className="k-link"
              onClick={() => {
                history.push("/login");
              }}
            >
              {localizationService.toLanguageString("custom.logIn")}
              <span className={"k-icon k-i-arrow-chevron-right"} />
            </Button> */}
            {/* <Button
              className="k-link"
              onClick={() => {
                history.push("/signup");
              }}
            >
              {localizationService.toLanguageString("custom.signUp")}
              <span className={"k-icon k-i-arrow-chevron-right"} />
            </Button> */}
            {/* <Button
              className="k-link"
              onClick={() => {
                history.push("/password");
              }}
            >
              {localizationService.toLanguageString("custom.forgotPassword")}
              <span className={"k-icon k-i-arrow-chevron-right"} />
            </Button> */}
          </div>
        </div>
      </div>
      <PrivacyBanner
        containerStyle={{ padding: "10px 0 0" }}
        privacyContainerStyle={{ padding: "10px 0" }}
        showPrivacy={true}
        language={language}
        team={"lincoln"}
        imgStyle={{ paddingLeft: "30px" }}
        otherLinks={[
          { text: "CONTACT US", url: "/contact" },
          {
            text: "PROGRAM TERMS AND CONDITIONS",
            url: "/terms-eligibility",
          },
          { text: "PROGRAM RESOURCES ", url: "/resources" },]}
      />
    </>
  );
};

export default HomeScreen;
