import React, { useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import {
    emailValidator,
    requiredValidator,
    phoneValidator,
    address1Validator,
    cityValidator,
    zipValidator,
    nameValidator,
    taxIdValidator,
} from '../validators';
import { states } from '../resources/states';

// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

import { AppContext } from './../AppContext'
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import { MaskedTextBox } from 'src/components/form/MaskedTextBox';
import { useHistory } from 'react-router';
import { DropDownList } from 'src/components/form/DropDownList';
import { DatePicker } from 'src/components/form/DatePicker';
import moment from 'moment';
import { Checkbox } from 'src/components/form/Checkbox';
import { Loader } from '@progress/kendo-react-indicators';

const EligibilityFormScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const { language } = localizationService;

    const [formError, setFormError] = useState<string>();
    const { dtg } = useServices();
    let history = useHistory();
    const [processing, setProcessing] = React.useState<boolean>(false);

    const onSubmit = React.useCallback(
        async (dataItem) => {
            setProcessing(true);

            dataItem.season_id = 7; // TODO: season should be dynamic

            // format dates properly
            if (dataItem.start_date) {
                dataItem.start_date = moment(dataItem.start_date).format('YYYY-MM-DD');
            }

            const eventRequest: DTGEvent = dataItem;

            const call: any = await dtg.saveRequest(eventRequest);
            if (call.result.errors) {
                console.log('[EligibilityFormScreen]', 'Event Request Error');
                setFormError(call.result.errors.join('\n'));
                setProcessing(false);
            } else {
                console.log('[EligibilityFormScreen]', 'event requested');
                history.push('/events');
            }
        },
        [dtg, history]
    );

    const ref: any = React.useRef(null);
    const dateRef: any = React.useRef(null);

    const handleOnWheel = (event: any) => {
        event.stopPropagation();
    };

    React.useEffect(() => {
        ref?.current?.element.addEventListener('wheel', handleOnWheel);
        dateRef?.current?.element.addEventListener('wheel', handleOnWheel);

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            ref?.current?.element.removeEventListener('wheel', handleOnWheel);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            dateRef?.current?.element.removeEventListener('wheel', handleOnWheel);
        };
    }, []);

    return (
        <>
            <div id="Eligibility" className="eligibility-page">
                <div className="card-container">
                    <h3 className="card-title">{localizationService.toLanguageString('custom.eligibilityRequest', 'eligibilityRequest')}</h3>
                    <p>{localizationService.toLanguageString('custom.eligibilityInstructions', 'eligibilityInstructions')} You can review our <a href="/organization-guidelines" target="_blank">Organization Guidelines here</a></p>
                    {
                        processing ? <div style={{ textAlign: 'center' }}><Loader type="infinite-spinner" size="large" /></div>
                            :
                            <div className="card-component">
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={{
                                        ...formValues,
                                    }}
                                    render={(formRenderProps) => (
                                        <FormElement horizontal={true}>
                                            {formError && <Error>{formError}</Error>}
                                            <Field
                                                id={'name'}
                                                name={'name'}
                                                maxLength={100}
                                                label={localizationService.toLanguageString('custom.charity_name', 'charity_name')}
                                                validator={requiredValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'website'}
                                                name={'website'}
                                                maxLength={255}
                                                label={localizationService.toLanguageString('custom.website', 'website')}
                                                validator={requiredValidator}
                                                component={Input}
                                            />
                                            <Field
                                                id={'tax_id'}
                                                name={'tax_id'}
                                                label={localizationService.toLanguageString('custom.tax_id', 'tax_id')}
                                                validator={taxIdValidator}
                                                mask="00-0000000"
                                                required={true}
                                                component={MaskedTextBox}
                                            />
                                            <Field
                                                id={'contact_first_name'}
                                                name={'contact_first_name'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.contact_first_name', 'contact_first_name')}
                                                validator={nameValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'contact_last_name'}
                                                name={'contact_last_name'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.contact_last_name', 'contact_last_name')}
                                                validator={nameValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'contact_phone_number'}
                                                name={'contact_phone_number'}
                                                label={localizationService.toLanguageString('custom.contact_phone', 'contact_phone')}
                                                validator={phoneValidator}
                                                required={true}
                                                mask="000-000-0000"
                                                placeholder={'e.g.: 000-000-0000'}
                                                component={MaskedTextBox}
                                            />
                                            <Field
                                                id={'contact_email'}
                                                name={'contact_email'}
                                                type={'email'}
                                                maxLength={100}
                                                placeholder={'e.g.: peter@gmail.com'}
                                                label={localizationService.toLanguageString('custom.contactEmail', 'Email')}
                                                validator={emailValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'funding_purpose'}
                                                name={'funding_purpose'}
                                                maxLength={1000}
                                                label={localizationService.toLanguageString('custom.org_description', 'org_description')}
                                                hint={localizationService.toLanguageString('custom.org_description_hint', 'org_description_hint')}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'start_date'}
                                                name={'start_date'}
                                                childRef={dateRef}
                                                label={localizationService.toLanguageString('custom.event_date', 'event_date')}
                                                format="MM/dd/yyyy"
                                                min={moment.max(moment('2023-07-15'), moment().add('days', 21)).toDate()}
                                                max={moment('2023-11-19').toDate()}
                                                validator={requiredValidator}
                                                component={DatePicker}
                                            />
                                            <Field
                                                id={'event_location_name'}
                                                name={'event_location_name'}
                                                maxLength={255}
                                                label={localizationService.toLanguageString('custom.event_location_name', 'event_location_name')}
                                                validator={requiredValidator}
                                                hint={localizationService.toLanguageString('custom.event_location_hint', 'event_location_hint')}
                                                component={Input}

                                            />
                                            <Field
                                                id={'address_1'}
                                                name={'address_1'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.address', 'address')}
                                                validator={address1Validator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                id={'city'}
                                                name={'city'}
                                                maxLength={45}
                                                label={localizationService.toLanguageString('custom.city', 'city')}
                                                validator={cityValidator}
                                                required={true}
                                                component={Input}
                                            />
                                            <Field
                                                component={DropDownList}
                                                id={'state'}
                                                name={'state'}
                                                label={localizationService.toLanguageString('custom.state', 'state')}
                                                data={states}
                                                validator={requiredValidator}
                                                required={true}
                                            />
                                            <Field
                                                id={'postal_code'}
                                                name={'postal_code'}
                                                label={localizationService.toLanguageString('custom.zip', 'zip')}
                                                validator={zipValidator}
                                                required={true}
                                                mask="00000"
                                                component={MaskedTextBox}
                                            />
                                            <Field
                                                id={'agree_terms'}
                                                name={'agree_terms'}
                                                label={`${localizationService.toLanguageString('custom.terms', 'terms')}`}
                                                checkboxText={`${localizationService.toLanguageString('custom.agree_eligibility_terms', 'agree_eligibility_terms')}`}
                                                hint={<a href="/terms-eligibility" target="_blank">{localizationService.toLanguageString('custom.read_eligibility_terms', 'read_eligibility_terms')}</a>}
                                                validator={requiredValidator}
                                                required={true}
                                                component={Checkbox}
                                            />
                                            <hr />
                                            <div className={'k-form-buttons'}>
                                                <Button
                                                    primary={true}
                                                    type={'submit'}
                                                    disabled={!formRenderProps.allowSubmit}
                                                >
                                                    {localizationService.toLanguageString('custom.submitEligibility', 'submitEligibility')}
                                                </Button>
                                                <Button onClick={() => history.push('/events')}>
                                                    {localizationService.toLanguageString('custom.cancel', 'cancel')}
                                                </Button>
                                            </div>
                                        </FormElement>
                                    )}
                                />
                            </div>
                    }
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />

        </>
    );
}

export default EligibilityFormScreen;
