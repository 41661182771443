import { default as React } from "react";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';
import { useLocalization } from "@progress/kendo-react-intl";
import logo from "../../assets/images/Lincoln_DrivenToGive_Banner_Logo.png";
const ResourcesScreen = () => {
    const localizationService = useLocalization();
    const { language } = localizationService;

    return (
        <div id="Profile" className="guidelines-page content">
            <img src={logo} alt={'Lincoln logo 100 years'} className={'logo'} />
            <div className="card-container">
                <div className="card-component">
                    <h1 className="card-title">Program Resources</h1>
                    <p>Thank you for expressing interest in hosting a Driven to Give event. Please click below for Program Resources to assist you with planning.</p>
                    <a className='link a-list' href="/resources/elements" target="_blank">Required Event Elements</a>
                    <a className='link a-list' href="/resources/faqs" target="_blank">Event FAQs</a>
                    <a className='link a-list' href="/resources/info" target="_blank">Event Organization Information</a>
                    <a className='link a-list' href="/resources/best-practices" target="_blank">Event Best Practices</a>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },
                ]}
            />
        </div>
    );
};

export default ResourcesScreen;
