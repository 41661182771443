import React, { useEffect, useState } from 'react';

import { useLocalization } from '@progress/kendo-react-intl';
import { useHistory, useLocation } from "react-router-dom";
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Error } from "@progress/kendo-react-labels";
import { emailValidator, requiredValidator } from '../validators';

import { AppContext } from './../AppContext'
import { useStores } from 'src/stores';
import { useServices } from 'src/services';
import { Input } from 'src/components/form/Input';
import logo from "../assets/images/LINC_Hz_Black.png";
// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

const LoginScreen = () => {
    const { ...formValues } = React.useContext(AppContext);
    const localizationService = useLocalization();
    const { language } = localizationService;
    const history = useHistory();

    const [loginError, setLoginError] = useState<string>();
    const { login } = useStores();
    const { dtg } = useServices();

    let location = useLocation<Location>();

    let { from } = location.state as any || { from: { pathname: "/events" } };

    useEffect(() => {
        if (login.dealer) {
            console.log('[LoginScreen]', 'I think we are already logged in');
            dtg.getAccount().then(() => {
                history.push('/events');
            });
        }
        // eslint-disable-next-line
    }, [])

    const onSubmit = React.useCallback(
        async (dataItem) => {
            login.setLoginEmail(dataItem.email);
            const call: any = await dtg.doLogin(dataItem.email, dataItem.password);
            if (call.result.ERROR) {
                console.log('[LoginScreen]', 'Login Error');
                return setLoginError(call.result.message);
            }
            if (call.result.dealer) {
                console.log('[LoginScreen]', 'logged in, no 2fa required');
                login.setDealerToken(call.result.token)
                login.setDealer(call.result.dealer as Dealer);
                history.push(from);
            }
        },
        [login, from, history, dtg]
    );

    return (
        <div id="Login" className="home-page main-content">
            <div className="content">
                <div className={'logo_container'}>
                    <img src={logo} alt={'Lincoln logo'} className={'logo small'} />
                </div>
                <div className="card-container" style={{ maxWidth: 700 }}>

                    <div className="card-component">
                        <h1 className="card-title">Log In</h1>
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...formValues,
                                email: login.loginEmail,
                            }}
                            render={(formRenderProps) => (
                                <FormElement horizontal={true} style={{ maxWidth: 700 }}>
                                    {loginError && <Error>{loginError}</Error>}
                                    <Field
                                        id={'email'}
                                        name={'email'}
                                        type={'email'}
                                        placeholder={'e.g.: peter@gmail.com'}
                                        label={localizationService.toLanguageString('custom.email', 'Email')}
                                        validator={emailValidator}
                                        required={true}
                                        component={Input}
                                    />
                                    <Field
                                        id={'password'}
                                        name={'password'}
                                        type={'password'}
                                        label={localizationService.toLanguageString('custom.password', 'Password')}
                                        validator={requiredValidator}
                                        component={Input}
                                    />
                                    <hr />
                                    <div className={'card-buttons'}>
                                        <Button
                                            className={'k-button'}
                                            primary={true}
                                            type={'submit'}
                                            disabled={!formRenderProps.allowSubmit}
                                        >
                                            {localizationService.toLanguageString('custom.logIn', 'Log In')}
                                        </Button>
                                    </div>

                                </FormElement>
                            )}
                        />
                        <div className="card-links">
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/password");
                                }}
                            >
                                {localizationService.toLanguageString("custom.forgotPassword", 'Forgot Password?')}
                                <span className={"k-icon k-i-arrow-chevron-right"} />
                            </Button>
                            {/* <Button
                                        className="k-link"
                                        onClick={() => {
                                            history.push("/signup");
                                        }}
                                    >
                                        {localizationService.toLanguageString("custom.signUp", 'Sign Up')}
                                        <span className={"k-icon k-i-arrow-chevron-right"} />
                                    </Button> */}
                            <Button
                                className="k-link"
                                onClick={() => {
                                    history.push("/");
                                }}
                            >
                                <span className={"k-icon k-i-arrow-chevron-left"} />
                                {localizationService.toLanguageString("custom.goBack", 'Go Back')}
                            </Button>
                        </div>

                    </div>
                </div>
            </div>
            <PrivacyBanner
                containerStyle={{ padding: '10px 0 0', width: '100%' }}
                privacyContainerStyle={{ padding: '10px 0', width: '100%' }}
                showPrivacy={true}
                language={language}
                team={'lincoln'}
                imgStyle={{ paddingLeft: '30px' }}
                otherLinks={[
                    { text: "CONTACT US", url: "/contact" },
                    { text: "PROGRAM TERMS AND CONDITIONS", url: "/terms-eligibility" },
                    { text: "PROGRAM RESOURCES ", url: "/resources" },]}
            />
        </div>
    );
}

export default LoginScreen;
