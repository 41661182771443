import * as React from "react";

import { useHistory } from "react-router";
import { Button } from "@progress/kendo-react-buttons";
import logo from "../assets/images/LINC_Hz_Black.png";

const NotFound = () => {
  const history = useHistory();
  return (
    <>
      <div id="Home" className="home-page main-content" style={{ height: '100vh'}}>
        <div className="content">
          <img src={logo} alt={"Lincoln logo"} className={"logo"} />
          <h1 className="home-title">PAGE NOT FOUND</h1>
          <div className="button-group">
            <Button
              className="k-link"
              onClick={() => {
                history.push("/");
              }}
              style={{ marginTop: 20}}
            >
              Home
              <span className={"k-icon k-i-arrow-chevron-right"} />
            </Button>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default NotFound;
